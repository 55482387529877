<template>
  <app-icon
    name="DownloadIcon"
    :class="[
      'h-5 w-5 text-gray-500 transition transform',
      rotate,
    ]"
  />
</template>
<script>
export default {
  props: {
    rotate: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

import client from "@/data/base-client";
import WithAuthRepository from "./with-auth-repository";

export default class DashboardRepository extends WithAuthRepository {
  constructor() {
    super();
  }

  async summary(business_id, currency, date_between) {
    const response = await client.get(
      "/business-dashboards/businesses/" + business_id + "/currencies/" + currency + "/summary?filter[created_at_between]=" + date_between,
      this.getToken()
    );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async overview(business_id, currency, date_between) {
    const response = await client.get(
      "/business-dashboards/businesses/" + business_id + "/currencies/" + currency + "/overview?filter[created_at_between]=" + date_between,
      this.getToken()
    );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async accountSummary(currency, date_between) {
    const response = await client.get(
      "/account-dashboards/currencies/" + currency + "/summary?filter[created_at_between]=" + date_between,
      this.getToken()
    );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async accountTransactionSummary(currency, date_between) {
    const response = await client.get(
      "/account-dashboards/currencies/" + currency + "/transaction-summary?filter[created_at_between]=" + date_between,
      this.getToken()
    );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async operationSummary(currency, date_between) {
    const response = await client.get(
      "/operation-dashboards/currencies/" + currency + "/summary?filter[created_at_between]=" + date_between,
      this.getToken()
    );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async masterBusinessSummary(master_business_id, currency, date_between) {
    const response = await client.get(
      "/master-business-dashboards/" + master_business_id + "/currencies/" + currency + "/summary?filter[created_at_between]=" + date_between,
      this.getToken()
    );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async masterBusinessSummaryTotalBusiness(master_business_id, currency, date_between) {
    const response = await client.get(
      "/master-business-dashboards/" + master_business_id + "/currencies/" + currency + "/summary-total-business?filter[created_at_between]=" + date_between,
      this.getToken()
    );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

}

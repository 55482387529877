<template>
  <svg
    class="w-4 h-4 cursor-pointer"
    :class="$attrs.class"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.63309 2.64645C3.43783 2.45118 3.12125 2.45118 2.92599 2.64645C2.73073 2.84171 2.73073 3.15829 2.92599 3.35355L6.42481 6.85237C4.74993 7.99178 3.50288 9.77383 3.05965 11.8979C3.00325 12.1682 3.17666 12.4331 3.44698 12.4895C3.7173 12.5459 3.98216 12.3725 4.03857 12.1021C4.44238 10.167 5.60316 8.56289 7.14664 7.57421L8.72848 9.15604C7.84463 9.79133 7.26901 10.8284 7.26901 12C7.26901 13.933 8.83601 15.5 10.769 15.5C11.9406 15.5 12.9777 14.9244 13.613 14.0405L17.926 18.3536C18.1212 18.5488 18.4378 18.5488 18.6331 18.3536C18.8284 18.1583 18.8284 17.8417 18.6331 17.6464L3.63309 2.64645ZM10.9033 8.50253L14.2665 11.8657C14.1976 10.0395 12.7295 8.57143 10.9033 8.50253ZM8.31058 5.9098L9.11364 6.71286C9.65095 6.57353 10.2096 6.5 10.7795 6.5C13.9851 6.5 16.8369 8.82688 17.5204 12.1021C17.5768 12.3725 17.8417 12.5459 18.112 12.4895C18.3823 12.433 18.5557 12.1682 18.4993 11.8979C17.722 8.17312 14.4729 5.5 10.7795 5.5C9.92432 5.5 9.09296 5.64331 8.31058 5.9098Z"
      fill="#313335"
    />
  </svg>
</template>
<template>
  <div class="flex space-x-2 items-center">
    <app-icon
      v-if="icon"
      class="flex-none"
      :class="[icon_color, icon_size]"
      :name="icon"
    />
    <app-icon-outline
      v-else-if="icon_outline"
      class="flex-none"
      :class="[icon_color, icon_size]"
      :name="icon_outline"
    />
    <p :class="[text_color, text_size]">{{ text ?? "-" }}</p>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: null,
    },
    icon_outline: {
      type: String,
      default: null,
    },
    icon_color: {
      type: String,
      default: "text-black",
    },
    icon_size: {
      type: String,
      default: "w-5 h-5",
    },
    text: {
      type: String,
      default: null,
    },
    text_color: {
      type: String,
      default: "text-black",
    },
    text_size: {
      type: String,
      default: "text-md",
    },
  },
};
</script>
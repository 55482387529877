<template>
  <svg
    width="82"
    height="64"
    viewBox="0 0 82 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M36.4631 13.8534C36.4631 17.463 35.0291 20.9248 32.4767 23.4772C29.9243 26.0296 26.4625 27.4636 22.8529 27.4636C19.2432 27.4636 15.7814 26.0296 13.229 23.4772C10.6766 20.9248 9.24266 17.463 9.24266 13.8534C9.24266 10.2437 10.6766 6.78191 13.229 4.2295C15.7814 1.67709 19.2432 0.243164 22.8529 0.243164C26.4625 0.243164 29.9243 1.67709 32.4767 4.2295C35.0291 6.78191 36.4631 10.2437 36.4631 13.8534V13.8534ZM72.757 13.8534C72.757 15.6407 72.4049 17.4105 71.7209 19.0618C71.037 20.713 70.0344 22.2134 68.7706 23.4772C67.5068 24.7411 66.0064 25.7436 64.3551 26.4276C62.7039 27.1115 60.9341 27.4636 59.1467 27.4636C57.3594 27.4636 55.5896 27.1115 53.9383 26.4276C52.2871 25.7436 50.7867 24.7411 49.5229 23.4772C48.2591 22.2134 47.2565 20.713 46.5726 19.0618C45.8886 17.4105 45.5365 15.6407 45.5365 13.8534C45.5365 10.2437 46.9705 6.78191 49.5229 4.2295C52.0753 1.67709 55.5371 0.243164 59.1467 0.243164C62.7564 0.243164 66.2182 1.67709 68.7706 4.2295C71.323 6.78191 72.757 10.2437 72.757 13.8534V13.8534ZM54.2924 63.7575C54.5011 62.2739 54.61 60.7632 54.61 59.2207C54.6205 52.0929 52.2225 45.1705 47.8049 39.5767C51.2532 37.5858 55.1648 36.5376 59.1466 36.5376C63.1284 36.5376 67.04 37.5857 70.4883 39.5765C73.9367 41.5674 76.8002 44.4308 78.7912 47.8791C80.7821 51.3274 81.8303 55.239 81.8304 59.2207V63.7575H54.2924ZM22.8529 36.537C28.8689 36.537 34.6386 38.9269 38.8926 43.1809C43.1467 47.435 45.5365 53.2046 45.5365 59.2207V63.7575H0.169189V59.2207C0.169189 53.2046 2.55907 47.435 6.81308 43.1809C11.0671 38.9269 16.8368 36.537 22.8529 36.537V36.537Z"
      fill="#52525B"
    />
  </svg>
</template>
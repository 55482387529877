<template>
  <button
    @click="onClick"
    type="button"
    :class="[
      'btn',
      $attrs.class,
      disable
        ? 'bg-white text-gray-500 border-b border-r border-l lg:border-l-0 lg:border-t border-gray-500 cursor-not-allowed'
        : active
        ? 'bg-primary text-white border border-primary cursor-pointer'
        : 'bg-white text-primary lg:border border-b lg:border-l-0 border-primary hover:bg-hover-button hover:text-white cursor-pointer',
    ]"
  >
    <slot />
  </button>
</template>


<script>
import { logEvent } from "firebase/analytics";

export default {
  props: {
    type: {
      type: String,
      default: "button",
    },
    active: {
      type: Boolean,
      default: false,
    },
    disable: {
      type: Boolean,
      default: false,
    },
    eventName: {
      type: String,
      default: null,
    },
  },
  methods: {
    onClick(event) {
      if (this.disable) return;

      if (this.eventName != null) {
        logEvent(this.$analytics, "click", { event: this.eventName });
      }

      this.$emit("onClick", event);
    },
  },
};
</script>

<style scoped>
.btn {
  @apply px-6 
    py-2.5
    flex gap-3
    transition;
}
</style>
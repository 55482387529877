import AuthRepository from "../../data/repository/auth-repository";
import InvoiceRepository from "../../data/repository/invoice-repository";
import { logEvent } from "firebase/analytics";
import baseStore from "../base-store";

const initialInvoice = {
  _id: null,
  customer_id: null,
  customer: {},
  title: null,
  tax_id: "",
  tax_type: null,
  tax_name: null,
  tax_rate: 0,
  tax_amount: 0,
  date: new Date().toUTCString(),
  currency: "MYR",
  payment_term_date: null,
  type: "one_time_payment",
  allow_partial: false,
  ref2: null,
  memo: "",
  internal_memo: "",
  footer: "",
  custom_attributes: [{ key: "", value: "" }],
  items: [],
  subtotal: 0,
  total_amount: 0,
  invoice_no: "",
};

export default {
  namespaced: true,
  state: {
    ...baseStore.state,

    invoice: { ...initialInvoice },
    invoiceHistory: [],
    invoiceRunningNo: {},
    agingInvoiceSummary: {
      total: { total: 0, count: 0 },
      _1_30: { total: 0, count: 0 },
      _31_60: { total: 0, count: 0 },
      _61_90: { total: 0, count: 0 },
      _91: { total: 0, count: 0 },
    },
    agingTempNotes: { content: "", created_at: null }
  },

  getters: {
    ...baseStore.getters,

    invoice(state) {
      return state.invoice;
    },
    invoiceHistory(state) {
      return state.invoiceHistory;
    },
    invoiceRunningNo(state) {
      return state.invoiceRunningNo;
    },
    agingInvoiceSummary(state) {
      return state.agingInvoiceSummary;
    },
    agingTempNotes(state) {
      return state.agingTempNotes;
    },
  },

  mutations: {
    ...baseStore.mutations,

    initInvoice(state, invoice = initialInvoice) {
      state.invoice = this.$lodash.cloneDeep(invoice);
    },
    setInvoice(state, invoice) {
      state.invoice = invoice;
    },
    setInvoiceHistory(state, invoiceHistory) {
      state.invoiceHistory = invoiceHistory;
    },
    setInvoiceRunningNo(state, invoiceRunningNo) {
      state.invoiceRunningNo = invoiceRunningNo;
    },
    setReportLoading(state, loading) {
      state.reportLoading = loading;
    },
    setAgingInvoicesSummary(state, summary) {
      state.agingInvoiceSummary = summary;
    },
    setAgingTempNotes(state, notes) {
      state.agingTempNotes = notes;
    },
  },

  actions: {
    async retrieveInvoices(state, queryParams) {
      const invoiceRepository = new InvoiceRepository();
      const authRepository = new AuthRepository();

      const business_id = authRepository.getSelectedRole()?.business_id;

      if (business_id == null) {
        return;
      }

      state.commit("setApiPaginationLoading", true);
      const invoiceIndexResult = await invoiceRepository.indexInvoice(
        business_id,
        queryParams
      );

      if (invoiceIndexResult.message != undefined) {
        logEvent(this.$analytics, "invoice-index", { result: "failed" });
        state.commit("setErrorMessage", invoiceIndexResult);
        return;
      } else {
        logEvent(this.$analytics, "invoice-index", { result: "success" });
      }

      state.commit("setApiPaginationLoading", false);
      state.commit("setApiPaginationResponse", invoiceIndexResult);
      return invoiceIndexResult;
    },

    async retrieveAgingInvoicesSummary(state) {
      const invoiceRepository = new InvoiceRepository();
      const authRepository = new AuthRepository();

      const business_id = authRepository.getSelectedRole()?.business_id;

      if (business_id == null) {
        return;
      }

      state.commit("setReportLoading", true);
      const result = await invoiceRepository.retrieveAgingInvoicesSummary(
        business_id
      );

      if (result.message != undefined) {
        logEvent(this.$analytics, "invoice-aging", { result: "failed" });
        state.commit("setErrorMessage", result);
        return;
      } else {
        logEvent(this.$analytics, "invoice-aging", { result: "success" });
      }

      state.commit("setReportLoading", false);
      state.commit("setAgingInvoicesSummary", result.data);
      return result;
    },

    async retrieveAgingTempNotes(state) {
      const invoiceRepository = new InvoiceRepository();
      const result = await invoiceRepository.retrieveAgingTempNotes();

      if (result.message != undefined) {
        logEvent(this.$analytics, "invoice-aging-note", { result: "failed" });
        state.commit("setErrorMessage", result);
        return;
      } else {
        logEvent(this.$analytics, "invoice-aging-note", { result: "success" });
      }

      state.commit("setAgingTempNotes", result.data);
      return result;
    },

    async saveAgingTempNotes(state, content) {
      const invoiceRepository = new InvoiceRepository();
      const result = await invoiceRepository.saveAgingTempNotes(content);

      if (result.message != undefined) {
        logEvent(this.$analytics, "invoice-aging-note", { result: "failed" });
        state.commit("setErrorMessage", result);
        return;
      } else {
        logEvent(this.$analytics, "invoice-aging-note", { result: "success" });
      }

      state.commit("setAgingTempNotes", result.data);
      return result;
    },

    async deleteAgingTempNotes(state) {
      const invoiceRepository = new InvoiceRepository();
      const result = await invoiceRepository.deleteAgingTempNotes();

      if (result.message != undefined) {
        logEvent(this.$analytics, "invoice-aging-note-delete", { result: "failed" });
        state.commit("setErrorMessage", result);
        return;
      } else {
        logEvent(this.$analytics, "invoice-aging-note-delete", { result: "success" });
      }

      state.commit("setAgingTempNotes", { content: "", created_at: null });
      return result;
    },

    async getSingleInvoice(state, invoiceId) {
      const invoiceRepository = new InvoiceRepository();

      state.commit("setLoading", true);

      const invoiceResult = await invoiceRepository.getSingleInvoice(invoiceId);

      if (invoiceResult.message != undefined) {
        logEvent(this.$analytics, "get-invoice", { result: "failed" });
        state.commit("setErrorMessage", invoiceResult);
      } else {
        logEvent(this.$analytics, "get-invoice", { result: "success" });
        state.commit("setInvoice", invoiceResult.data ?? {});
      }

      state.commit("setLoading", false);
      return invoiceResult;
    },

    async createOrUpdateInvoice(state, invoice_form) {
      const invoiceRepository = new InvoiceRepository();
      const authRepository = new AuthRepository();

      const business_id = authRepository.getSelectedRole()?.business_id;

      if (business_id === null) {
        return;
      }

      state.commit("setLoading", true);

      Object.keys(invoice_form).forEach((key) => {
        if (invoice_form[key] === "") {
          delete invoice_form[key];
        }
      });

      const invoiceResult = await invoiceRepository.createOrUpdateInvoice(
        business_id,
        invoice_form
      );

      const isCreate = invoice_form._id === null;

      logEvent(
        this.$analytics,
        isCreate ? "create-invoice" : "update-invoice",
        { result: invoiceResult.message ? "failed" : "success" }
      );

      if (invoiceResult.message) {
        state.commit("setErrorMessage", invoiceResult);
        return;
      }

      state.commit("setErrorMessage");
      state.commit("setLoading", false);

      return invoiceResult;
    },

    async resetInvoice(state) {
      state.commit("initInvoice");
    },

    async getInvoiceById(state, invoiceId) {
      const invoiceRepository = new InvoiceRepository();
      const authRepository = new AuthRepository();

      const businessId = authRepository.getSelectedRole()?.business_id;

      if (businessId == null) {
        return;
      }

      // state.commit("setLoading", true);

      const invoiceResult = await invoiceRepository.getInvoiceById(
        businessId,
        invoiceId
      );

      if (invoiceResult.message != undefined) {
        logEvent(this.$analytics, "get-invoice", { result: "failed" });
        state.commit("setErrorMessage", invoiceResult);
      } else {
        logEvent(this.$analytics, "get-invoice", { result: "success" });
        state.commit("setInvoice", invoiceResult.data ?? {});
      }

      state.commit("setLoading", false);
      return invoiceResult;
    },

    async downloadInvoicePdf(state, payload) {
      const invoiceRepository = new InvoiceRepository();

      state.commit("setLoading", true);

      const invoiceResult = await invoiceRepository.downloadInvoicePdf(
        payload.businessId,
        payload.invoiceId
      );

      logEvent(this.$analytics, "download-invoice", {
        result: invoiceResult.message ? "failed" : "success",
      });

      state.commit("setLoading", false);

      window.open(invoiceResult.data.url, "_blank");
    },

    async deleteInvoice(state, invoice) {
      const invoiceRepository = new InvoiceRepository();
      const authRepository = new AuthRepository();

      const business_id = authRepository.getSelectedRole()?.business_id;

      if (business_id == null) {
        return;
      }

      state.commit("setLoading", true);

      const invoiceResult = await invoiceRepository.deleteInvoice(
        business_id,
        invoice
      );

      if (invoiceResult.message != undefined) {
        logEvent(this.$analytics, "delete-invoice", { result: "failed" });
        state.commit("setErrorMessage", invoiceResult);
      } else {
        logEvent(this.$analytics, "delete-invoice", { result: "success" });
      }

      state.commit("setLoading", false);

      return invoiceResult;
    },

    async getInvoiceHistory(state, invoiceId) {
      const invoiceRepository = new InvoiceRepository();
      const authRepository = new AuthRepository();
      const businessId = authRepository.getSelectedRole()?.business_id;
      if (businessId == null) {
        return;
      }
      state.commit("setLoading", true);
      const invoiceResult = await invoiceRepository.getInvoiceHistory(
        businessId,
        invoiceId
      );

      logEvent(this.$analytics, "invoice-history", {
        result: invoiceResult.message ? "failed" : "success",
      });

      state.commit("setInvoiceHistory", invoiceResult.data ?? []);
      state.commit("setLoading", false);

      return invoiceResult;
    },

    async getNextRunningNo(state) {
      const invoiceRepository = new InvoiceRepository();
      const authRepository = new AuthRepository();
      const businessId = authRepository.getSelectedRole()?.business_id;
      if (businessId == null) {
        return;
      }
      state.commit("setLoading", true);
      const invoiceRunningNo = await invoiceRepository.getInvoiceRunningNo(
        businessId
      );

      logEvent(this.$analytics, "invoice-runnning-no", {
        result: invoiceRunningNo.message ? "failed" : "success",
      });

      state.commit("setInvoiceRunningNo", invoiceRunningNo.data ?? "");
      state.commit("setLoading", false);

      return invoiceRunningNo;
    },

    async proceedInvoicePaymentAttempt(state, data) {
      const invoiceRepository = new InvoiceRepository();

      state.commit("setLoading", true);

      const paymentPaymentAttemptResult =
        await invoiceRepository.proceedInvoicePaymentAttempt(
          state.getters.invoice._id,
          data.businessPaymentGatewayMethodId,
          data.card_info,
          data.bank_info,
          data.stripe_create_payment_result
        );

      if (paymentPaymentAttemptResult.message != undefined) {
        logEvent(this.$analytics, "proceed-invoice-payment-attempt", {
          result: "failed",
        });
        state.commit("setErrorMessage", paymentPaymentAttemptResult);
        return;
      } else {
        logEvent(this.$analytics, "proceed-invoice-payment-attempt", {
          result: "success",
        });
      }

      return paymentPaymentAttemptResult.data;
    },

    async downloadAgingReport(state, payload) {

      const invoiceRepository = new InvoiceRepository();
      state.commit("setLoading", true);

      const authRepository = new AuthRepository();
      const businessId = authRepository.getSelectedRole()?.business_id;
      if (businessId == null) {
        return;
      }
      const invoiceResult = await invoiceRepository.downloadAgingReport(
        businessId,
        payload
      );

      logEvent(this.$analytics, "download-invoice-aging-report", {
        result: invoiceResult.message ? "failed" : "success",
      });

      state.commit("setLoading", false);
      window.open(invoiceResult.data, "_blank");
    },

    async sendEmail(state, invoiceId) {
      const invoiceRepository = new InvoiceRepository();
      state.commit("setLoading", true);
      const authRepository = new AuthRepository();
      const businessId = authRepository.getSelectedRole()?.business_id;
      if (businessId == null) {
        return;
      }
      const result = await invoiceRepository.sendEmail(
        businessId,
        invoiceId
      );
      state.commit("setLoading", false);
      return result;
    }
  },
};

import baseStore from "../base-store";
import UserRepository from "../../data/repository/user-repository";
import { logEvent } from "firebase/analytics";

export default {
  namespaced: true,

  state: {
    ...baseStore.state,
    notification: {},
    notificationUnread: 0,
    notificationPaginate: [],
    openPaymentLinkSkipPrompt: false,
    list_user: [],
  },

  getters: {
    ...baseStore.getters,
    notification: (state) => state.notification,
    notificationUnread: (state) => state.notificationUnread,
    notificationPaginate: (state) => state.notificationPaginate,
    openPaymentLinkSkipPrompt: (state) => state.openPaymentLinkSkipPrompt,

    list_user(state) {
      return state.list_user;
    },
  },

  mutations: {
    ...baseStore.mutations,
    setNotification(state, notification) {
      state.notification = notification;
    },
    setNotificationsUnread(state, notificationUnread) {
      state.notificationUnread = notificationUnread;
    },
    setNotificationPaginate(state, notificationPaginate) {
      state.notificationPaginate = notificationPaginate;
    },
    setOpenPaymentLinkSkipPrompt(state, openPaymentLinkSkipPrompt) {
      state.openPaymentLinkSkipPrompt = openPaymentLinkSkipPrompt;
    },
    setListUser(state, list_user) {
      state.list_user = list_user;
    },
  },

  actions: {
    async retrieveUsers(state, queryParams) {
      const userRepository = new UserRepository();

      state.commit("setApiPaginationLoading", true);

      const userIndexResult = await userRepository.index(queryParams);

      if (userIndexResult.message != undefined) {
        logEvent(this.$analytics, "user-index", { result: "failed" });
        state.commit("setErrorMessage", userIndexResult);
      } else {
        logEvent(this.$analytics, "user-index", { result: "success" });
        state.commit("setApiPaginationResponse", userIndexResult);
      }

      state.commit("setApiPaginationLoading", false);
      return userIndexResult;
    },

    async getUser(state, id) {
      const userRepository = new UserRepository();

      state.commit("setLoading", true);

      const result = await userRepository.getUser(id);

      if (result.message != undefined) {
        logEvent(this.$analytics, "user-role-assign", { result: "failed" });
        state.commit("setErrorMessage", result);
      } else {
        logEvent(this.$analytics, "user-role-assign", { result: "success" });
      }

      state.commit("setLoading", false);

      return result.data;
    },

    async updateUser(state, data) {
      const userRepository = new UserRepository();

      state.commit("setLoading", true);

      const result = await userRepository.updateUser(data);

      if (result.message != undefined) {
        logEvent(this.$analytics, "user-update", { result: "failed" });
        state.commit("setErrorMessage", result);
      } else {
        logEvent(this.$analytics, "user-update", { result: "success" });
      }

      state.commit("setLoading", false);

      return result.data;
    },

    async createRoleUser(state, data) {
      const userRepository = new UserRepository();

      state.commit("setLoading", true);

      const result = await userRepository.createRoleUser(data);

      if (result.message != undefined) {
        logEvent(this.$analytics, "user-role-assign", { result: "failed" });
        state.commit("setErrorMessage", result);
      } else {
        logEvent(this.$analytics, "user-role-assign", { result: "success" });
      }

      state.commit("setLoading", false);

      return result;
    },

    async deleteRoleUser(state, id) {
      const userRepository = new UserRepository();

      state.commit("setLoading", true);

      const result = await userRepository.deleteRoleUser(id);

      if (result.message != undefined) {
        logEvent(this.$analytics, "user-role-delete", { result: "failed" });
        state.commit("setErrorMessage", result);
      } else {
        logEvent(this.$analytics, "user-role-delete", { result: "success" });
      }

      state.commit("setLoading", false);

      return result;
    },

    async fetchNotifications(state, filters = {}) {
      const userRepository = new UserRepository();

      state.commit("setLoading", true);

      const result = await userRepository.notifications(filters);

      if (result.message != undefined) {
        logEvent(this.$analytics, "user-get-notification", { result: "failed" });
        state.commit("setErrorMessage", result);
      } else {
        logEvent(this.$analytics, "user-get-notification", { result: "success" });
        state.commit("setNotificationPaginate", result.data);
      }

      state.commit("setLoading", false);

      return result.data;
    },

    async showNotification(state, notification_id) {
      const userRepository = new UserRepository();

      state.commit("setLoading", true);

      const result = await userRepository.showNotification(notification_id);

      if (result.message != undefined) {
        logEvent(this.$analytics, "user-show-notification", {
          result: "failed",
        });
        state.commit("setErrorMessage", result);
      } else {
        logEvent(this.$analytics, "user-show-notification", {
          result: "success",
        });
        state.commit("setNotification", result.data);
      }

      state.commit("setLoading", false);

      return result.data;
    },

    async markAsReadNotification(state, notification_id) {
      const userRepository = new UserRepository();

      state.commit("setLoading", true);

      const result = await userRepository.markAsReadNotification(
        notification_id
      );

      if (result.message != undefined) {
        logEvent(this.$analytics, "user-mark-notification", {
          result: "failed",
        });
        state.commit("setErrorMessage", result);
      } else {
        logEvent(this.$analytics, "user-mark-notification", {
          result: "success",
        });
      }

      state.commit("setLoading", false);

      return result.data;
    },

    async markAsReadAllNotification(state) {
      const userRepository = new UserRepository();

      state.commit("setLoading", true);

      const result = await userRepository.markAsReadAllNotification();

      if (result.message != undefined) {
        logEvent(this.$analytics, "user-mark-all-notification", {
          result: "failed",
        });
        state.commit("setErrorMessage", result);
      } else {
        logEvent(this.$analytics, "user-mark-all-notification", {
          result: "success",
        });
      }

      state.commit("setLoading", false);

      return result.data;
    },

    async notificationUnread(state) {
      const userRepository = new UserRepository();

      state.commit("setLoading", true);

      const result = await userRepository.notificationUnread();

      if (result.message != undefined) {
        logEvent(this.$analytics, "user-count-unread-notification", {
          result: "failed",
        });
        state.commit("setErrorMessage", result);
      } else {
        logEvent(this.$analytics, "user-count-unread-notification", {
          result: "success",
        });
        state.commit("setNotificationsUnread", result.data);
      }

      state.commit("setLoading", false);

      return result.data;
    },

    async getSalesListUserExports(state, queryParams = "") {
      state.commit("setLoading", true);
      const userRepository = new UserRepository();

      const userResult = await userRepository.getSalesListUserExports(queryParams);

      if (userResult.message != null) {
        logEvent(this.$analytics, "get-list-user-exports", { result: "failed" });
        state.commit("setErrorMessage", userResult);
      } else {
        logEvent(this.$analytics, "get-list-user-exports", { result: "success" });
        state.commit("setListUser", userResult.data);
      }

      state.commit("setLoading", false);

      return userResult;
    },
  },
};

import store from '@/stores/store';

export default (to, from, next) => {

    let business = store.getters["authStore/selectedRole"]?.business;

    if (business == null) {
        next({ name: 'business' })
    }

}
<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.1344 0H4.55036C4.08864 0 3.71436 0.374286 3.71436 1.10057V31.4286C3.71436 31.6257 4.08864 32 4.55036 32H27.4498C27.9115 32 28.2858 31.6257 28.2858 31.4286V7.416C28.2858 7.01829 28.2326 6.89029 28.1389 6.796L21.4898 0.146857C21.3955 0.0531429 21.2675 0 21.1344 0Z"
      fill="#E9E9E0"
    />
    <path
      d="M27.4498 32.0004H4.55036C4.08864 32.0004 3.71436 31.6261 3.71436 31.1644V22.2861H28.2858V31.1644C28.2858 31.6261 27.9115 32.0004 27.4498 32.0004Z"
      fill="#659C35"
    />
    <path
      d="M21.4287 0.0859375V6.85679H28.1996L21.4287 0.0859375Z"
      fill="#D9D7CA"
    />
    <path
      d="M9.9343 30.286H8.99658V24.5283H10.6526C10.8972 24.5283 11.1394 24.5672 11.3789 24.6455C11.6183 24.7237 11.8332 24.8409 12.0234 24.9969C12.2137 25.1529 12.3674 25.342 12.4846 25.5632C12.6017 25.7843 12.6606 26.0329 12.6606 26.3095C12.6606 26.6015 12.6109 26.8655 12.512 27.1026C12.4132 27.3397 12.2749 27.5392 12.0977 27.7003C11.9206 27.8615 11.7069 27.9866 11.4572 28.0752C11.2074 28.1637 10.9309 28.2077 10.6292 28.2077H9.93372V30.286H9.9343ZM9.9343 25.2392V27.5203H10.7937C10.908 27.5203 11.0212 27.5009 11.1337 27.4615C11.2457 27.4226 11.3486 27.3586 11.4423 27.27C11.536 27.1815 11.6114 27.058 11.6686 26.8992C11.7257 26.7403 11.7543 26.5437 11.7543 26.3095C11.7543 26.2157 11.7412 26.1072 11.7154 25.9855C11.6892 25.8632 11.636 25.746 11.5554 25.634C11.4743 25.522 11.3612 25.4283 11.2154 25.3529C11.0697 25.2775 10.8766 25.2397 10.6372 25.2397H9.9343V25.2392Z"
      fill="white"
    />
    <path
      d="M17.895 24.5283V30.286H16.9418L14.6841 26.3175V30.286H13.731V24.5283H14.6841L16.9418 28.4969V24.5283H17.895Z"
      fill="white"
    />
    <path
      d="M23.52 27.3174V29.5437C23.4 29.6951 23.2662 29.818 23.1177 29.9146C22.9691 30.0111 22.8131 30.0906 22.6491 30.1529C22.4851 30.2151 22.3154 30.2597 22.1411 30.286C21.9662 30.3123 21.7937 30.3249 21.6217 30.3249C21.2777 30.3249 20.9617 30.2626 20.6725 30.1374C20.3834 30.0123 20.1308 29.8277 19.9148 29.5826C19.6988 29.3374 19.5291 29.0306 19.4068 28.6609C19.2845 28.2911 19.2234 27.8637 19.2234 27.3797C19.2234 26.8957 19.2845 26.4694 19.4068 26.1026C19.5291 25.7351 19.6982 25.4294 19.9148 25.1849C20.1308 24.9397 20.3845 24.754 20.6765 24.626C20.968 24.498 21.2834 24.4346 21.6217 24.4346C21.9342 24.4346 22.2257 24.4866 22.4965 24.5906C22.7674 24.6951 23.0091 24.8511 23.2228 25.0591L22.5742 25.6374C22.4491 25.486 22.3057 25.3769 22.1445 25.3094C21.9828 25.242 21.8165 25.2077 21.6445 25.2077C21.452 25.2077 21.268 25.2437 21.0937 25.3169C20.9188 25.39 20.7628 25.5134 20.6251 25.6877C20.4868 25.8626 20.3788 26.0871 20.3011 26.3637C20.2234 26.6403 20.1811 26.978 20.176 27.3791C20.1811 27.77 20.2217 28.1083 20.2971 28.3946C20.3725 28.6809 20.4765 28.9151 20.6097 29.0974C20.7428 29.2797 20.8937 29.4151 21.0628 29.5037C21.232 29.5923 21.4102 29.6363 21.5982 29.6363C21.6554 29.6363 21.732 29.6323 21.8285 29.6243C21.9245 29.6163 22.0211 29.6037 22.1177 29.5854C22.2137 29.5671 22.3062 29.5426 22.3948 29.5111C22.4834 29.4797 22.5485 29.4357 22.5902 29.3786V27.9569H21.6137V27.3163H23.52V27.3174Z"
      fill="white"
    />
    <path
      d="M10.8179 10.8575C12.2598 10.8575 13.4287 9.68854 13.4287 8.2466C13.4287 6.80466 12.2598 5.63574 10.8179 5.63574C9.37595 5.63574 8.20703 6.80466 8.20703 8.2466C8.20703 9.68854 9.37595 10.8575 10.8179 10.8575Z"
      fill="#F3D55B"
    />
    <path
      d="M3.71436 22.2856H10.0001H28.2858V15.9999L22.5715 10.5713L16.5715 17.1427L13.4384 14.0096L3.71436 22.2856Z"
      fill="#88C057"
    />
  </svg>
</template>
import client from "@/data/base-client";
import WithAuthRepository from "./with-auth-repository";
import moment from "moment";

export default class BusinessRepresentativeRepository extends WithAuthRepository {
    constructor() {
        super();
    }

    async createOrUpdate(representative) {

        const response = (representative.id == null) ?
            await client.post("/businesses/" + representative.business_id + "/representatives", representative, this.getToken()) :
            await client.put("/businesses/" + representative.business_id + "/representatives/" + representative.id, representative, this.getToken());

        if (this.$lodash.isEmpty(response.data)) {
            return this.errorResponse(response);
        }

        return this.successResponse(response.data);
    }

    async createOrUpdateMasterBusiness(masterBusinessId, representative) {

        const response = (representative.id == null) ?
            await client.post("/master-businesses/" + masterBusinessId + "/businesses/" + representative.business_id + "/representatives", representative, this.getToken()) :
            await client.put("/master-businesses/" + masterBusinessId + "/businesses/" + representative.business_id + "/representatives/" + representative.id, representative, this.getToken());

        if (this.$lodash.isEmpty(response.data)) {
            return this.errorResponse(response);
        }

        return this.successResponse(response.data);
    }

    async retrieveRepresentative(business_id) {
        const response = await client.get(
            `/businesses/${business_id}/representatives`,
            this.getToken()
        );

        if (this.$lodash.isEmpty(response.data)) {
            return this.errorResponse(response);
        }

        return this.successResponse(response.data);
    }

    async icDocuments(business_id) {
        const response = await client.get(
            `/businesses/${business_id}/representatives/ic-documents`,
            this.getToken()
        );

        if (this.$lodash.isEmpty(response.data)) {
            return this.errorResponse(response);
        }

        return this.successResponse(response.data);
    }

    async typeIcDocuments(business_id) {
        const response = await client.get(
            `/businesses/${business_id}/representatives/ic-documents-types`,
            this.getToken()
        );
    
        if (response.data == null) {
          return this.errorResponse(response);
        }
    
        return this.successResponse(response.data);
      }

      async icDocumentsMasterBusiness(business_id, master_business_id) {
        const response = await client.get(
            `/master-businesses/${master_business_id}/businesses/${business_id}/representatives/ic-documents`,
            this.getToken()
        );

        if (this.$lodash.isEmpty(response.data)) {
            return this.errorResponse(response);
        }

        return this.successResponse(response.data);
    }

    async typeIcDocumentsMasterBusiness(business_id, master_business_id) {
        const response = await client.get(
            `/master-businesses/${master_business_id}/businesses/${business_id}/representatives/ic-documents-types`,
            this.getToken()
        );
    
        if (response.data == null) {
          return this.errorResponse(response);
        }
    
        return this.successResponse(response.data);
      }

}    
<template>
  <app-modal width="md:w-full lg:w-7/12 w-full" @close="$emit('close')">
    <template v-slot:body>
      <div class="flex flex-col md:flex-row">
        <div class="flex flex-col w-full pr-4 md:w-1/2 mb-5 md:mb-0">
          <p class="mb-5 font-semibold text-xl">
            {{
              isEditProduct
                ? $t("product.modal.edit_product")
                : $t("product.modal.add_product_title")
            }}
          </p>
          <form
            @submit.prevent="createProduct"
            class="flex flex-col items-start"
          >
            <app-button-upload-image
              :labelText="$t('product.modal.image_label')"
              :category="MEDIA_CATEGORY.PHOTO"
              :aspectRatio="1"
              @onTempUpload="onPhotoUpload"
              :preview="productPhoto"
            />
            <app-form-input
              id="product.name"
              name="product.name"
              type="text"
              :placeholder="$t('product.modal.name_placeholder')"
              :labelText="$t('product.modal.name_label')"
              class="w-full my-5"
              v-model="product.name"
              :errorMessage="errors.name"
            />

            <app-form-input-with-select
              class="w-full mb-5"
              type="number"
              step=".01"
              :labelText="$t('product.modal.price_label')"
              :placeholder="$t('product.modal.price_placeholder')"
              maxLength="7"
              :options="[
                {
                  id: 1,
                  desc: 'MYR',
                },
              ]"
              v-model="product.formatted_price"
              :errorMessage="errors.price"
            />

            <app-form-select
              class="w-full mb-16"
              :labelText="$t('product.modal.unit_label')"
              :placeholder="$t('product.modal.unit_placeholder')"
              :datas="unitLabel"
              :loading="loadingUnitLabel"
              :allowFilter="false"
              v-on:retry="fetchUnitLabel"
              v-model="product.unit_label"
              :errorMessage="errors.unit_label"
            />

            <app-button
              type="submit"
              :loading="loading"
              :showf70Icon="false"
              :disabled="hasActiveBusiness"
              class="w-full"
            >
              {{
                isEditProduct
                  ? $t("product.modal.edit_product")
                  : $t("product.modal.add_product_title")
              }}
            </app-button>
          </form>
        </div>
        <div class="md:border-l md:px-4 flex flex-col">
          <div class="flex flex-col md:flex-row items-center md:space-x-4">
            <p
              class="
                w-full
                text-left
                md:w-auto
                text-xl
                font-semibold
                my-2
                md:my-0
              "
            >
              {{ $t("product.modal.preview_title") }}
            </p>
          </div>
          <div class="mt-2 md:mt-5 flex flex-col items-center">
            <div v-if="productPhoto" class="w-90 h-90 rounded-md">
              <img :src="productPhoto" class="w-full object-contain" />
            </div>
            <div v-else class="bg-gray-300 w-90 h-90 rounded-md"></div>
            <p class="text-center text-2xl font-semibold mt-4">
              {{ product.name ?? "-" }}
            </p>
            <p class="text-center text-lg text-gray-400 my-2">
              {{
                $formats.formattedCurrency(
                  product?.currency ?? "MYR",
                  product?.formatted_price ?? 0
                )
              }}/{{ product?.unit_label?.id ?? "-" }}
            </p>
          </div>
        </div>
      </div>
    </template>
  </app-modal>
</template>

<script>
import MEDIA_CATEGORY from "@/utils/const/media_category";
import Business_status from "@/utils/const/business_status";

export default {
  emits: ["close", "refresh", "success"],
  data() {
    return {
      MEDIA_CATEGORY,
      filters: {},
      selectedProduct: null,
      emptyErrors: {
        name: [],
        currency: [],
        price: [],
        unit_label: [],
      },
    };
  },

  computed: {
    loading() {
      return this.$store.getters["productStore/loading"];
    },
    business() {
      return this.$store.getters["businessStore/business"];
    },
    hasActiveBusiness() {
      if (this.business.business_status_id == null) {
        return false;
      }
      return this.business.business_status_id != Business_status.APPROVED;
    },
    product() {
      return this.$store.getters["productStore/product"];
    },
    productPhoto() {
      const url = this.product?.media?.find(
        (media) => media.collection_name == MEDIA_CATEGORY.PHOTO
      )?.original_url;
      return url && url.startsWith("/")
        ? `${this.$store.$backendURL}${url}`
        : url;
    },
    isEditProduct() {
      return Boolean(this.product?._id);
    },
    unitLabel() {
      return this.$store.getters["productStore/unitLabel"];
    },
    loadingUnitLabel() {
      return this.$store.getters["productStore/loadingUnitLabel"];
    },
    errors() {
      return this.$store.getters["productStore/errors"] ?? this.emptyErrors;
    },
  },

  created() {
    this.fetchUnitLabel();
  },

  methods: {
    fetchUnitLabel() {
      this.$store.dispatch("productStore/retrieveAllUnitLabel");
    },

    onPhotoUpload(tempUrl) {
      this.product.photo_url = tempUrl?.media_path;
      this.product.media = [];
      if (tempUrl.media_url != null) {
        this.product.media.push({
          collection_name: MEDIA_CATEGORY.PHOTO,
          original_url: tempUrl.media_url,
        });
      }
    },

    async createProduct() {
      this.resetState();

      const product = await this.$store.dispatch(
        "productStore/createOrUpdateProduct",
        {
          id: this.product._id,
          name: this.product.name,
          currency: this.product.currency,
          price: this.$formats.toCents(this.product.currency, this.product.formatted_price),
          unit_label: this.product.unit_label?.id,
          photo_url: this.product.photo_url,
        }
      );

      if (!product) return;

      this.$emit("close");
      this.$emit("refresh", true);
      this.$emit("success", product.data);
    },

    resetState() {
      this.$store.commit("productStore/setErrorMessage");
    },
  },
};
</script>
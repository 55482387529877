import AuthDao from "../dao/auth-dao";
import client from "@/data/base-client";
import WithAuthRepository from "./with-auth-repository";
export default class AuthRepository extends WithAuthRepository {
    constructor() {
        super();
        this.authDao = new AuthDao();
    }

    async getUserFromToken() {
        const response =
            await client.get(
                "/auth/get-user-from-token",
                this.getToken()
            );

        if (this.$lodash.isEmpty(response.data)) {
            return this.errorResponse(response);
        }

        this.authDao.saveAuth(response.data);
        return this.successResponse(response.data);
    }

    async login(email, password, form_remember_me) {
        const loginResponse = await client.post("/auth/sign-in", {
            email: email,
            password: password,
        });

        if (this.$lodash.isEmpty(loginResponse.data)) {
            return this.errorResponse(loginResponse);
        }

        loginResponse.data.remember_me = form_remember_me;
        this.authDao.saveAuth(loginResponse.data);
        return this.successResponse(loginResponse.data);
    }

    async register(user) {
        const registerResponse = await client.post("/auth/sign-up", {
            email: user.email,
            name: user.name,
            phone_no: user.phone_no,
            password: user.password,
            country_id: user.country_id,
            user_agreement: user.user_agreement,
        });

        if (this.$lodash.isEmpty(registerResponse.data)) {
            return this.errorResponse(registerResponse);
        }

        this.authDao.saveAuth(registerResponse.data);
        return this.successResponse(registerResponse.data);
    }

    logout() {
        this.authDao.removeAuth();
    }

    async resendVerificationEmail() {
        const resendVerificationResponse = await client.post(
            "/auth/resend-verification-email",
            {},
            this.getToken()
        );
        if (this.$lodash.isEmpty(resendVerificationResponse.data)) {
            return this.errorResponse(resendVerificationResponse);
        }

        return this.successResponse(resendVerificationResponse.data);
    }

    async verifyEmailAdmin(email) {
        const verifyEmailResponse = await client.post(
            "/admin/verify-email-admin",
            {
                email: email,
            },
            this.getToken()
        );
        if (this.$lodash.isEmpty(verifyEmailResponse.data)) {
            return this.errorResponse(verifyEmailResponse);
        }

        return this.successResponse(verifyEmailResponse.data);
    }

    async forgotPassword(email) {
        const forgotPasswordResponse = await client.post("/auth/forgot-password", {
            email: email,
        });

        if (this.$lodash.isEmpty(forgotPasswordResponse.data)) {
            return this.errorResponse(forgotPasswordResponse);
        }

        return this.successResponse(forgotPasswordResponse.data);
    }

    async resetPassword(token, password) {
        const resetPasswordResponse = await client.post("/auth/reset-password", {
            token: token,
            password: password,
        });

        if (this.$lodash.isEmpty(resetPasswordResponse.data)) {
            return this.errorResponse(resetPasswordResponse);
        }

        return this.successResponse(resetPasswordResponse.data);
    }

    async assignDefaultRole(model_has_role_id, isDefault) {
        const response = await client.patch("/user/assign-default-user-role",
            {
              model_has_role_id: model_has_role_id,
              default: isDefault,
            },
            this.getToken()
        );

        if (this.$lodash.isEmpty(response.data)) {
            return this.errorResponse(response);
        }

        return this.successResponse(response.data);
    }

    async getAuditTrails(queryParams) {
        const response = await client.get(
          "admin/audit-trails" + queryParams,
          this.getToken()
        );
    
        if (this.$lodash.isEmpty(response.data)) {
          return response;
        }
    
        return this.successResponse(response.data);
    }

    setSelectedRole(selectedRole) {
        this.authDao.saveSelectedRole(selectedRole);
    }

    getSelectedRole() {
        return this.authDao.retrieveSelectedRole();
    }

    getLocalUser() {
        return this.authDao.retrieveUser();
    }

    getLocalAuth() {
        return {
            user: this.authDao.retrieveUser(),
            token: this.getToken(),
            selectedRole: this.getSelectedRole(),
        }
    }
}
import { createI18n } from 'vue-i18n/index'

function loadLocaleMessages() {
  
  const locales = require.context('@/utils/lang', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}

  locales.keys().forEach(key => {

    const matched = key.match(/([A-Za-z0-9-_]+)\./i)

    if (matched && matched.length > 1) {

      const locale = matched[1]

      messages[locale] = locales(key).default
      
    }
    
  })
  
  return messages

}

export default createI18n({
  legacy: false,
  globalInjection: true,
  messages: loadLocaleMessages(),
  locale: process.env.VUE_APP_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_LOCALE || 'en',
})

<template>
  <div>
    <app-modal-attach-tax
      :show="isAttachingTax"
      @close="isAttachingTax = false"
      @success="attachTax"
    />
  </div>
  <div class="overflow-x-auto">
    <table
      class="
        min-w-full
        divide-y divide-divider-gray
        border-b border-divider-gray
      "
    >
      <thead class="bg-white">
        <tr>
          <td class="px-1">{{ $t("invoice.create.product_image") }}</td>
          <td>{{ $t("invoice.create.product_or_item") }}</td>
          <td>{{ $t("invoice.create.product_price") }}</td>
          <td>{{ $t("invoice.create.product_qty") }}</td>
          <td>{{ $t("invoice.create.product_tax") }}</td>
          <td>{{ $t("invoice.create.product_amount") }}</td>
          <td>&nbsp;</td>
        </tr>
      </thead>
      <tbody class="bg-white divide-y divide-divider-gray">
        <app-form-invoice-product-item
          v-for="(item, idx) in invoice.items"
          :idx="idx"
          :key="idx"
          @attach-tax="displayProductTaxForm"
          @detach-tax="detachTax"
          @recalculate="calculateInvoiceAmount"
          @remove="removeProduct"
          @submit="handleProductSubmitted"
        />
        <tr v-if="canAddNewProduct">
          <td colspan="7" class="py-7">
            <div
              class="flex justify-center cursor-pointer space-x-3"
              @click="displayProductDropdown"
            >
              <app-icon-outline name="PlusCircleIcon" class="w-4" />
              <span class="font-bold">{{ $t("product.add_a_product") }}</span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div v-if="isSelecting" class="w-full">
    <app-form-select-custom-item
      :datas="products"
      :loading="apiPaginationLoading"
      :placeholder="$t('product.search_product')"
      :allowFilter="true"
      :allowButton="true"
      :buttonText="$t('product.add_new_product')"
      :showListTitle="true"
      :listTitleText="$t('product.product_title')"
      :alwaysOpen="true"
      @retry="getProducts"
      @button-click="displayProductForm"
      @valueSelected="addProduct"
      @close-dropdown="clearProduct"
      @filter="handleFilterTextChanged"
    >
      <template #item="{ item }">
        <div class="flex flex-row space-x-2 border py-3 px-3 items-center">
          <app-image
            v-if="getProductPhoto(item)"
            :src="getProductPhoto(item)"
            class="rounded-md flex-none w-12"
          />
          <img
            v-else
            class="w-12"
            src="@/assets/image/default_product_image.svg"
          />
          <span class="block truncate flex-grow">
            {{ item.name }}
          </span>
          <span class="block truncate text-gray-400">
            {{
              `${$formats.currency(item.currency, item.price)}/${
                item.unit_label
              }`
            }}
          </span>
        </div>
      </template>
    </app-form-select-custom-item>
  </div>
  <div
    class="text-sm space-y-2 pr-10"
    :class="{
      'rounded-md py-2 ring-1 ring-error': errors?.total_amount?.length > 0,
    }"
  >
    <!-- subtotal -->
    <div class="flex items-center justify-end">
      <div class="w-1/5">{{ $t("invoice.create.subtotal") }}</div>
      <div class="w-1/6 text-right">
        {{ $formats.currency(invoice.currency, invoice.subtotal) }}
      </div>
    </div>
    <div class="flex items-center justify-end pt-4 font-extrabold">
      <div class="w-1/5">{{ $t("invoice.create.total_due") }}</div>
      <div class="w-1/6 text-right">
        {{ $formats.currency(invoice.currency, invoice.total_amount) }}
      </div>
    </div>
  </div>
  <div
    v-if="errors?.total_amount?.length > 0"
    class="text-xs text-error text-right"
  >
    {{ errors.total_amount[0] }}
  </div>
</template>

<style>
tr.editing td .product-name > div > div {
  display: flex;
  flex-direction: column;
}
</style>

<script>
import {
  MAX_DROPDOWN_ITEMS,
  MAX_N_PRODUCTS,
} from "@/utils/const/invoice_settings";
import MEDIA_CATEGORY from "@/utils/const/media_category";
import TAX_TYPE from "@/utils/const/tax_type";

export default {
  data() {
    return {
      isSelecting: false,
      isEditing: false,
      isAttachingTax: false,
      filterText: "",
      taxTarget: "invoice",
    };
  },

  computed: {
    products() {
      return this.$store.getters["productStore/apiPaginationResponse"]?.data
        ?.data;
    },
    apiPaginationLoading() {
      return this.$store.getters["productStore/apiPaginationLoading"];
    },
    invoice() {
      return this.$store.getters["invoiceStore/invoice"];
    },
    errors() {
      return this.$store.getters["invoiceStore/errors"] ?? {};
    },
    invoiceId() {
      // @TODO: remove this when the BE is consistent using _id or id on listing/detail invoice/customer/product
      return this.invoice._id || this.invoice.id;
    },
    canAddNewProduct() {
      return (
        this.invoice.items.length < MAX_N_PRODUCTS &&
        !this.isSelecting &&
        !this.isEditing
      );
    },
  },

  async mounted() {
    await this.getProducts();
    this.fetchUnitLabel();
  },

  methods: {
    async getProducts() {
      let filters = `?page=1&per_page=${MAX_DROPDOWN_ITEMS}`;
      if (this.filterText) {
        filters += `&filter[name]=${this.filterText}`;
      }
      await this.$store.dispatch(
        "productStore/retrieveProduct",
        "?" + new URLSearchParams(filters).toString()
      );
    },
    fetchUnitLabel() {
      this.$store.dispatch("productStore/retrieveAllUnitLabel");
    },
    getProductPhoto(product) {
      const url = product?.media?.find(
        (media) => media.collection_name == MEDIA_CATEGORY.PHOTO
      )?.original_url;
      return url && url.startsWith("/")
        ? `${this.$store.$backendURL}${url}`
        : url;
    },
    handleFilterTextChanged(filterText) {
      this.filterText = filterText;
      this.getProducts();
    },
    clearProduct() {
      if (this.filterText) {
        this.filterText = "";
        this.getProducts();
      }
      this.isSelecting = false;
    },
    displayProductDropdown() {
      this.isSelecting = true;
    },
    displayProductForm() {
      this.isEditing = true;
      this.addProduct({
        name: "",
        currency: this.invoice.currency,
        price: "",
        unit_label: "-",
      });
    },
    handleProductSubmitted() {
      this.isEditing = false;
      if (this.products.length < MAX_DROPDOWN_ITEMS) {
        this.getProducts();
      }
    },
    addProduct(product) {
      this.isSelecting = false;
      if (
        this.invoice.items.length === 0 ||
        this.invoice.currency === product.currency
      ) {
        this.invoice.currency = product.currency;
        this.invoice.items.push({
          product_id: product._id,
          quantity: 1,
          ...product,
        });
      }
    },
    toPrecision(amount) {
      return Math.round(amount * 100) / 100;
    },
    calculateTax(amount, rate, type) {
      if (type === TAX_TYPE.INCLUSIVE) {
        return this.toPrecision(amount - (amount * 100) / (100 + rate));
      }
      if (type === TAX_TYPE.EXCLUSIVE) {
        return this.toPrecision((amount * rate) / 100);
      }
      return 0;
    },
    calculateInvoiceTax() {
      let tempSubtotal = 0;
      let tempTotal = 0;

      this.invoice.items.forEach((item) => {
        tempSubtotal = tempSubtotal + item.price * item.quantity;
        tempTotal = tempTotal + item.amount;
      });

      this.invoice.subtotal = tempSubtotal;
      this.invoice.total_amount = tempTotal;
    },
    calculateInvoiceAmount() {
      this.invoice.subtotal = this.invoice.items.reduce(
        (sum, item) => sum + item.amount,
        0
      );
      this.calculateInvoiceTax();
    },
    removeProduct(idx) {
      const item = this.invoice.items.splice(idx, 1)[0];
      if (!item._id) {
        this.isEditing = false;
      }
      this.calculateInvoiceAmount();
    },
    displayProductTaxForm(idx) {
      this.isAttachingTax = true;
      this.taxTarget = idx;
    },
    displayInvoiceTaxForm() {
      this.isAttachingTax = true;
      this.taxTarget = "invoice";
    },
    attachTax(data) {
      if (this.taxTarget === "invoice") {
        this.invoice.tax_id = data.tax._id;
        this.invoice.tax_type = data.type;
        this.invoice.tax_name = data.tax.name;
        this.invoice.tax_rate = data.tax.rate;
        this.calculateInvoiceTax();
      } else {
        const item = this.invoice.items[this.taxTarget];
        item.tax_id = data.tax._id;
        item.tax_type = data.type;
        item.tax_name = data.tax.name;
        item.tax_rate = data.tax.rate;
      }
      this.isAttachingTax = false;
    },
    detachTax(target) {
      this.taxTarget = target;
      const data = { tax: { _id: "", name: null, rate: 0 }, type: null };
      this.attachTax(data);
    },
  },

  watch: {
    invoiceId() {
      setTimeout(() => {
        this.calculateInvoiceTax();
      }, 500);
    },
  },
};
</script>

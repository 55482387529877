<template>
  <app-form-select
    :id="id"
    :name="name"
    :datas="countries"
    :showIcon="showIcon"
    :labelText="labelText ?? $t('general.country')"
    :errorMessage="errorMessage"
    :serverMessage="countriesServerMessage"
    :loading="loadingCountries"
    :showLabel="showLabel"
    :allowFilter="allowFilter"
    v-model="checkedValue"
    v-on:retry="getCountries"
    v-on:valueSelected="onValueSelected"
  />
</template>

<script>
export default {
  props: {
    id: {
      type: String,
    },
    labelText: {
      type: String,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    showIcon: {
      type: Boolean,
      default: false,
    },
    allowFilter: {
      type: Boolean,
      default: true,
    },
    errorMessage: {
      type: Array,
      default: () => [],
    },
    modelValue: {
      type: Object,
      default: () => {}
    },
  },

  computed: {
    checkedValue: {
      get() {
        return (
          this.modelValue ?? {
            id: 160,
            name: "Malaysia",
            icon: process.env.VUE_APP_BACKEND_URL + "/image/flag/mys.svg",
          }
        );
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },

    countries() {
      return this.$store.getters["referenceStore/countries"];
    },

    loadingCountries() {
      return this.$store.getters["referenceStore/loading"];
    },

    countriesServerMessage() {
      return this.$store.getters["referenceStore/message"] ?? "";
    },
  },

  mounted() {
    this.getCountries();
  },

  methods: {
    async getCountries() {
      this.$store.commit("referenceStore/resetState");
      await this.$store.dispatch("referenceStore/getCountries");
    },

    onValueSelected(value) {
      this.$emit("update:modelValue", value);
      this.$store.commit("referenceStore/setSelectedCountryId", value.id);
    },
  },
};
</script>



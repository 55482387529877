import client from "@/data/base-client";
import WithAuthRepository from "./with-auth-repository";

export default class CustomerRepository extends WithAuthRepository {
  constructor() {
    super();
  }

  async indexCustomer(business_id, queryParams) {
    const response = await client.get(
      "/customers/businesses/" + business_id + "/customers" + queryParams,
      this.getToken()
    );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async createOrUpdateCustomer(business_id, customer) {
    var to_submit_customers = this.$lodash.omitBy(customer, this.$lodash.isNil);

    const response =
      customer._id == null
        ? await client.post(
            "/customers/businesses/" + business_id + "/customers",
            to_submit_customers,
            this.getToken()
          )
        : await client.put(
            "/customers/businesses/" +
              business_id +
              "/customers/" +
              customer._id,
            to_submit_customers,
            this.getToken()
          );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async deleteCustomer(business_id, customer) {
    const response = await client.delete(
      "/customers/businesses/" + business_id + "/customers/" + customer._id,
      this.getToken()
    );

    if (this.$lodash.isEmpty(response.data)) {
      return response;
    }

    return response.data;
  }

  async getCustomerById(business_id, customer_id) {
    const response = await client.get(
      "/customers/businesses/" + business_id + "/customers/" + customer_id,
      this.getToken()
    );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }
  
}

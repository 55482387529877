<template>
  <app-form-select
    :datas="paymentGatewayApplicationStatuses"
    id="payment_gateway_application_status"
    name="payment_gateway_application_status"
    :errorMessage="errorMessage"
    :serverMessage="serverMessage"
    :loading="loading"
    :showLabel="showLabel"
    :allowFilter="allowFilter"
    v-model="checkedValue"
    v-on:retry="getPaymentGatewayApplicationStatuses()"
    v-on:valueSelected="onValueSelected"
  />
</template>

<script>
export default {
  props: {
    labelText: {
      type: String,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    showIcon: {
      type: Boolean,
      default: false,
    },
    allowFilter: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: Array,
      default: () => [],
    },
    modelValue: {
      type: Object,
      default: () => {},
    },
    country_id: {
      type: Number,
      default: 0,
    },
  },
  watch: {

    paymentGatpaymentGatewayApplicationStatuseseways(paymentGatewayApplicationStatuses) {
      if (this.$lodash.isEmpty(paymentGatewayApplicationStatuses)) {
        this.onValueSelected(null);
      }
    },
  },
  computed: {
    checkedValue: {
      get() {
        if (this.$lodash.isEmpty(this.paymentGatewayApplicationStatuses)) {
          return {};
        }
        return this.modelValue ?? [];
      },
      set(value) {
        this.onValueSelected(value);
      },
    },

    paymentGatewayApplicationStatuses() {
      return this.$store.getters["referenceStore/paymentGatewayApplicationStatuses"];
    },

    loading() {
      return this.$store.getters["referenceStore/loading"];
    },

    serverMessage() {
      return this.$store.getters["referenceStore/message"] ?? "";
    },
  },

  async mounted() {
    this.getPaymentGatewayApplicationStatuses();
  },

  methods: {
    async getPaymentGatewayApplicationStatuses() {
      this.$store.commit("referenceStore/resetState");
      this.$store.dispatch("referenceStore/getPaymentGatewayApplicationStatuses");
    },

    onValueSelected(value) {
      this.$emit("update:modelValue", value);
    },
  },
};
</script>



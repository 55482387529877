<template>
  <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
    <app-form-selection-box
      v-for="paymentGatewayMethod in paymentMethods"
      :key="paymentGatewayMethod.id"
      :id="paymentGatewayMethod.id"
      name="payment_method_selection"
      :active="paymentGatewayMethod.payment_method_id === checkedValue?.payment_method_id"
      @click="selectPaymentMethod(paymentGatewayMethod)"
      :disabled="disabled"
    >
      <img
        class="object-contain w-full"
        :class="[$attrs.class ? $attrs.class : 'h-12']"
        alt="logo"
        :src="paymentGatewayMethod.payment_method?.image_url"
      />
      <p>{{ paymentGatewayMethod.payment_method?.name ?? "" }}</p>
    </app-form-selection-box>
  </div>
</template>

<script>
export default {
  props: {
    labelText: {
      type: String,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    showIcon: {
      type: Boolean,
      default: false,
    },
    allowFilter: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: Array,
      default: () => [],
    },
    modelValue: {
      type: Object,
      default: () => {},
    },
    payment_gateway_id: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    payment_gateway_id: function (newVal, oldValue) {
      this.getPaymentGatewayMethods();
    },
    paymentGatewayMethods(paymentGatewayMethods) {
      if (this.$lodash.isEmpty(paymentGatewayMethods)) {
        this.onValueSelected(null);
      }
    },
  },
  computed: {
    checkedValue: {
      get() {
        if (this.$lodash.isEmpty(this.paymentGatewayMethods)) {
          return {};
        }
        return this.modelValue ?? [];
      },
      set(value) {
        this.onValueSelected(value);
      },
    },

    paymentGatewayMethods() {
      return this.$store.getters["referenceStore/paymentGatewayMethods"];
    },

    loading() {
      return this.$store.getters["referenceStore/loading"];
    },

    serverMessage() {
      return this.$store.getters["referenceStore/message"] ?? "";
    },

    paymentMethods: function () {
      return this.$lodash.uniqBy(this.paymentGatewayMethods, function (paymentMethod) {
        return paymentMethod.payment_method_id;
      });
    },
  },

  async mounted() {
    this.getPaymentGatewayMethods();
  },

  methods: {
    async getPaymentGatewayMethods() {
      this.$store.commit("referenceStore/resetState");
      this.$store.dispatch(
        "referenceStore/getPaymentGatewayMethods",
        this.payment_gateway_id
      );
    },

    onValueSelected(value) {
      this.$emit("update:modelValue", value);
    },

    selectPaymentMethod(value) {
      this.checkedValue = value;
    },
  },
};
</script>



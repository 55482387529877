<template>
  <div class="text-sm">
    <span :classText="classText" class="text-justify whitespace-pre-line">
      {{ formattedText }}
    </span>
    <span
      v-if="showReadMode"
      @click="showingFullText = !showingFullText"
      class="font-bold cursor-pointer"
      :class="textReadMore"
    >
      {{ showingFullText ? $t("general.read_less") : $t("general.read_more") }}
    </span>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showingFullText: false,
    };
  },
  props: {
    text: {
      type: String,
      required: true,
    },
    length: {
      type: Number,
      default: 300,
    },
    classText: {
      type: String,
    },
    classReadMore: {
      type: String,
    },
    showReadMore: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    formattedText() {
      if (this.showingFullText || !this.showReadMore) {
        return `${this.text} `;
      }

      let text = `${this.text.slice(0, this.length).trim()}`;

      if (this.showReadMode) {
        text += "... ";
      }

      return text;
    },
    textLength() {
      return this.text?.length ?? 0;
    },
    showReadMode() {
      return this.showReadMore && this.textLength > this.length;
    },
  },
};
</script>
import store from '@/stores/store';
import Role from "@/utils/const/role";

export default (to, from, next) => {

    if (store.getters["authStore/selectedRole"]?.role_id == Role.ADMIN) {
        next({ name: 'user.index' })
    } else if (store.getters["authStore/selectedRole"]?.role_id == Role.SUPPORT) {
        next({ name: 'support.payment.link.index' })
    } else if (store.getters["authStore/selectedRole"]?.role_id == Role.SALES) {
        next({ name: 'sales.business.index' })
    } else {
        next({ name: 'dashboard' })
    }

}
import { VueReCaptcha } from 'vue-recaptcha-v3'
import Pusher from "pusher-js";
import store from "@/stores/store";

function registerAppComponent(app) {

  const requireComponent = require.context('@/views/components', true, /app-[\w-]+\.vue$/)

  requireComponent.keys().forEach((fileName) => {

    const componentConfig = requireComponent(fileName)

    const componentName = fileName
      .split('/')
      .pop()
      .replace(/\.\w+$/, '')

    app.component(componentName, componentConfig.default || componentConfig)

  })
}

function registerReCaptchaComponent(app) {

  app.use(VueReCaptcha,
    {
      siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
      loaderOptions: {
        autoHideBadge: true
      }
    })

  app.component('VueReCaptcha', VueReCaptcha);
}

function registerBroadcast(app) {

  store.dispatch("authStore/checkAuth", false).then((auth) => {

    if (auth?.token) {

      if (process.env.NODE_ENV == "development") {
        Pusher.logToConsole = true
      }

      app.config.globalProperties.$pusher = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY, {
        cluster: process.env.VUE_APP_PUSHER_CLUSTER,
        enabledTransports: ['ws'],
        forceTLS: true
      });

    }

  });
}

export default function register(app) {

  registerBroadcast(app)
  registerAppComponent(app)
  registerReCaptchaComponent(app)

}

<template>
  <div :class="$attrs.class" class="flex flex-col">
    <p v-if="label" :class="labelClass" class="mb-3">{{ label }}</p>
    <div class="w-full space-x-6">
      <label
        v-for="{ id, name: label } in options"
        :key="id"
        class="inline-flex items-center"
      >
        <input
          type="radio"
          class="form-radio focus:ring-transparent"
          :class="hasError ? ['ring-1 ring-error border-none'] : ['border-1']"
          :name="name"
          :value="id"
          :disabled="disabled"
          v-model="selectedValue"
        />
        <span :class="['ml-2', { 'text-gray-400': disabled }]">
          {{ label }}
        </span>
      </label>
    </div>
    <span v-if="hasError" class="mt-1 text-xs text-error">
      {{ errorMessage[0] }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
    },
    label: {
      type: String,
    },
    labelClass: {
      type: String,
    },
    modelValue: {},
    options: {},
    errorMessage: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    selectedValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    hasError() {
      return this.errorMessage?.length > 0;
    },
  },
};
</script>
import client from "@/data/base-client";
import WithAuthRepository from "./with-auth-repository";
import BUSINESS_STATUS from "@/utils/const/business_status";

export default class BusinessRepository extends WithAuthRepository {
  constructor() {
    super();
  }

  async createOrUpdateMasterBusiness(masterBusinessId, business) {
    business.country_id = business.country != null ? business.country.id : 0;
    business.state_id = business.state != null ? business.state.id : 0;
    business.type_of_business_id =
      business.type_of_business != null ? business.type_of_business.id : 0;

    const response =
      business.id == null
        ? await client.post("/master-businesses/" + masterBusinessId + "/businesses", business, this.getToken())
        : await client.put(
          "/master-businesses/" + masterBusinessId + "/businesses/" + business.id,
          business,
          this.getToken()
        );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(this.mapData(response.data));
  }

  async getMasterBusinessById(masterBusinessId, businessId) {
    const response = await client.get(
      "/master-businesses/" + masterBusinessId + "/businesses/" + businessId,
      this.getToken()
    );

    if (this.$lodash.isEmpty(response.data)) {
      return response;
    }


    return this.successResponse(this.mapData(response.data));
  }

  async getMasterBusiness(masterBusinessId, params = {}) {

    const response = await client.get(
      "/master-businesses/" + masterBusinessId + "/businesses",
      this.getToken(),
      params,
    );

    if (this.$lodash.isEmpty(response.data)) {
      return response;
    }


    return this.successResponse(response.data);
  }

  async copyBusiness(masterBusinessId, businessId) {
    const response = await client.get(`/master-businesses/${masterBusinessId}/businesses/${businessId}/copy`, this.getToken());

    if (this.$lodash.isEmpty(response.data)) {
      return response;
    }

    return this.successResponse(this.mapData(response.data));
  }

  async submitMasterBusiness(masterBusinessId, businessId) {
    const response = await client.post(
      "/master-businesses/" + masterBusinessId + "/businesses/" + businessId + "/submit",
      {},
      this.getToken()
    );

    if (this.$lodash.isEmpty(response.data)) {
      return response;
    }

    return this.successResponse(this.mapData(response.data));
  }


  async getListBusinessByMasterMerchantId(masterBusinessId, queryParams) {
    const response = await client.get(
      "/master-businesses/" + masterBusinessId + "/all-businesses" + queryParams,
      this.getToken()
    );

    if (this.$lodash.isEmpty(response.data)) {
      return response;
    }

    return this.successResponse(response.data);
  }

  mapData(business) {
    var populateBusiness = business;

    populateBusiness.country.icon = business.country?.flag;
    populateBusiness.type_of_business.name = business.type_of_business.description;

    if (populateBusiness.bank?.bank) {
      populateBusiness.bank.bank.name = business.bank.bank.full_name;
      populateBusiness.bank.bank.icon = business.bank.bank.logo_url;
    }

    return populateBusiness;
  }

  async createOrUpdateMasterBusinessOwner(masterBusinessId, businessOwner) {
    const response =
      businessOwner.id == null
        ? await client.post(
          "/master-businesses/" + masterBusinessId + "/businesses/" + businessOwner.business_id + "/owners",
          businessOwner,
          this.getToken()
        )
        : await client.put(
          "/master-businesses/" + masterBusinessId + "/businesses/" + businessOwner.business_id + "/owners/" + businessOwner.id,
          businessOwner,
          this.getToken()
        );

    if (this.$lodash.isEmpty(response.data)) {
      return response;
    }

    return response.data;
  }

  async deleteMasterBusinessOwner(masterBusinessId, businessOwner) {
    const response = await client.delete(
      "/master-businesses/" + masterBusinessId + "/businesses/" + businessOwner.business_id + "/owners/" + businessOwner.id,
      this.getToken()
    );

    if (this.$lodash.isEmpty(response.data)) {
      return response;
    }

    return response.data;
  }

  async documentsMasterBusinessOwner(business_id, master_business_id) {
    const response = await client.get(
      `/master-businesses/${master_business_id}/businesses/${business_id}/owners/ic-documents`,
      this.getToken()
    );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async typeDocumentsMasterBusinessOwner(business_id, master_business_id) {
    const response = await client.get(
        `/master-businesses/${master_business_id}/businesses/${business_id}/owners/ic-documents-types`,
        this.getToken()
    );

    if (response.data == null) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async updatePayoutTo(masterBusinessId, businessId, payoutTo) {
    const response = await client.patch(
      "/master-businesses/" + masterBusinessId + "/businesses/" + businessId + "/update-payout-to",
      {
        payout_to: payoutTo,
      },
      this.getToken()
    );

    if (this.$lodash.isEmpty(response.data)) {
      return response;
    }

    return this.successResponse(response.data);
  }

}

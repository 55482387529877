import axios from "axios";
import store from "@/stores/store";

export default {

  async put(path = '/', data = {}, token = '') {
    const baseURL = store.$backendURL + '/api'
    try {
      var response = await axios.create({
        baseURL,
        headers: {
          "content-type": "application/json",
          "Accept": "application/json",
          "Authorization": "Bearer " + token
        }
      }).put(path, data);
      return {
        data: response.data.data,
        message: null,
        errors: []
      }
    } catch (errorResponse) {
      return {
        data: {},
        message: this.massageErrorResponse(errorResponse.response.data),
        errors: (errorResponse.response.data && errorResponse.response.data.errors) ? errorResponse.response.data.errors : []
      }
    }
  },

  async patch(path = '/', data = {}, token = '') {
    const baseURL = store.$backendURL + '/api'
    try {
      var response = await axios.create({
        baseURL,
        headers: {
          "content-type": "application/json",
          "Accept": "application/json",
          "Authorization": "Bearer " + token
        }
      }).patch(path, data);
      return {
        data: response.data.data,
        message: null,
        errors: []
      }
    } catch (errorResponse) {
      return {
        data: {},
        message: this.massageErrorResponse(errorResponse.response.data),
        errors: (errorResponse.response.data && errorResponse.response.data.errors) ? errorResponse.response.data.errors : []
      }
    }
  },

  async post(path = '/', data = {}, token = '') {
    const baseURL = store.$backendURL + '/api'
    try {
      var response = await axios.create({
        baseURL,
        headers: {
          "content-type": "application/json",
          "Accept": "application/json",
          "Authorization": "Bearer " + token
        }
      }).post(path, data);
      return {
        data: response.data.data,
        message: null,
        errors: []
      }
    } catch (errorResponse) {
      return {
        data: {},
        message: this.massageErrorResponse(errorResponse.response.data),
        errors: (errorResponse.response.data && errorResponse.response.data.errors) ? errorResponse.response.data.errors : []
      }
    }
  },

  async get(path = '/', token = '', params = {}) {
    const baseURL = store.$backendURL + '/api'
    try {
      var response = await axios.create({
        baseURL,
        headers: {
          "content-type": "application/json",
          "Accept": "application/json",
          "Authorization": "Bearer " + token
        }
      }).get(path, { params: params });
      return {
        data: response.data.data,
        message: null,
        errors: []
      }
    } catch (errorResponse) {
      return {
        data: {},
        message: this.massageErrorResponse(errorResponse.response.data),
        errors: (errorResponse.response.data && errorResponse.response.data.errors) ? errorResponse.response.data.errors : []
      }
    }
  },

  async delete(path = '/', token = '', params = {},) {
    const baseURL = store.$backendURL + '/api'
    try {
      var response = await axios.create({
        baseURL,
        headers: {
          "content-type": "application/json",
          "Accept": "application/json",
          "Authorization": "Bearer " + token
        }
      }).delete(path, { params: params });
      return {
        data: response.data.data,
        message: null,
        errors: []
      }
    } catch (errorResponse) {
      return {
        data: {},
        message: this.massageErrorResponse(errorResponse.response.data),
        errors: (errorResponse.response.data && errorResponse.response.data.errors) ? errorResponse.response.data.errors : []
      }
    }
  },

  async postFile(path = '/', data = {}, token = '') {
    const baseURL = store.$backendURL + '/api'
    try {
      var response = await axios.create({
        baseURL,
        headers: {
          'Content-Type': 'multipart/form-data',
          "Accept": "application/json",
          "Authorization": "Bearer " + token
        }
      }).post(path, data);
      return {
        data: response.data.data,
        message: null,
        errors: []
      }
    } catch (errorResponse) {
      return {
        data: {},
        message: this.massageErrorResponse(errorResponse.response.data),
        errors: (errorResponse.response.data && errorResponse.response.data.errors) ? errorResponse.response.data.errors : []
      }
    }
  },

  massageErrorResponse(errorResponse) {
    return (errorResponse)
      ? (errorResponse.errors)
        ? errorResponse.errors[Object.keys(errorResponse.errors)[0]][0]
        : errorResponse.message
      : '$general.message';
  }

}
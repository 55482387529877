import store from './store'
export default {
    state: {
        loading: false,
        errors: [],
        message: null,
        apiResponse: {
            success: false,
            data: null,
            errors: null,
            meta: null
        },
        apiPaginationLoading: false,
        apiPaginationResponse: {
            success: false,
            data: {
                current_page: 0,
                data: [],
                first_page_url: null,
                from: 0,
                last_page: 0,
                last_page_url: null,
                links: [],
                next_page_url: null,
                path: null,
                per_page: 0,
                prev_page_url: null,
                to: 0,
                total: 0,
            },
            errors: null,
            meta: null
        }
    },
    getters: {
        apiPaginationResponse(state) {
            return state.apiPaginationResponse;
        },

        apiPaginationLoading(state) {
            return state.apiPaginationLoading;
        },

        message(state) {
            return state.message;
        },

        errors(state) {
            return state.errors;
        },

        loading(state) {
            return state.loading;
        }
    },
    mutations: {
        resetState(state, emptyErrors) {
            store.dispatch('setGeneralErrors', {})
            store.dispatch('setGeneralErrorMessage', null)
            state.message = null;
            state.errors = emptyErrors;
            state.loading = true;
        },

        setApiPaginationLoading(state, apiPaginationLoading) {
            state.apiPaginationLoading = apiPaginationLoading;
        },

        setApiPaginationResponse(state, apiPaginationResponse) {
            state.apiPaginationResponse = apiPaginationResponse;
        },

        setErrorMessage(state, errorResponse = { message: null, errors: []}) {
            store.dispatch('setGeneralErrors', errorResponse.errors)
            store.dispatch('setGeneralErrorMessage', errorResponse.message)
            state.message = errorResponse.message;
            state.errors = errorResponse.errors;
            state.loading = false;
            state.apiPaginationLoading = false;
        },

        setError(state, errors) {
            store.dispatch('setGeneralErrors', errors)
            state.errors = errors;
            state.loading = false;
            state.apiPaginationLoading = false;
        },

        setMessage(state, message) {
            state.message = message;
            state.loading = false;
            state.apiPaginationLoading = false;
        },

        setLoading(state, loading) {
            state.loading = loading;
        }
    }
}
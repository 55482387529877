<template>
  <div
    class="
      flex-none
      bg-gray-100
      h-full
      rounded-lg
      pt-3
      pb-3
      pl-2
      pr-2
      text-gray-500
      lg:ml-10
      ml-0
      mt-2
      lg:mt-6
      text-sm
    "
  >
    {{ infoText }}
  </div>
</template>
<script>
export default {
  props: {
    infoText: {
      type: String,
      required: true,
    },
  },
};
</script>

<template>
  <div
    class="text-center flex capitalize text-xs"
    :class="{ 'place-items-center justify-center': is_center }"
  >
    <div
      :class="[
        {
          'bg-success-badge text-green-900': status == 'success',
          'bg-error-badge text-red-900': status == 'error',
          'bg-warning-badge text-yellow-900': status == 'warning',
          'bg-light-badge text-gray-900': status == 'light',
          'border text-gray-900': status == 'outline',
        },
        padding,
        rounded,
        $attrs.class,
      ]"
    >
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    status: String,
    padding: {
      type: String,
      default: "py-1 px-3",
    },
    rounded: {
      type: String,
      default: "rounded-md",
    },
    is_center: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
<template>
  <app-full-modal :title="$t('wallet.payout.payout_detail')">
    <template v-slot:body>
      <div v-if="loading">
        <app-icon-loading iconColor="primary" class="mx-auto mt-10" />
      </div>

      <div v-else-if="payout">
        <!--Payout Details -->

        <div class="p-5 space-y-14">
          <div class="space-y-4">
            <div>
              <p class="col-span-1 text-gray-400" width="120px">
                {{ $t("wallet.payout.payout") }}
              </p>
            </div>
            <div class="flex flex-row space-x-2 items-center">
              <p class="font-semibold">
                {{ $formats.currency(payout.currency, payout.amount ?? 0.0) }}
              </p>
              <app-badge class="capitalize text-xs w-20" :status="getStatus(payout.status)">
                {{ payout.status ?? "-" }}
              </app-badge>
            </div>

            <div class="grid grid-cols-3 text-sm space-x-3">
              <div class="border-r-2">
                <p class="row-span-2 text-gray-400">
                  {{ $t("wallet.payout.date") }}
                </p>

                <p class="col-span-4">
                  {{ $moment(payout.created_at).format("DD MMM YYYY hh:mm a") }}
                </p>
              </div>

              <div class="border-r-2">
                <p class="col-span-1 text-gray-400">
                  {{ $t("wallet.payout.bank_acc") }}
                </p>
                <div class="flex flex-row justify-between mr-3">
                  <p class="col-span-1 capitalize">
                    {{ payout.business.bank?.bank.display_name ?? "-" }}
                  </p>
                  <app-icon-withdraw />
                </div>
              </div>

              <div class="">
                <p class="col-span-1 text-gray-400">
                  {{ $t("wallet.payout.email_address") }}
                </p>
                <p class="col-span-4">
                  {{ payout.business?.users[0]?.email ?? "-" }}
                </p>
              </div>
            </div>
          </div>

          <div v-if="payout.reason">
            <div class="grid grid-cols-5 text-sm">
              <p class="col-span-1 text-gray-400" width="120px">
                {{ $t("wallet.payout.remarks") }}
              </p>
              <p class="col-span-4">
                {{ payout.reason ?? "-" }}
              </p>
            </div>
          </div>

          <!--Account Bank Details -->

          <div class="space-y-3">
            <div class="pb-2 border-b-2">
              <p class="col-span-1 font-semibold" width="120px">
                {{ $t("wallet.payout.account_bank_details") }}
              </p>
            </div>
            <div v-if="isMasterBusinessRole" class="grid grid-cols-5 text-sm">
              <p class="col-span-1 text-gray-400" width="120px">
                {{ $t("wallet.payout.business_name") }}
              </p>
              <p class="col-span-4">
                {{ payout.business?.name }}
              </p>
            </div>
            <div class="grid grid-cols-5 text-sm">
              <p class="col-span-1 text-gray-400" width="120px">
                {{ $t("wallet.payout.bank_name") }}
              </p>
              <p class="col-span-4">
                {{ payout.business.bank?.bank.display_name ?? "-" }}
              </p>
            </div>
            <div class="grid grid-cols-5 text-sm">
              <p class="col-span-1 text-gray-400" width="120px">
                {{ $t("wallet.payout.account_no") }}
              </p>
              <p class="col-span-4">
                {{ payout.business.bank?.account_no ?? "-" }}
              </p>
            </div>
            <div class="grid grid-cols-5 text-sm">
              <p class="col-span-1 text-gray-400" width="120px">
                {{ $t("wallet.payout.origin") }}
              </p>
              <p class="col-span-4">
                {{ payout.business.country?.name ?? "-" }}
              </p>
            </div>
            <div class="grid grid-cols-5 text-sm">
              <p class="col-span-1 text-gray-400" width="120px">
                {{ $t("wallet.payout.issuer") }}
              </p>
              <p class="col-span-4">
                {{ payout.business.bank?.bank.full_name ?? "-" }}
              </p>
            </div>
            <div v-if="payout.resolved_at" class="grid grid-cols-5 text-sm">
              <p class="col-span-1 text-gray-400" width="120px">
                {{ $t("wallet.payout.resolved_at") }}
              </p>
              <p class="col-span-4">
                {{ $moment(payout.resolved_at).format("DD MMM YYYY hh:mm a") }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div v-if="showBusiness">
        <!-- Transaction Details -->
        <p class="mt-5 font-medium px-4 py-2">
          {{ $t("wallet.transaction.business_detail") }}
        </p>
        <hr />

        <div class="p-5 space-y-2">
          <div class="block sm:grid grid-cols-5 text-sm">
            <p class="col-span-1 text-gray-400" width="120px">
              {{ $t("wallet.transaction.business_id") }}
            </p>
            <p class="col-span-4">{{ payout.business?.id ?? "-" }}</p>
          </div>
          <div class="block sm:grid grid-cols-5 text-sm">
            <p class="col-span-1 text-gray-400" width="120px">
              {{ $t("wallet.transaction.business_name") }}
            </p>
            <p class="col-span-4">{{ payout.business?.name ?? "-" }}</p>
          </div>
          <div class="block sm:grid grid-cols-5 text-sm">
            <p class="col-span-1 text-gray-400" width="120px">
              {{ $t("wallet.transaction.created_at") }}
            </p>
            <p class="col-span-4">
              {{
                  $moment(payout.business?.created_at).format(
                    "DD MMM YYYY hh:mm a"
                  )
              }}
            </p>
          </div>
          <div class="block sm:grid grid-cols-5 text-sm">
            <p class="col-span-1 text-gray-400" width="120px">
              {{ $t("wallet.transaction.submitted_at") }}
            </p>
            <p class="col-span-4">
              {{
                  $moment(payout.business?.submitted_at).format(
                    "DD MMM YYYY hh:mm a"
                  )
              }}
            </p>
          </div>
        </div>
      </div>

      <div class="px-5 mt-2" v-if="payout.type == 'auto'">
        <div class="pb-2 border-b-2">
          <p class="col-span-1 font-semibold" width="120px">
            {{ $t("wallet.payout.summary") }}
          </p>
        </div>
        <div class="overflow-x-auto">
          <table>
            <thead class="border-b-2">
              <th class="font-bold pl-0 pr-0">{{ $t("wallet.transaction.description") }}</th>
              <th class="font-bold" v-if="payout.as_master_business">{{ $t("wallet.transaction.business_name") }}</th>
              <th class="font-bold">{{ $t("wallet.transaction.count") }}</th>
              <th class="font-bold">{{ $t("wallet.transaction.gross") }}</th>
              <th class="font-bold">{{ $t("wallet.transaction.fee") }}</th>
              <th class="font-bold">{{ $t("wallet.transaction.net_amount") }}</th>
              <th class="font-bold pr-0">{{ $t("general.action") }}</th>
            </thead>
            <tbody class="border-b-2">
              <tr v-for="wallet in filterRelevantWallet(payout.wallets, payout._id)" :key="wallet._id">
                <td class="pl-0">{{ wallet.description }}</td>
                <td v-if="payout.as_master_business">{{ wallet?.business?.name }}</td>
                <td class="text-center">{{ wallet.reference?.summary?.count ?? 0 }}</td>
                <td>{{ $formats.currency(wallet.currency, wallet.reference?.summary?.amount ?? 0) }}</td>
                <td>{{ $formats.currency(wallet.currency, wallet.reference?.summary?.charge ?? 0) }}</td>
                <td>{{ $formats.currency(wallet.currency, getNetAmount(wallet) ?? 0) }}</td>
                <td class="pr-0">
                  <div class="flex justify-center" v-if="isSettlement(wallet)">
                    <app-link :to="{
                      name: processedPaymentLinkRoute,
                      params: {
                        settlement_id: wallet.reference_id,
                        business_id: wallet.business_id,
                      },
                    }">
                      <app-button-outline>
                        {{ $t("wallet.transaction.view_payment_details") }}
                      </app-button-outline>
                    </app-link>
                  </div>

                  <div class="flex justify-center" v-else-if="isMasterBusinessSettlement(wallet)">
                    <app-link :to="{
                      name: processedMasterBusinessPaymentLinkRoute,
                      params: {
                        master_business_settlement_id: wallet.reference_id,
                        business_id: wallet.business_id,
                      },
                    }">
                      <app-button-outline>
                        {{ $t("wallet.transaction.view_payment_details") }}
                      </app-button-outline>
                    </app-link>
                  </div>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td class="font-bold pl-0 pr-0" colspan="4">{{ $t("wallet.transaction.total") }}</td>
                <td class="font-bold pr-0"> {{ $formats.currency(payout.currency, payout.amount ?? 0.0) }}</td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>

    </template>
  </app-full-modal>
</template>

<script>

export default {
  props: {
    payout: {
      type: Object,
      default: null,
    },
    showBusiness: {
      type: Boolean,
      default: false,
    },
    processedPaymentLinkRoute: {
      type: String,
      default: 'balance.transaction.payment-link'
    },
    processedMasterBusinessPaymentLinkRoute: {
      type: String,
      default: 'balance.transaction.master-business-payment-link'
    }
  },
  data() {
    return {};
  },
  computed: {
    loading() {
      return this.$store.getters["walletStore/loading"];
    },

    isMasterBusinessRole() {
      return this.$store.getters["authStore/isMasterBusinessRole"]
    },
  },
  methods: {
    getStatus(status) {
      switch (status) {
        case "approved":
          return "success";
        case "processing":
          return "light";
        case "requested":
          return "light";
        case "rejected":
          return "error";
      }
    },

    filterRelevantWallet(wallets, payout_id) {
      return wallets.filter(
        wallet => wallet.reference_model != 'Modules\\Wallet\\Entities\\Payout' && wallet.reference_id != payout_id
      )
    },

    getNetAmount(wallet) {
      if (!this.isSettlement(wallet))
        return wallet.amount;

      return wallet.reference?.summary?.net;
    },

    isSettlement(wallet) {
      return wallet.reference_model == 'Modules\\Wallet\\Entities\\Settlement';
    },

    isMasterBusinessSettlement(wallet) {
      return wallet.reference_model == 'Modules\\Wallet\\Entities\\MasterBusinessSettlement';
    },

    getPaymentLinkRoute() {
      return (this.selectedRole.business_id != null) ? '' : 'support.balance.transaction.payment-link';
    }
  },
};
</script>

<template>
  <app-full-modal :title="$t('integration.webhook_event.webhook_event_logs')">
    <template v-slot:body>
      <div v-if="webhookEventLog" class="p-5">
        <!-- Webhook Details  -->
        <div class="space-y-4">
          <div class="space-y-1 text-sm">
            <div class="block sm:grid grid-cols-5 text-sm">
              <p class="col-span-1" width="120px">
                {{ $t("integration.webhook_event.status") }}:
              </p>
              <p class="col-span-4 mb-1">
                <app-badge
                  :status="
                    webhookEventLog.status == 'succeed' ? 'success' : 'light'
                  "
                  class="w-16 text-xs"
                >
                  {{ webhookEventLog.status }}
                </app-badge>
              </p>
            </div>
            <div class="block sm:grid grid-cols-5 text-sm">
              <p class="col-span-1" width="120px">
                {{ $t("integration.webhook_event.event") }}:
              </p>
              <p class="col-span-4">
                <span class="capitalize">{{
                  webhookEventLog.payload.event?.replace(".", " ")
                }}</span>
                ( {{ webhookEventLog.payload.event }} )
              </p>
            </div>
            <div class="block sm:grid grid-cols-5 text-sm">
              <p class="col-span-1" width="120px">
                {{ $t("integration.webhook_event.uuid") }}:
              </p>
              <p class="col-span-4">{{ webhookEventLog.uuid }}</p>
            </div>
            <div class="block sm:grid grid-cols-5 text-sm">
              <p class="col-span-1" width="120px">
                {{ $t("integration.webhook_event.url") }}:
              </p>
              <p class="col-span-4">{{ webhookEventLog.url }}</p>
            </div>
            <div class="block sm:grid grid-cols-5 text-sm">
              <p class="col-span-1" width="120px">
                {{ $t("integration.webhook_event.created_at") }}:
              </p>
              <p class="col-span-4">
                {{
                  $moment(webhookEventLog.created_at).format(
                    "DD MMM YYYY hh:mm a"
                  )
                }}
              </p>
            </div>
            <div class="block sm:grid grid-cols-5 text-sm">
              <p class="col-span-1" width="120px">
                {{ $t("integration.webhook_event.updated_at") }}:
              </p>
              <p class="col-span-4">
                {{
                  $moment(webhookEventLog.updated_at).format(
                    "DD MMM YYYY hh:mm a"
                  )
                }}
              </p>
            </div>
          </div>

          <!-- Webhook Payload  -->
          <div
            class="space-y-3"
            v-if="!$lodash.isEmpty(webhookEventLog.payload)"
          >
            <hr />
            <app-expand :expanded="true">
              <template v-slot:head="slot">
                <div class="flex justify-between">
                  <div class="flex space-x-2 cursor-pointer">
                    <p class="font-medium">
                      {{ $t("integration.webhook_event.payload") }}
                    </p>
                    <app-icon
                      name="ChevronRightIcon"
                      :class="[
                        'h-5 w-5 text-gray-500 transform',
                        slot.expanded ? '-rotate-90' : 'rotate-90',
                      ]"
                    />
                  </div>
                  <app-button-copy
                    v-if="slot.expanded"
                    @click.stop
                    :textToCopy="JSON.stringify(webhookEventLog.payload)"
                    :title="$t('general.copy')"
                  />
                </div>
              </template>
              <template v-slot:body>
                <div
                  class="block whitespace-pre overflow-x-scroll bg-gray-900 text-yellow-200 text-xs p-2 rounded-md my-3"
                >
                  <code v-text="webhookEventLog.payload" />
                </div>
              </template>
            </app-expand>
          </div>

          <!-- Webhook Exception  -->
          <div
            class="space-y-3"
            v-if="!$lodash.isEmpty(webhookEventLog.exception)"
          >
            <hr />
            <app-expand :expanded="true">
              <template v-slot:head="slot">
                <div class="flex justify-between">
                  <div class="flex space-x-2 cursor-pointer">
                    <p class="font-medium">
                      {{ $t("integration.webhook_event.exception") }}
                    </p>
                    <app-icon
                      name="ChevronRightIcon"
                      :class="[
                        'h-5 w-5 text-gray-500 transform',
                        slot.expanded ? '-rotate-90' : 'rotate-90',
                      ]"
                    />
                  </div>
                  <app-button-copy
                    v-if="slot.expanded"
                    @click.stop
                    :textToCopy="JSON.stringify(webhookEventLog.exception)"
                    :title="$t('general.copy')"
                  />
                </div>
              </template>
              <template v-slot:body>
                <div
                  class="block whitespace-pre overflow-x-scroll bg-red-900 text-white text-xs p-2 rounded-md my-3"
                >
                  <code v-text="webhookEventLog.exception" />
                </div>
              </template>
            </app-expand>
          </div>

          <hr />

          <!-- Retries  -->
          <div class="space-y-3">
            <app-alert-error
              v-if="errors?.webhook_event_log"
              :message="errors?.webhook_event_log[0]"
              class="mb-3 p-2 border rounded-md border-decline"
            />
            <app-expand :expanded="true">
              <template v-slot:head="slot">
                <div class="flex justify-between pb-3">
                  <div class="flex space-x-2 cursor-pointer">
                    <p class="font-medium">
                      {{ $t("integration.webhook_event.retries") }}
                    </p>
                    <app-icon
                      v-if="!$lodash.isEmpty(webhookEventLog.retries)"
                      name="ChevronRightIcon"
                      :class="[
                        'h-5 w-5 text-gray-500 transform',
                        slot.expanded ? '-rotate-90' : 'rotate-90',
                      ]"
                    />
                  </div>
                  <button @click.stop="webhookRetry">
                    <div
                      class="px-2 py-1 border rounded-md flex flex-row justify-center bg-background place-items-center hover:bg-gray-100 space-x-1"
                    >
                      <app-icon-outline
                        name="RefreshIcon"
                        class="h-4 w-4 text-gray-700"
                      />
                      <p class="text-xs">{{ $t("general.retry") }}</p>
                    </div>
                  </button>
                </div>
              </template>
              <template v-slot:body>
                <div
                  v-for="(retry, index) in webhookEventLog.retries"
                  :key="index"
                  class="space-y-5"
                >
                  <div class="flex flex-col mt-2 border border-dotted py-3">
                    <div class="p-2 text-gray-700 text-sm space-y-2">
                      <div class="block sm:grid grid-cols-5 text-sm">
                        <p class="col-span-1" width="120px">
                          {{ $t("integration.webhook_event.status") }}:
                        </p>
                        <p class="col-span-4 mb-1">
                          <app-badge
                            :status="
                              retry.status == 'succeed' ? 'success' : 'light'
                            "
                            class="w-16 text-xs"
                          >
                            {{ retry.status }}
                          </app-badge>
                        </p>
                      </div>
                      <div class="block sm:grid grid-cols-5 text-sm">
                        <p class="col-span-1" width="120px">
                          {{ $t("integration.webhook_event.uuid") }}:
                        </p>
                        <p class="col-span-4">{{ retry.uuid }}</p>
                      </div>
                      <div class="block sm:grid grid-cols-5 text-sm">
                        <p class="col-span-1" width="120px">
                          {{ $t("integration.webhook_event.url") }}:
                        </p>
                        <p class="col-span-4">{{ retry.url }}</p>
                      </div>
                      <div class="block sm:grid grid-cols-5 text-sm">
                        <p class="col-span-1" width="120px">
                          {{ $t("integration.webhook_event.created_at") }}:
                        </p>
                        <p class="col-span-4">
                          {{
                            $moment(retry.created_at).format(
                              "DD MMM YYYY hh:mm a"
                            )
                          }}
                        </p>
                      </div>
                      <app-expand v-if="!$lodash.isEmpty(retry.payload)">
                        <template v-slot:head="slot">
                          <div class="flex justify-between">
                            <div class="flex space-x-2 cursor-pointer">
                              <p>
                                {{ $t("integration.webhook_event.payload") }}
                              </p>
                              <app-icon
                                name="ChevronRightIcon"
                                :class="[
                                  'h-5 w-5 text-gray-500 transform',
                                  slot.expanded ? '-rotate-90' : 'rotate-90',
                                ]"
                              />
                            </div>
                            <app-button-copy
                              v-if="slot.expanded"
                              @click.stop
                              :textToCopy="
                                JSON.stringify(webhookEventLog.payload)
                              "
                              :title="$t('general.copy')"
                            />
                          </div>
                        </template>
                        <template v-slot:body>
                          <div
                            class="block whitespace-pre overflow-x-scroll bg-gray-900 text-yellow-200 text-xs p-2 rounded-md my-3"
                          >
                            <code v-text="retry.payload" />
                          </div>
                        </template>
                      </app-expand>

                      <!-- Exception -->
                      <app-expand v-if="!$lodash.isEmpty(retry.exception)">
                        <template v-slot:head="slot">
                          <div class="flex justify-between">
                            <div class="flex space-x-2 cursor-pointer">
                              <p>Exception</p>
                              <app-icon
                                name="ChevronRightIcon"
                                :class="[
                                  'h-5 w-5 text-gray-500 transform',
                                  slot.expanded ? '-rotate-90' : 'rotate-90',
                                ]"
                              />
                            </div>
                            <app-button-copy
                              v-if="slot.expanded"
                              @click.stop
                              :textToCopy="JSON.stringify(retry.exception)"
                              :title="$t('general.copy')"
                            />
                          </div>
                        </template>
                        <template v-slot:body>
                          <div
                            class="block whitespace-pre overflow-x-scroll bg-red-900 text-white text-xs p-2 rounded-md my-3"
                          >
                            <code v-text="retry.exception" />
                          </div>
                        </template>
                      </app-expand>
                    </div>
                  </div>
                </div>
              </template>
            </app-expand>
          </div>
        </div>
      </div>
    </template>
  </app-full-modal>
</template>

<script>
export default {
  data() {
    return {
      expanded: true,
    };
  },
  props: {
    webhookEventLog: {
      type: Object,
      default: null,
    },
    businessId: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    loading() {
      return this.$store.getters["integrationStore/loading"];
    },
    errors() {
      return this.$store.getters["integrationStore/errors"];
    },
  },
  watch: {
    webhookEventLog(newVal, oldVal) {
      this.resetState();
    },
  },
  methods: {
    onClick(value) {
      this.$emit("onClick", value);
    },

    async webhookRetry() {
      const result = await this.$store.dispatch(
        "integrationStore/webhookRetry",
        {
          integration_id: this.webhookEventLog.webhook_event.integration_id,
          webhook_event_id: this.webhookEventLog.webhook_event_id,
          webhook_event_log_id: this.webhookEventLog._id,
          business_id: this.businessId,
        }
      );

      if (this.$lodash.isEmpty(result.errors)) {
        this.$notify(
          {
            group: "success",
            title: this.$t("message.resend_successfully"),
          },
          2000
        );
      }
    },

    resetState() {
      this.$store.commit("integrationStore/setError", []);
    },
  },
};
</script>

import baseStore from "../base-store";
import AuthRepository from "../../data/repository/auth-repository";
import WalletRepository from "../../data/repository/wallet-repository";
import Role from "@/utils/const/role";
import { logEvent } from "firebase/analytics";
import currency from "@/utils/currency";

const checkRoleMasterBusiness = [
  Role.MASTER_BUSINESS_OWNER,
  Role.MASTER_BUSINESS_ADMIN,
  Role.MASTER_BUSINESS_MANAGER,
  Role.MASTER_BUSINESS_DEVELOP,
  Role.MASTER_BUSINESS_SUPPORT,
]

export default {
  namespaced: true,
  state: {
    ...baseStore.state,
    currency: 'MYR',
    walletBalance: {
      currency: 'MYR',
      balance: 0.0,
      formatted_amount: 0.0,
    },
    accountBalance: {
      currency: 'MYR',
      balance: 0.0,
      formatted_amount: 0.0,
    },
    balanceOverviewLoading: false,
    balanceOverviewMessage: null,
    balanceOverview: {
      currency: 'MYR',
      total_payout_requested: 0.0,
      total_payment_paid: 0.0,
      total_payment_paid_and_processed: 0.0,
    },
    list_payout: [],
    payout: {
      _id: null,
      status: "requested",
      reference_id: null,
      reason: null
    }
  },

  getters: {
    ...baseStore.getters,
    walletBalance(state) {
      return state.walletBalance;
    },
    accountBalance(state) {
      return state.accountBalance;
    },
    list_payout(state) {
      return state.list_payout;
    },
    payout(state) {
      return state.payout;
    },
    currency(state) {
      return state.currency;
    },
    balanceOverview(state) {
      return state.balanceOverview;
    },
    balanceOverviewLoading(state) {
      return state.balanceOverviewLoading;
    },
    balanceOverviewMessage(state) {
      return state.balanceOverviewMessage;
    },
  },

  mutations: {
    ...baseStore.mutations,
    setWalletBalance(state, walletBalance) {
      state.walletBalance = walletBalance;
    },
    setAccountBalance(state, accountBalance) {
      state.accountBalance = accountBalance;
    },
    setListPayout(state, list_payout) {
      state.list_payout = list_payout;
    },
    setPayout(state, payout) {
      state.payout = payout;
    },
    setBalanceOverview(state, balanceOverview) {
      state.balanceOverview = balanceOverview;
    },
    setBalanceOverviewLoading(state, balanceOverviewLoading) {
      state.balanceOverviewLoading = balanceOverviewLoading;
    },
    setBalanceOverviewErrorMessage(state, errorResponse = { message: null, errors: [] }) {
      state.balanceOverviewMessage = errorResponse.message;
      state.errors = errorResponse.errors;
      state.loading = false;
    },
  },

  actions: {
    async retrieveWalletTransactions(state, queryParams) {
      const walletRepository = new WalletRepository();
      const authRepository = new AuthRepository();

      const business_id = authRepository.getSelectedRole()?.business_id;

      if (business_id == null) {
        return;
      }

      state.commit("setApiPaginationLoading", true);

      let walletIndexResult = null;

      if (checkRoleMasterBusiness.includes(authRepository.getSelectedRole()?.role_id)) {
        walletIndexResult = await walletRepository.indexMasterBusiness(
          business_id,
          queryParams
        );
      } else {
        walletIndexResult = await walletRepository.index(
          business_id,
          queryParams
        );
      }

      if (walletIndexResult.message != undefined) {
        logEvent(this.$analytics, "wallet-index", { result: "failed" });
        state.commit("setErrorMessage", walletIndexResult);
        return;
      } else {
        logEvent(this.$analytics, "wallet-index", { result: "success" });
      }

      state.commit("setApiPaginationLoading", false);
      state.commit("setApiPaginationResponse", walletIndexResult);
      return walletIndexResult;
    },

    async retrieveSettlementIndex(state, data) {
      const authRepository = new AuthRepository();
      const walletRepository = new WalletRepository();

      if (data.business_id == null || data.settlement_id == null) {
        return;
      }

      state.commit("setApiPaginationLoading", true);

      let settlementIndex = await walletRepository.settlementIndex(
        data.business_id,
        data.settlement_id,
        data.queryParams
      );

      if (settlementIndex.message != undefined) {
        logEvent(this.$analytics, "settlement-index", { result: "failed" });
        state.commit("setErrorMessage", settlementIndex);
      } else {
        logEvent(this.$analytics, "settlement-index", { result: "success" });
        state.commit("setApiPaginationResponse", settlementIndex);
      }

      state.commit("setApiPaginationLoading", false);

      return settlementIndex;
    },

    async retrieveMasterBusinessSettlementIndex(state, data) {
      const walletRepository = new WalletRepository();

      if (data.business_id == null || data.master_business_settlement_id == null) {
        return;
      }

      state.commit("setApiPaginationLoading", true);

      let settlementIndex = await walletRepository.masterBusinessSettlementIndex(
        data.business_id,
        data.master_business_settlement_id,
        data.queryParams
      );

      if (settlementIndex.message != undefined) {
        logEvent(this.$analytics, "master-business-settlement-index", { result: "failed" });
        state.commit("setErrorMessage", settlementIndex);
      } else {
        logEvent(this.$analytics, "master-business-settlement-index", { result: "success" });
        state.commit("setApiPaginationResponse", settlementIndex);
      }

      state.commit("setApiPaginationLoading", false);

      return settlementIndex;
    },

    async getWalletBalance(state, businessId) {
      const walletRepository = new WalletRepository();
      const authRepository = new AuthRepository();

      const business_id = businessId ?? authRepository.getSelectedRole()?.business_id;

      if (business_id == null) {
        return;
      }

      state.commit("setLoading", true);

      const walletBalanceBalanceResult = await walletRepository.walletBalance(business_id);

      if (walletBalanceBalanceResult.message != undefined) {
        logEvent(this.$analytics, "wallet-balance", { result: "failed" });
        state.commit("setErrorMessage", walletBalanceBalanceResult);
        return;
      } else {
        logEvent(this.$analytics, "wallet-balance", { result: "success" });
      }

      state.commit("setLoading", false);
      state.commit("setWalletBalance", walletBalanceBalanceResult.data);
      return walletBalanceBalanceResult;
    },

    async getAccountBalance(state, businessId) {
      const walletRepository = new WalletRepository();
      const authRepository = new AuthRepository();

      const business_id = businessId ?? authRepository.getSelectedRole()?.business_id;

      if (business_id == null) {
        return;
      }
      
      state.commit("setLoading", true);

      const accountBalanceResult = await walletRepository.accountBalance(
          business_id,
          state.getters.currency
        );

        

      if (accountBalanceResult.message != undefined) {
        logEvent(this.$analytics, "account-balance", { result: "failed" });
        state.commit("setErrorMessage", accountBalanceResult);
        return;
      } else {
        logEvent(this.$analytics, "account-balance", { result: "success" });
      }

      state.commit("setLoading", false);
      state.commit("setAccountBalance", accountBalanceResult.data);

      return accountBalanceResult;
    },

    async downloadSettlementCSV(state, data) {
      const walletRepository = new WalletRepository();

      if (data.business_id == null || data.settlement_id == null) {
        return;
      }

      state.commit("setLoading", true);

      const downloadSettlementCSVResult = await walletRepository.downloadSettlementCSV(
        data.business_id,
        data.settlement_id,
        data.filters
      );

      if (downloadSettlementCSVResult.message != undefined) {
        logEvent(this.$analytics, "download-settlement-csv", { result: "failed" });
        state.commit("setErrorMessage", downloadSettlementCSVResult);
      } else {
        logEvent(this.$analytics, "download-settlement-csv", { result: "success" });
      }

      state.commit("setLoading", false);

      return downloadSettlementCSVResult;
    },

    async downloadMasterBusinessSettlementCSV(state, data) {
      const walletRepository = new WalletRepository();

      if (data.business_id == null || data.master_business_settlement_id == null) {
        return;
      }

      state.commit("setLoading", true);

      const downloadSettlementCSVResult = await walletRepository.downloadMasterBusinessSettlementCSV(
        data.business_id,
        data.master_business_settlement_id,
        data.queryParams
      );

      if (downloadSettlementCSVResult.message != undefined) {
        logEvent(this.$analytics, "download-master-business-settlement-csv", { result: "failed" });
        state.commit("setErrorMessage", downloadSettlementCSVResult);
      } else {
        logEvent(this.$analytics, "download-master-business-settlement-csv", { result: "success" });
      }

      state.commit("setLoading", false);

      return downloadSettlementCSVResult;
    },

    async retrieveWalletPayouts(state, queryParams) {
      const walletRepository = new WalletRepository();
      const authRepository = new AuthRepository();

      const business_id = authRepository.getSelectedRole()?.business_id;

      if (business_id == null) {
        return;
      }

      state.commit("setApiPaginationLoading", true);

      let payoutIndexResult = (checkRoleMasterBusiness.includes(authRepository.getSelectedRole()?.role_id)) ?
        await walletRepository.payoutIndexMasterBusiness(
          business_id,
          queryParams
        ) :
        await walletRepository.payoutIndex(
          business_id,
          queryParams
        );

      if (payoutIndexResult.message != undefined) {
        logEvent(this.$analytics, "payout-index", { result: "failed" });
        state.commit("setErrorMessage", payoutIndexResult);
        return;
      } else {
        logEvent(this.$analytics, "payout-index", { result: "success" });
      }

      state.commit("setApiPaginationLoading", false);
      state.commit("setApiPaginationResponse", payoutIndexResult);
      return payoutIndexResult;
    },

    async createPayoutRequest(state, data) {
      const walletRepository = new WalletRepository();
      const authRepository = new AuthRepository();

      const business_id = data.business_id ?? authRepository.getSelectedRole()?.business_id;

      if (business_id == null) {
        return;
      }

      state.commit("setLoading", true);

      data.amount = currency.toCents(data.currency, data.amount);

      const payoutRequestResult = await walletRepository.payoutRequest(
        business_id,
        data
      );

      if (payoutRequestResult.message != undefined) {
        logEvent(this.$analytics, "payout-request", { result: "failed" });
        state.commit("setErrorMessage", payoutRequestResult);
        return;
      } else {
        logEvent(this.$analytics, "payout-request", { result: "success" });
      }

      state.commit("setLoading", false);
      return payoutRequestResult;
    },
    async getListPayouts(state, queryParams = "") {
      state.commit("setLoading", true);
      const walletRepository = new WalletRepository();

      const walletPayoutResult = await walletRepository.getListPayouts(queryParams);

      if (walletPayoutResult.message != null) {
        logEvent(this.$analytics, "get-list-payouts", { result: "failed" });
        state.commit("setErrorMessage", walletPayoutResult);
      } else {
        logEvent(this.$analytics, "get-list-payouts", { result: "success" });
        state.commit("setListPayout", walletPayoutResult.data);
      }

      state.commit("setLoading", false);

      return walletPayoutResult;
    },

    async getListPayoutsExports(state, queryParams = "") {
      state.commit("setLoading", true);
      const walletRepository = new WalletRepository();

      const walletPayoutResult = await walletRepository.getListPayoutsExports(queryParams);

      if (walletPayoutResult.message != null) {
        logEvent(this.$analytics, "get-list-payouts-exports", { result: "failed" });
        state.commit("setErrorMessage", walletPayoutResult);
      } else {
        logEvent(this.$analytics, "get-list-payouts-exports", { result: "success" });
        state.commit("setListPayout", walletPayoutResult.data);
      }

      state.commit("setLoading", false);

      return walletPayoutResult;
    },

    async downloadListPayouts(state, params = {}) {
      state.commit("setLoading", true);
      const walletRepository = new WalletRepository();

      const walletPayoutResult = await walletRepository.downloadListPayouts(params);

      if (walletPayoutResult.message != null) {
        logEvent(this.$analytics, "download-list-payouts", { result: "failed" });
        state.commit("setErrorMessage", walletPayoutResult);
      } else {
        logEvent(this.$analytics, "download-list-payouts", { result: "success" });
        state.commit("setListPayout", walletPayoutResult.data);
      }

      state.commit("setLoading", false);

      return walletPayoutResult;
    },

    async updatePayoutStatus(state, payout = {}) {
      state.commit("setLoading", true);
      const walletRepository = new WalletRepository();

      const walletPayoutResult = await walletRepository.updatePayoutStatus(state.getters.payout._id, payout.status, payout.reference_id, payout.reason);

      if (walletPayoutResult.message != null) {
        logEvent(this.$analytics, "update-status-payout", { result: "failed" });
        state.commit("setErrorMessage", walletPayoutResult);
      } else {
        logEvent(this.$analytics, "update-status-payout", { result: "success" });
        state.commit("setPayout", walletPayoutResult.data);
      }

      state.commit("setLoading", false);

      return walletPayoutResult;
    },

    async updatePayoutStatusWithDocuments(state, payload = {}) {
      state.commit("setLoading", true);
      const walletRepository = new WalletRepository();

      const walletPayoutResult = await walletRepository.updatePayoutStatusWithDocuments(state.getters.payout._id, payload);

      if (walletPayoutResult.message != null) {
        logEvent(this.$analytics, "update-status-payout-with-documents", { result: "failed" });
        state.commit("setErrorMessage", walletPayoutResult);
      } else {
        logEvent(this.$analytics, "update-status-payout-with-documents", { result: "success" });
        state.commit("setPayout", walletPayoutResult.data);
      }

      state.commit("setLoading", false);

      return walletPayoutResult;
    },

    async updatePayoutResolve(state, payload = {}) {
      state.commit("setLoading", true);
      const walletRepository = new WalletRepository();
      const authRepository = new AuthRepository();

      let business_id = authRepository.getSelectedRole()?.business_id;

      if (business_id == null) {
        return;
      }

      const walletPayoutResult = await walletRepository.updatePayoutResolve(
        business_id,
        payload.payout_id,
      );

      if (walletPayoutResult.message != null) {
        logEvent(this.$analytics, "update-status-resolve", { result: "failed" });
        state.commit("setErrorMessage", walletPayoutResult);
      } else {
        logEvent(this.$analytics, "update-status-resolve", { result: "success" });
        state.commit("setPayout", walletPayoutResult.data);
      }

      state.commit("setLoading", false);

      return walletPayoutResult;
    },

    async bulkUpdatePayoutStatus(state, payout_ids) {
      state.commit("setLoading", true);
      const walletRepository = new WalletRepository();

      const walletPayoutResult = await walletRepository.bulkUpdatePayoutStatus(payout_ids);

      if (walletPayoutResult.message != null) {
        logEvent(this.$analytics, "bulk-update-status-payout", { results: "failed" });
        state.commit("setErrorMessage", walletPayoutResult);
      } else {
        logEvent(this.$analytics, "bulk-update-status-payout", { result: "success" });
      }

      state.commit("setLoading", false);

      return walletPayoutResult;
    },

    async getPayoutForSupport(state, queryParams) {
      state.commit("setApiPaginationLoading", true);
      const walletRepository = new WalletRepository();
      const payoutIndexSupportResult = await walletRepository.getPayoutForSupport(queryParams);

      if (payoutIndexSupportResult.message != undefined) {
        logEvent(this.$analytics, "payout-index-for-support", { result: "failed" });
        state.commit("setErrorMessage", payoutIndexSupportResult);
        return;
      } else {
        logEvent(this.$analytics, "payout-index-for-support", { result: "success" });
      }

      state.commit("setApiPaginationLoading", false);
      state.commit("setApiPaginationResponse", payoutIndexSupportResult);
      return payoutIndexSupportResult;
    },

    async getTransactionForSupport(state, queryParams = "") {
      state.commit("setApiPaginationLoading", true);
      const walletRepository = new WalletRepository();
      const transactionIndexSupportResult = await walletRepository.getTransactionForSupport(queryParams);

      if (transactionIndexSupportResult.message != undefined) {
        logEvent(this.$analytics, "transaction-index-for-support", { result: "failed" });
        state.commit("setErrorMessage", transactionIndexSupportResult);
        return;
      } else {
        logEvent(this.$analytics, "transaction-index-for-support", { result: "success" });
      }

      state.commit("setApiPaginationLoading", false);
      state.commit("setApiPaginationResponse", transactionIndexSupportResult);
      return transactionIndexSupportResult;
    },

    async updateRoundOffAmountPayout(state, payout_id) {
      state.commit("setLoading", true);
      const walletRepository = new WalletRepository();
      const walletPayoutResult = await walletRepository.updateRoundOffAmountPayout(payout_id);

      if (walletPayoutResult.message != null) {
        logEvent(this.$analytics, "update-round-off-amount-payout", { result: "failed" });
        state.commit("setErrorMessage", walletPayoutResult);
      } else {
        logEvent(this.$analytics, "update-round-off-amount-payout", { result: "success" });
      }
      state.commit("setLoading", false);
      return walletPayoutResult;
    },

    async cancelRoundOffAmountPayout(state, payout_id) {
      state.commit("setLoading", true);
      const walletRepository = new WalletRepository();
      const walletPayoutResult = await walletRepository.cancelRoundOffAmountPayout(payout_id);

      if (walletPayoutResult.message != null) {
        logEvent(this.$analytics, "cancel-round-off-amount-payout", { result: "failed" });
        state.commit("setErrorMessage", walletPayoutResult);
      } else {
        logEvent(this.$analytics, "cancel-round-off-amount-payout", { result: "success" });
      }
      state.commit("setLoading", false);
      return walletPayoutResult;
    },

    async retrieveBalanceOverview(state, isMasterBusinessRole = false) {
      const walletRepository = new WalletRepository();
      const authRepository = new AuthRepository();

      const business_id = authRepository.getSelectedRole()?.business_id;

      if (business_id == null) {
        return;
      }

      state.commit("setBalanceOverviewLoading", true);

      const balanceOverviewResult = (!isMasterBusinessRole) ? await walletRepository.balanceOverview(
        business_id,
        state.getters.currency,
      ) :
        await walletRepository.masterBusinessBalanceOverview(
          business_id,
          state.getters.currency,
        );

      if (balanceOverviewResult.message != undefined) {
        logEvent(this.$analytics, "balance-overview", { result: "failed" });
        state.commit("setBalanceOverviewErrorMessage", balanceOverviewResult);
        return;
      } else {
        logEvent(this.$analytics, "balance-overview", { result: "success" });
      }

      state.commit("setBalanceOverviewLoading", false);
      state.commit("setBalanceOverview", balanceOverviewResult.data);
      return balanceOverviewResult;
    },

    async getSupportPayoutExports(state, queryParams = "") {
      state.commit("setLoading", true);
      const walletRepository = new WalletRepository();

      const walletPayoutResult = await walletRepository.getSupportPayoutExports(queryParams);

      if (walletPayoutResult.message != null) {
        logEvent(this.$analytics, "get-support-payouts-exports", { result: "failed" });
        state.commit("setErrorMessage", walletPayoutResult);
      } else {
        logEvent(this.$analytics, "get-support-payouts-exports", { result: "success" });
        state.commit("setListPayout", walletPayoutResult.data);
      }

      state.commit("setLoading", false);

      return walletPayoutResult;
    },

    async getSupportTransactionExports(state, queryParams = "") {
      state.commit("setLoading", true);
      const walletRepository = new WalletRepository();

      const walletTransactionResult = await walletRepository.getSupportTransactionExports(queryParams);

      if (walletTransactionResult.message != null) {
        logEvent(this.$analytics, "get-support-transaction-exports", { result: "failed" });
        state.commit("setErrorMessage", walletTransactionResult);
      } else {
        logEvent(this.$analytics, "get-support-transaction-exports", { result: "success" });
        state.commit("setListPayout", walletTransactionResult.data);
      }

      state.commit("setLoading", false);

      return walletTransactionResult;
    },

  },
};

import client from "@/data/base-client";
import WithAuthRepository from "./with-auth-repository";

export default class BusinessDetailRepository extends WithAuthRepository {
  constructor() {
    super();
  }

  async createOrUpdate(detail) {
    detail.industry_id = (detail.industry != null) ? detail.industry.id : 0;
    detail.company_annual_turnover_id = (detail.company_annual_turnover != null) ? detail.company_annual_turnover.id : 0;
    detail.state_id = (detail.state != null) ? detail.state.id : 0;
    detail.phone_no = detail.phone_no?.replace("+", "");

    const response = (detail.id == null) ?
      await client.post("/businesses/" + detail.business_id + "/details", detail, this.getToken()) :
      await client.put("/businesses/" + detail.business_id + "/details/" + detail.id, detail, this.getToken());

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async createOrUpdateMasterBusiness(masterBusinessId, detail) {
    detail.industry_id = (detail.industry != null) ? detail.industry.id : 0;
    detail.state_id = (detail.state != null) ? detail.state.id : 0;
    detail.company_annual_turnover_id = (detail.company_annual_turnover != null) ? detail.company_annual_turnover.id : 0;
    detail.phone_no = detail.phone_no?.replace("+", "");

    const response = (detail.id == null) ?
      await client.post("/master-businesses/" + masterBusinessId + "/businesses/" + detail.business_id +"/details", detail, this.getToken()) :
      await client.put("/master-businesses/" + masterBusinessId + "/businesses/" + detail.business_id + "/details/" + detail.id, detail, this.getToken());

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }
}
<template>
  <div class="flex flex-col" :class="$attrs.class">
    <label
      v-if="showlabel"
      :for="name"
      class="block text-sm font-light text-gray-700"
    >
      {{ labelText }}
    </label>
    <div class="relative mt-2">
      <input
        :class="[
          'w-full h-10 rounded-md shadow-sm text-sm border-1 focus:ring-primary border-none ring-1 focus:ring-2 focus:outline-none',
          errorMessage.length > 0 ? 'ring-error' : 'ring-bordercolor',
          { 'bg-gray-100': disabled },
          $attrs.inputclass,
        ]"
        id="phone"
        :name="name"
        type="tel"
        :autocomplete="autocomplete"
        :disabled="disabled || loading"
        :required="required"
        :placeholder="placeholder"
        :value="modelValue"
        @input="onInputChanges"
      />
      <span v-if="errorMessage.length > 0" class="text-xs text-error">{{
        errorMessage[0]
      }}</span>
      <app-icon-select class="absolute top-2.5 left-9 ml-0.5" />
    </div>
  </div>
</template>
<script>
import intlTelInput from "intl-tel-input";
import "../../../../node_modules/intl-tel-input/build/js/utils.js";

export default {
  data() {
    return {
      intlTelInput: () => {},
    };
  },

  mounted() {
    const input = document.querySelector("#phone");
    if (input != null) {
      this.intlTelInput = intlTelInput(input, {
        initialCountry: "MY",
        utilsScript:
          "../../../../node_modules/intl-tel-input/build/js/utils.js",
        preferredCountries: ["MY"],
        customContainer: "text-sm",
        separateDialCode: false,
        nationalMode: true,
        responsiveDropDown: true,
        placeholderNumberType: "MOBILE"
      });
    }
  },

  props: {
    name: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    autocomplete: {
      type: String,
    },
    labelText: {
      type: String,
      default: "",
    },
    showlabel: {
      type: Boolean,
      default: true,
    },
    modelValue: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    onInputChanges() {
      this.$emit("update:modelValue", this.intlTelInput.getNumber());
    },
  },
};
</script>
<style scoped>
#phone {
  padding-left: 64px;
}
</style>
<template>
  <div
    v-show="
      showPendingPaymentComment && business.package.id != BUSINESS_PACKAGE.BASIC
    "
    class="flex flex-col bg-gray-100 h-full rounded-md px-5 py-3 mb-5"
  >
    <div class="flex justify-between items-center mb-2">
      <b>{{ $t("business.main.pending_payment_comments_reasons") }}</b>
    </div>
    <span class="text-gray-500">{{ business.pending_payment_comment }}</span>
  </div>
</template>

<script>
import BUSINESS_PACKAGE from "@/utils/const/business_package";

export default {
  data() {
    return {
      BUSINESS_PACKAGE: BUSINESS_PACKAGE,
      showPendingPaymentComment: true,
    };
  },
  computed: {
    business() {
      return this.$store.getters["businessStore/business"];
    },
  },
};
</script>

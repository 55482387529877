<template>
  <div
    class="
      flex
      w-full
      h-20
      border border-error
      rounded-md
      bg-red-50
      justify-between
      items-center
    "
    v-if="message"
  >
    <div class="flex items-center ml-10">
      <app-icon name="ExclamationIcon" class="h-6 w-6 text-error mr-6" />

      <span class="text-error">
        {{
          message === "$general.message"
            ? $t("general.unable_to_reach_server")
            : message
        }}
      </span>
    </div>
    <div class="pr-8">
      <button
        class="
          flex
          rounded-md
          shadow
          border
          bg-white
          hover:bg-gray-100
          text-base
          px-4
          py-2
          h-auto
          w-22
          items-center
        "
        @click="onClick()"
      >
        <PencilIcon class="pr-2" />
        {{ $t("general.edit") }}
      </button>
    </div>
  </div>
</template>

<script>
import { PencilIcon } from "@heroicons/vue/solid";
export default {
  components: {
    PencilIcon,
  },
  props: {
    message: {
      type: String,
    },
  },
  methods: {
    onClick(event) {
      this.$emit("onClick", event);
    },
  },
};
</script>
<template>
  <Transition name="fade">
    <div
      v-show="show"
      class="
        fixed
        z-40
        inset-0
        overflow-y-auto
        backdrop-filter backdrop-blur-sm
      "
    >
      <div
        class="flex min-h-screen justify-end bg-gray-400 bg-opacity-75"
        @click.self="closeOutside"
      >
        <Transition name="slide">
          <!-- modal box -->
          <div
            v-if="show"
            class="
              relative
              text-left
              shadow-xl
              bg-white
              sm:inline-block
              block
              overflow-y-auto
              transform
              transition-all
              h-screen
              w-screen
            "
            :class="[width, { 'overflow-hidden': overflow_hide }]"
          >
            <div class="relative">
              <!-- title -->
              <div class="flex justify-between p-4">
                <p class="text-md leading-6 font-medium text-gray-900">
                  {{ title }}
                </p>
                <app-icon
                  v-if="showClose"
                  name="XIcon"
                  class="w-5 cursor-pointer"
                  @click="close"
                />
              </div>

              <hr />

              <!-- body -->
              <div :class="paddingBody">
                <slot name="body" />
              </div>

              <div v-if="hasSlot('action')" class="p-4">
                <slot name="action" />
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </div>
  </Transition>
  <slot name="outside"/>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    show: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: "md:w-2/3 lg:w-1/2 xl:w-5/12",
    },
    overflow_hide: {
      type: Boolean,
      default: false,
    },
    backgroundClose: {
      type: Boolean,
      default: true,
    },
    padding: {
      type: String,
      default: "p-3",
    },
    paddingBody: {
      type: String,
      default: "p-3",
    },
    colorClose: {
      type: String,
      default: "text-gray-500 hover:text-gray-700",
    },
    showClose: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    show(value) {
      if (value) {
        return document.querySelector("body").classList.add("overflow-hidden");
      }
      return document.querySelector("body").classList.remove("overflow-hidden");
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    onClick(value) {
      this.$emit("onClick", value);
    },
    hasSlot(name = "default") {
      return !!this.$slots[name];
    },
    closeOutside() {
      if (this.backgroundClose) {
        this.close();
      }
    },
  },
};
</script>

<style scoped>
  .fade-enter-active {
    animation: fade 0.4s;
  }

  .fade-leave-active {
    animation: fade 0.2s reverse;
  }

  @keyframes fade {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  .slide-enter-active {
    animation: slide 0.2s;
  }

  .slide-leave-active {
    animation: slide 0.2s reverse;
  }

  @keyframes slide {
    0% {
      transform: translateX(5rem);
    }

    100% {
      transform: translateX(0);
    }
  }
  </style>

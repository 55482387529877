import AuthDao from "../dao/auth-dao";
import BaseRepository from "./base-repository";
export default class WithAuthRepository extends BaseRepository {
    constructor() {
        super();
        this.authDao = new AuthDao();
    }

    getToken() {
        return this.authDao.retrieveToken();
    }
}
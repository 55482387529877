<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.1344 0H4.55036C4.08864 0 3.71436 0.374286 3.71436 1.10057V31.4286C3.71436 31.6257 4.08864 32 4.55036 32H27.4498C27.9115 32 28.2858 31.6257 28.2858 31.4286V7.416C28.2858 7.01829 28.2326 6.89029 28.1389 6.796L21.4898 0.146857C21.3955 0.0531429 21.2675 0 21.1344 0Z"
      fill="#E9E9E0"
    />
    <path
      d="M21.4287 0.0859375V6.85679H28.1996L21.4287 0.0859375Z"
      fill="#D9D7CA"
    />
    <path
      d="M11.151 19.0421C10.9521 19.0421 10.7612 18.9776 10.5984 18.8558C10.0035 18.4096 9.92352 17.913 9.96124 17.5747C10.0652 16.6444 11.2155 15.6707 13.3812 14.6787C14.2407 12.7953 15.0584 10.4747 15.5458 8.53584C14.9755 7.29469 14.4212 5.68441 14.8252 4.73984C14.967 4.40898 15.1435 4.15527 15.4732 4.04555C15.6035 4.00212 15.9327 3.94727 16.0538 3.94727C16.3418 3.94727 16.595 4.31812 16.7744 4.54669C16.943 4.76155 17.3252 5.21698 16.5612 8.43355C17.3315 10.0244 18.423 11.645 19.4687 12.7547C20.2178 12.6193 20.8624 12.5501 21.3875 12.5501C22.2824 12.5501 22.8247 12.7587 23.0458 13.1884C23.2287 13.5438 23.1538 13.9593 22.823 14.4227C22.5047 14.8678 22.0658 15.1033 21.5544 15.1033C20.8595 15.1033 20.0504 14.6644 19.1481 13.7976C17.527 14.1364 15.6338 14.741 14.1035 15.4101C13.6258 16.4238 13.1681 17.2404 12.7418 17.8393C12.1561 18.6593 11.651 19.0421 11.151 19.0421ZM12.6721 16.113C11.451 16.7993 10.9532 17.3633 10.9172 17.681C10.9115 17.7336 10.8961 17.8718 11.1635 18.0764C11.2487 18.0496 11.7458 17.8227 12.6721 16.113ZM20.4647 13.5747C20.9304 13.933 21.0441 14.1141 21.3487 14.1141C21.4824 14.1141 21.8635 14.1084 22.0401 13.8621C22.1252 13.7427 22.1584 13.6661 22.1715 13.625C22.1012 13.5878 22.0081 13.5124 21.5001 13.5124C21.2115 13.513 20.8487 13.5256 20.4647 13.5747ZM16.1961 9.81355C15.7875 11.2273 15.2481 12.7536 14.6681 14.1358C15.8624 13.6724 17.1607 13.2678 18.3801 12.9816C17.6087 12.0856 16.8378 10.9667 16.1961 9.81355ZM15.8492 4.97812C15.7932 4.99698 15.0892 5.98212 15.9041 6.81584C16.4464 5.60727 15.8738 4.97012 15.8492 4.97812Z"
      fill="#CC4B4C"
    />
    <path
      d="M27.4498 32.0004H4.55036C4.08864 32.0004 3.71436 31.6261 3.71436 31.1644V22.2861H28.2858V31.1644C28.2858 31.6261 27.9115 32.0004 27.4498 32.0004Z"
      fill="#CC4B4C"
    />
    <path
      d="M9.9343 30.286H8.99658V24.5283H10.6526C10.8972 24.5283 11.1394 24.5672 11.3789 24.6455C11.6183 24.7237 11.8332 24.8409 12.0234 24.9969C12.2137 25.1529 12.3674 25.342 12.4846 25.5632C12.6017 25.7843 12.6606 26.0329 12.6606 26.3095C12.6606 26.6015 12.6109 26.8655 12.512 27.1026C12.4132 27.3397 12.2749 27.5392 12.0977 27.7003C11.9206 27.8615 11.7069 27.9866 11.4572 28.0752C11.2074 28.1637 10.9309 28.2077 10.6292 28.2077H9.93372V30.286H9.9343ZM9.9343 25.2392V27.5203H10.7937C10.908 27.5203 11.0212 27.5009 11.1337 27.4615C11.2457 27.4226 11.3486 27.3586 11.4423 27.27C11.536 27.1815 11.6114 27.058 11.6686 26.8992C11.7257 26.7403 11.7543 26.5437 11.7543 26.3095C11.7543 26.2157 11.7412 26.1072 11.7154 25.9855C11.6892 25.8632 11.636 25.746 11.5554 25.634C11.4743 25.522 11.3612 25.4283 11.2154 25.3529C11.0697 25.2775 10.8766 25.2397 10.6372 25.2397H9.9343V25.2392Z"
      fill="white"
    />
    <path
      d="M18.4108 27.2472C18.4108 27.7209 18.3599 28.126 18.2582 28.462C18.1565 28.798 18.0279 29.0792 17.8714 29.306C17.7148 29.5329 17.5393 29.7112 17.3439 29.8415C17.1485 29.9717 16.9599 30.0689 16.7776 30.1346C16.5954 30.1998 16.4285 30.2415 16.2776 30.2597C16.1268 30.2769 16.0148 30.286 15.9416 30.286H13.7622V24.5283H15.4965C15.9811 24.5283 16.4068 24.6055 16.7736 24.7586C17.1405 24.9117 17.4456 25.1169 17.6879 25.3717C17.9302 25.6266 18.1108 25.9175 18.2308 26.2426C18.3508 26.5683 18.4108 26.9032 18.4108 27.2472ZM15.6296 29.5986C16.2651 29.5986 16.7233 29.3957 17.0045 28.9895C17.2856 28.5832 17.4262 27.9946 17.4262 27.2237C17.4262 26.9843 17.3976 26.7472 17.3405 26.5129C17.2828 26.2786 17.1725 26.0666 17.0085 25.8763C16.8445 25.686 16.6216 25.5323 16.3405 25.4152C16.0594 25.298 15.6948 25.2392 15.2468 25.2392H14.6999V29.5986H15.6296Z"
      fill="white"
    />
    <path
      d="M20.7234 25.2392V27.0517H23.1297V27.6923H20.7234V30.286H19.7703V24.5283H23.3714V25.2392H20.7234Z"
      fill="white"
    />
  </svg>
</template>
<template>
  <div v-if="loading" class="fixed z-30 inset-0 overflow-y-auto">
    <div class="flex flex-col items-center justify-center h-full opacity-80 bg-white space-y-3">
      <app-icon-loading customClass="h-8 w-8 text-primary" />
      <p class="text-center">{{ text ?? $t("general.loading") }}</p>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: null,
    },
  },
};
</script>
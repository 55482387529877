<template>
  <app-modal class="rounded-md" :showClose="false" @close="closeFormPaymentProvider">
    <template v-slot:title>
      <div class="flex justify-between text-base">
        <img :src="
          business_payment_gateway_method?.payment_gateway_method_application
            ?.payment_gateway_method.payment_gateway?.image_url
        " alt="" class="w-24" />
        <span class="flex flex-row items-center space-x-2">
          <img :src="
            business_payment_gateway_method
              ?.payment_gateway_method_application?.payment_gateway_method
              .payment_method?.image_url
          " alt="" class="h-7" />
          <p class="text-xs">
            {{
                business_payment_gateway_method
                  ?.payment_gateway_method_application?.payment_gateway_method
                  ?.payment_method?.name
            }}
          </p>
        </span>
      </div>
      <div class="mt-5 flex flex-row items-center space-x-3">
        <p class="text-gray-400 text-sm">
          {{ $t("payment.settings.payment_provider.channel_used") }}
        </p>
        <div>
          <app-logo-senangpay class="mb-1" v-if="
            business_payment_gateway_method
              ?.payment_gateway_method_application?.payment_gateway_method
              ?.channel == PAYMENT_CHANNEL.SENANG_PAY
          " />
          <app-logo-m1pay class="h-3" v-else-if="
            business_payment_gateway_method
              ?.payment_gateway_method_application?.payment_gateway_method
              ?.channel == PAYMENT_CHANNEL.M1PAY
          " />
          <app-logo-gobiz class="w-20" v-else-if="
            business_payment_gateway_method
              ?.payment_gateway_method_application?.payment_gateway_method
              ?.channel == PAYMENT_CHANNEL.GO_BIZ
          " />
          <app-logo-stripe class="h-5" v-else-if="
            business_payment_gateway_method
              ?.payment_gateway_method_application?.payment_gateway_method
              ?.channel == PAYMENT_CHANNEL.STRIPE
          " />
          <app-logo-iserve class="h-5" v-else-if="
            business_payment_gateway_method
              ?.payment_gateway_method_application?.payment_gateway_method
              ?.channel == PAYMENT_CHANNEL.ISERVE
          " />
          <p class="text-sm" v-else>
            {{
                business_payment_gateway_method
                  ?.payment_gateway_method_application?.payment_gateway_method
                  ?.channel ?? ""
            }}
          </p>
        </div>
      </div>
    </template>

    <template v-slot:body>
      <div class="space-y-6">
        <app-form-input id="settlement_day" name="settlement_day" type="number"
          v-model="business_payment_gateway_method.settlement_day" :errorMessage="errors.settlement_day"
          :numberOnly="true" :maxlength="3" :disabled="true" :labelText="
            $t('payment.menu.settlement_day')
          " />

        <app-form-select :allowFilter="false" :datas="chargesType"
          v-model="business_payment_gateway_method.master_merchant_charges_type" v-on:retry="fetchChargesType"
          :errorMessage="errors.charges_type" :loading="loadingChargesType"
          :labelText="$t('payment.menu.charges_type')" />

        <app-form-input v-if="hasData() && !hasType(PAYMENT_CHARGE_TYPE.PERCENTAGE)" type="number" v-model="
          business_payment_gateway_method.formatted_master_merchant_fixed_charges
        " :errorMessage="errors.master_merchant_fixed_charges" :labelText="$t('payment.menu.fixed_charges')"
          :placeholder="$t('payment.create.amount_placeholder')" />

        <app-form-input v-if="hasData() && !hasType(PAYMENT_CHARGE_TYPE.FIXED)" type="number" v-model="
          business_payment_gateway_method.master_merchant_percentage_charges
        " :errorMessage="errors.master_merchant_percentage_charges" :labelText="$t('payment.menu.percentage_charges')"
          :placeholder="$t('payment.create.amount_placeholder')" />

        <app-form-input v-if="hasData() && !hasType(PAYMENT_CHARGE_TYPE.FIXED)" type="number" v-model="
          business_payment_gateway_method.formatted_master_merchant_percentage_min_charges
        " :errorMessage="errors.master_merchant_percentage_min_charges"
          :labelText="$t('payment.menu.fixed_min_charges')" :placeholder="$t('payment.create.amount_placeholder')" />
      </div>

      <app-button :showf70Icon="false" class="mt-4 pt-2 pb-2" @onClick="submitBusinessPaymentGatewayMethod">
        {{ $t("general.submit") }}
      </app-button>
    </template>
  </app-modal>
</template>

<script>
import PAYMENT_CHANNEL from "@/utils/const/payment_channel";
import PAYMENT_CHARGE_TYPE from "@/utils/const/payment_charge_type";
export default {
  data() {
    return {
      PAYMENT_CHANNEL: PAYMENT_CHANNEL,
      PAYMENT_CHARGE_TYPE: PAYMENT_CHARGE_TYPE,
      business_payment_gateway_method: {},
    };
  },

  props: ["businessPaymentGatewayMethod"],

  created() {
    this.fetchChargesType();
  },

  mounted() {
    this.$store.dispatch[
      ("paymentMethodStore/getPaymentGatewayApplications", this.businessId)
    ];
  },

  watch: {
    businessPaymentGatewayMethod(newValue, oldValue) {

      if (newValue.master_merchant_charges_type != null) {
        newValue.master_merchant_charges_type = {
          id: newValue.master_merchant_charges_type,
          name: this.$t('payment.menu.' + newValue.master_merchant_charges_type)
        }
      }

      this.business_payment_gateway_method = newValue;
    },
  },

  computed: {
    payment_gateway_applications() {
      return this.$store.getters[
        "paymentMethodStore/payment_gateway_applications"
      ];
    },
    errors() {
      return this.$store.getters["paymentStore/errors"];
    },
    businessPaymentGatewayMethods() {
      return this.$store.getters[
        "paymentStore/business_payment_gateway_method"
      ];
    },
    chargesType() {
      return this.$store.getters["paymentStore/chargesType"];
    },
    loadingChargesType() {
      return this.$store.getters["paymentStore/loadingChargesType"];
    },
    businessId() {
      return this.$route.query.business_id;
    },
  },
  methods: {
    fetchChargesType() {
      this.$store.dispatch("paymentStore/retrieveChargesType");
    },

    hasType(type) {
      return this.business_payment_gateway_method.master_merchant_charges_type?.id == type;
    },

    hasData() {
      return this.business_payment_gateway_method.master_merchant_charges_type?.id != null;
    },

    async submitBusinessPaymentGatewayMethod() {

      this.resetError();

      const businessPaymentProvider = await this.$store.dispatch(
        "paymentStore/updateChargesMasterMerchantPaymentGatewayMethod",
        {
          master_merchant_charges_type:
            this.business_payment_gateway_method?.master_merchant_charges_type
              ?.id,
          formatted_master_merchant_fixed_charges:
            this.business_payment_gateway_method?.formatted_master_merchant_fixed_charges,
          master_merchant_percentage_charges:
            this.business_payment_gateway_method
              ?.master_merchant_percentage_charges,
          formatted_master_merchant_percentage_min_charges:
            this.business_payment_gateway_method
              ?.formatted_master_merchant_percentage_min_charges,
          _id: this.businessPaymentGatewayMethod._id ?? null,
          business_id: this.businessId,
        }
      );

      if (
        businessPaymentProvider.data &&
        !this.$lodash.isEmpty(businessPaymentProvider.data)
      ) {
        this.resetError()
        this.close();

        this.refresh();

        this.$notify(
          {
            group: "success",
            title: "Saved!",
          },
          3000
        );
      }
    },

    close() {
      this.$emit("close");

    },

    refresh() {
      this.$emit("refresh");
    },
    resetError() {
      this.$store.commit('paymentStore/setErrorMessage')
    },
    closeFormPaymentProvider() {
      this.resetError()
      this.refresh()
    }
  },
};
</script>

import { createRouter, createWebHistory } from 'vue-router'
import NProgress from 'nprogress/nprogress'
import routes from '@/router'
import VueRouteMiddleware from 'vue-route-middleware';

const router = createRouter({
  routes,
  mode: 'history',
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior(routeTo, routeFrom, savedPosition) {
    return savedPosition ? savedPosition : { x: 0, y: 0 }
  },
});

router.beforeEach(VueRouteMiddleware());

router.beforeResolve(async (routeTo, routeFrom, next) => {

  document.title = `${process.env.VUE_APP_NAME}` + ((routeTo.meta !== undefined && routeTo.meta.title !== undefined) ? " - " + `${routeTo.meta.title}` : "");

  if (routeFrom.name !== null) {
    NProgress.start()
  }

  try {
    for (const route of routeTo.matched) {
      await new Promise((resolve, reject) => {
        if (route.meta && route.meta.beforeResolve) {
          route.meta.beforeResolve(routeTo, routeFrom, (...args) => {

            if (args.length) {

              if (routeFrom.name === args[0].name) {
                NProgress.done()
              }
              next(...args)

              reject(new Error('Redirected'))

            } else {
              resolve()
            }
          })
        } else {
          resolve()
        }
      })
    }

  } catch (error) {
    return
  }

  next()

})

router.afterEach((routeTo, routeFrom) => {
  NProgress.done()
})

export default router;
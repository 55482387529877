<template>
  <app-form-select
    :id="id"
    :name="name"
    :datas="banks"
    :labelText="labelText ?? $t('general.bank')"
    :errorMessage="errorMessage"
    :serverMessage="serverMessage"
    :loading="loading"
    :showLabel="showLabel"
    :allowFilter="allowFilter"
    v-model="checkedValue"
    v-on:retry="getBanks"
    v-on:valueSelected="onValueSelected"
  />
</template>

<script>
export default {
  props: {
    labelText: {
      type: String,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    showIcon: {
      type: Boolean,
      default: false,
    },
    allowFilter: {
      type: Boolean,
      default: true,
    },
    errorMessage: {
      type: Array,
      default: () => [],
    },
    modelValue: {
      type: Object,
      default: () => {},
    },
    country_id: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    country_id: function (newVal, oldValue) {
      this.getBanks(newVal);
    },
  },
  computed: {
    checkedValue: {
      get() {
        return this.modelValue ?? [];
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },

    banks() {
      return this.$store.getters["referenceStore/banks"];
    },

    loading() {
      return this.$store.getters["referenceStore/loading"];
    },

    serverMessage() {
      return this.$store.getters["referenceStore/message"] ?? "";
    },
  },

  async mounted() {
    this.getBanks();
  },

  methods: {
    async getBanks() {
      this.$store.commit("referenceStore/resetState");
      this.$store.dispatch("referenceStore/getBanks", this.country_id);
    },

    onValueSelected(value) {
      this.$emit("update:modelValue", value);
    },
  },
};
</script>
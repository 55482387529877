<template>
  <div class="flex flex-col" :class="$attrs.class">
    <label
      v-if="showlabel"
      :for="name"
      class="block text-sm font-light text-gray-700"
    >
      {{ labelText }}
    </label>
    <div class="mt-2">
      <textarea
        :class="[
          'w-full h-24 rounded-md shadow-sm text-sm border-1 focus:ring-primary border-none ring-1 focus:ring-2 focus:outline-none',
          errorMessage.length > 0 ? 'ring-error' : 'ring-bordercolor ',
          { 'bg-gray-100': disabled },
          $attrs.inputclass,
        ]"
        :id="id"
        :name="name"
        :type="type"
        :autocomplete="autocomplete"
        :disabled="disabled || loading"
        :required="required"
        :placeholder="placeholder"
        :value="modelValue"
        ref="input"
        @input="$emit('update:modelValue', $event.target.value)"
        @blur="onBlur"
        @focus="onFocus"
        :maxlength="maxlength"
      />
      <span v-if="errorMessage.length > 0" class="text-xs text-error">{{
        errorMessage[0]
      }}</span>
    </div>
  </div>
</template>

<script>
export default {
  emits: ["onblur", "onfocus", "update:modelValue"],
  props: {
    id: {
      type: String,
    },
    name: {
      type: String,
    },
    type: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    autocomplete: {
      type: String,
    },
    labelText: {
      type: String,
      default: "",
    },
    showlabel: {
      type: Boolean,
      default: true,
    },
    maxlength: {
      type: String,
      default: "",
    },
    modelValue: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    onBlur(event) {
      this.$emit("onblur", event);
    },
    onFocus(event) {
      this.$emit("onfocus", event);
    },
  },
};
</script>



<template>
  <div class="relative">
    <div
      class="
        mt-2
        filter
        inset-1
        blur-sm
        absolute
        rounded-md
        opacity-50
        bg-purple-300
      "
    />
    <div class="absolute mt-2 filter inset-1 blur-lg rounded-lg bg-gray-300" />
    <div class="relative bg-white mx-auto rounded-lg md:p-10 p-8">
      <slot />
    </div>
  </div>
</template>
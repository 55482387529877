<template>
  <app-cropper
    :image="croppedImage"
    :show="showCropper"
    :aspect_ratio="aspectRatio"
    @result="cropResult($event)"
    @cancel="cancelCrop"
    @close="showCropper = false"
  />

  <div class="flex flex-col" :class="$attrs.class">
    <label
      v-if="labelText != null"
      class="block text-sm font-light text-gray-700 mb-2"
    >
      {{ labelText }}
    </label>

    <div
      :class="[
        width,
        height,
        'relative rounded-md border-dashed cursor-pointer text-gray-400 hover:bg-gray-50 border border-bordercolor',
      ]"
      @click="$refs.upload_file.click"
    >
      <div class="h-full w-full">
        <input
          hidden
          ref="upload_file"
          type="file"
          accept="image/*"
          @change="selectedImage($event)"
        />
        <app-image
          :src="preview ?? uploaded_image"
          :loading="loading"
          class="h-full w-full rounded-md"
        />
      </div>

      <div
        v-if="!uploaded_image"
        class="
          absolute
          top-1/2
          left-1/2
          transform
          -translate-x-1/2 -translate-y-1/2
        "
      >
        <div class="items-center">
          <app-icon-outline name="PlusCircleIcon" class="w-6" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { logEvent } from "firebase/analytics";

export default {
  components: {},

  emits: ["onTempUpload"],

  data() {
    return {
      uploaded_image: null,
      croppedImage: {
        src: null,
        type: null,
      },
      showCropper: false,
    };
  },

  props: {
    preview: {
      type: String,
      default: null,
    },
    category: {
      type: String,
      default: null,
    },
    eventName: {
      type: String,
      default: null,
    },
    aspectRatio: {
      type: Number,
      default: 1.0,
    },
    labelText: {
      type: String,
      default: null,
    },
    width: {
      type: String,
      default: "w-26",
    },
    height: {
      type: String,
      default: "h-26",
    },
  },

  computed: {
    loading() {
      return this.$store.getters["mediaStore/loading"];
    },
  },

  methods: {
    selectedImage(event) {
      const { files } = event.target;
      if (files && files[0]) {
        if (this.croppedImage.src) {
          URL.revokeObjectURL(this.croppedImage.src);
        }
        const reader = new FileReader();
        reader.readAsArrayBuffer(files[0]);
        reader.onload = (e) => {
          this.showCropper = true;

          this.croppedImage = {
            src: URL.createObjectURL(files[0]),
            type: "image/png",
          };
        };
      }
    },

    async cropResult(event) {
      if (this.eventName != null) {
        logEvent(this.$analytics, "click", { event: this.eventName });
      }

      const canvas = event.canvas;

      if (!canvas) {
        this.$emit("onTempUpload", null);
        this.clearInputFile();
        return;
      }

      canvas
        .toBlob(async (blob) => {
          const form = new FormData();
          form.append("file", blob, ".png");

          let uploadResult = await this.$store.dispatch(
            "mediaStore/uploadFile",
            {
              file: form,
            }
          );

          if (uploadResult.data == null) {
            this.$emit("onTempUpload", null);
            this.clearInputFile();
            return;
          }

          this.uploaded_image = uploadResult.data.media_url;
          this.$emit("onTempUpload", uploadResult.data);
          this.clearInputFile();
        }, "image/png")
        .bind(this);
    },

    cancelCrop() {
      this.croppedImage = {
        src: null,
        type: null,
      };
      this.clearInputFile();
    },

    clearInputFile() {
      this.$refs.upload_file.value = null;
    },
  },
};
</script>
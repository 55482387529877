<template>
  <svg
    width="100"
    height="99"
    viewBox="0 0 100 99"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="1.44894"
      y="0.581633"
      width="97.102"
      height="97.102"
      rx="6.39796"
      fill="#F8F8FF"
    />
    <path
      d="M45.605 69.2383H73.3638C75.3558 69.3619 77.3168 68.6842 78.837 67.3468C80.3571 66.0094 81.3188 64.1158 81.5209 62.0619L85.9917 36.2037C86.7763 31.6169 84.7463 29.0272 80.2755 29.0272H52.4544C50.4604 28.9001 48.4962 29.5763 46.9733 30.9141C45.4504 32.2518 44.487 34.1474 44.2849 36.2037L39.9137 62.0619C39.1291 66.6486 41.1591 69.2383 45.6299 69.2383H45.605Z"
      fill="#52525B"
    />
    <path
      d="M18.3937 69.2381H46.1401C48.1341 69.3652 50.0983 68.6891 51.6212 67.3513C53.1441 66.0136 54.1075 64.118 54.3096 62.0617L58.7182 36.2035C59.4903 31.6168 57.4728 29.0271 53.002 29.0271H25.2306C23.2387 28.9034 21.2777 29.5812 19.7575 30.9186C18.2373 32.256 17.2757 34.1496 17.0736 36.2035L12.6651 62.0617C11.8805 66.6485 13.9104 69.2381 18.3812 69.2381H18.3937Z"
      fill="#3F3F46"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M83.9863 50.4663H31.5198V47.8125H83.9863V50.4663Z"
      fill="white"
    />
    <rect
      x="1.44894"
      y="0.581633"
      width="97.102"
      height="97.102"
      rx="6.39796"
      stroke="#F5F5F5"
      stroke-width="1.16327"
    />
    =
  </svg>
</template>
<template>
  <svg
    class="w-6 h-3 mt-1.5"
    :class="$attrs.class"
    viewBox="1 0 24 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.4913 12.6708H18.9447C19.5513 12.7075 20.1485 12.5064 20.6114 12.1093C21.0743 11.7123 21.3672 11.1502 21.4287 10.5405L22.7902 2.86434C23.0291 1.50276 22.411 0.73399 21.0495 0.73399H12.5771C11.9699 0.696262 11.3718 0.896986 10.908 1.29411C10.4442 1.69123 10.1509 2.25394 10.0893 2.86434L8.75815 10.5405C8.51923 11.9021 9.13739 12.6708 10.4989 12.6708H10.4913Z"
      fill="#D3CCE5"
    />
    <path
      d="M2.20483 12.6709H10.6544C11.2616 12.7086 11.8598 12.5079 12.3236 12.1108C12.7873 11.7136 13.0807 11.1509 13.1423 10.5405L14.4848 2.86436C14.7199 1.50277 14.1055 0.734003 12.7441 0.734003H4.28688C3.68028 0.697299 3.08309 0.898489 2.62015 1.29551C2.15721 1.69253 1.86437 2.25465 1.80283 2.86436L0.460299 10.5405C0.221374 11.9021 0.839543 12.6709 2.20103 12.6709H2.20483Z"
      fill="#623F99"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22.1796 7.09823H6.20203V6.31042H22.1796V7.09823Z"
      fill="white"
    />
  </svg>
</template>
<template>
  <p
    v-if="testMode"
    class="
      text-xs
      uppercase
      rounded-sm
      font-semibold
      bg-test-mode
      text-white
      px-2
    "
  >
    {{ $t("general.test_mode") }}
  </p>
</template>

<script>
export default {
  props: {
    
    testMode: {
      type: Boolean,
      default: false
    }
  },
};
</script>
import client from "@/data/base-client";
import WithAuthRepository from "./with-auth-repository";

export default class ReferenceRepository extends WithAuthRepository {
  constructor() {
    super();
  }

  async getCountries(filters = {}) {
    const response = await client.get("/reference/countries", null, filters);

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async getStates(filters = {}) {
    const response = await client.get("/reference/states", null, filters);

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async getPackages(filters = {}) {
    const response = await client.get("/reference/packages", null, filters);

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async getTypeOfBusinesses(filters = {}) {
    const response = await client.get(
      "/reference/type-of-business",
      null,
      filters
    );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async getIndustries(filters = {}) {
    const response = await client.get("/reference/industries", null, filters);

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async getBanks(filters = {}) {
    const response = await client.get("/reference/banks", null, filters);

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async getPaymentMethods(filters = {}) {
    const response = await client.get(
      "/reference/payment-methods",
      null,
      filters
    );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async getPaymentGateways(filters = {}) {
    const response = await client.get(
      "/reference/payment-gateways",
      null,
      filters
    );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async getPaymentGatewayMethods(filters = {}) {
    const response = await client.get(
      "/reference/payment-gateway-methods",
      null,
      filters
    );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async getPaymentGatewayApplicationStatuses(filters = {}) {
    const response = await client.get(
      "/reference/payment-gateway-application-statuses",
      null,
      filters
    );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async getRoles() {
    const response = await client.get(
      "/admin/roles",
      this.getToken()
    );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async getCompanyAnnualTurnover(filters = {}) {
    const response = await client.get("/reference/company-annual-turnovers", null, filters);

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }
}

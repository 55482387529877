<template>
  <div
    v-show="showComment"
    class="flex flex-col bg-gray-100 h-full rounded-md px-5 py-3 mb-5"
  >
    <div class="flex justify-between items-center mb-2">
      <b>{{ $t("business.main.comments_reasons" )}}</b>
      <button @click="showComment = false">
        <app-icon name="XIcon" class="w-5 mt-2 text-bold" />
      </button>
    </div>
    <span class="text-gray-500">{{ business.comment }}</span>
  </div>
</template>

<script>
export default {
  computed: {
    business() {
      return this.$store.getters["businessStore/business"];
    },
  },
  data() {
    return {
      showComment: true,
    };
  },
};
</script>
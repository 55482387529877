<template>
  <app-modal class="rounded-md" :showCancelButton="false" @close="$emit('close')">
    <template v-slot:title>
      <div class="flex justify-between text-base">
        <p>{{ $t("customer.add_customer") }}</p>
      </div>
    </template>

    <template v-slot:body>
      <div class="space-y-6">
        <div>
          <app-form-input
            v-model="customer.name"
            type="text"
            :labelText="$t('customer.customer_name')"
            :placeholder="$t('customer.customer_name_placeholder')"
            :errorMessage="errors.name"
          />
        </div>
        <div>
          <app-form-input
            v-model="customer.email"
            type="text"
            :labelText="$t('customer.customer_email')"
            :placeholder="$t('customer.customer_email_placeholder')"
            :errorMessage="errors.email"
          />
        </div>
        <div>
          <app-form-input
            v-model="customer.billing_address_1"
            type="text"
            :placeholder="$t('general.address.line_1')"
            :labelText="$t('business.structure.address_label')"
            :errorMessage="errors.billing_address_1"
          />

          <app-form-input
            v-model="customer.billing_address_2"
            type="text"
            :placeholder="$t('general.address.line_2')"
            :errorMessage="errors.billing_address_2"
          />

          <app-form-input
            v-model="customer.billing_postcode"
            type="number"
            :placeholder="$t('general.address.postcode')"
            :errorMessage="errors.billing_postcode"
          />

          <app-form-input
            v-model="customer.billing_city"
            type="text"
            :placeholder="$t('general.address.city')"
            :errorMessage="errors.billing_city"
          />

          <app-form-state
            v-model="customer.billing_state"
            :showLabel="false"
            :placeholder="$t('general.address.state')"
            :country_id="business.country?.id ?? 0"
            :errorMessage="errors.billing_state_id"
          />
          <app-form-country
            :showLabel="false"
            :allowFilter="true"
            :showIcon="true"
            v-model="customer.billing_country"
            :errorMessage="errors.billing_country_id"
          />
        </div>
        <div>
          <div class="flex flex-row justify-between">
            <p class="text-sm text-gray-700">
              {{ $t("customer.shipping_address") }}
            </p>
            <div class="flex flex-row">
              <app-form-checkbox
                v-model="customer.use_billing_address"
                class="-mt-1"
                @click="statusCheck"
                
              />
              <p class="text-sm text-gray-500">
                {{ $t("customer.same_billing_address") }}
              </p>
            </div>
          </div>
          <app-form-input
            type="text"
            :placeholder="$t('general.address.line_1')"
            :label="false"
            :errorMessage="errors.shipping_address_1"
            v-model="customer.shipping_address_1"
            :disabled="customer.use_billing_address"
          />

          <app-form-input
            type="text"
            :placeholder="$t('general.address.line_2')"
            :errorMessage="errors.shipping_address_2"
            v-model="customer.shipping_address_2"
            :disabled="customer.use_billing_address"
          />

          <app-form-input
            type="number"
            :placeholder="$t('general.address.postcode')"
            :errorMessage="errors.shipping_postcode"
            v-model="customer.shipping_postcode"
            :disabled="customer.use_billing_address"
          />

          <app-form-input
            type="text"
            :placeholder="$t('general.address.city')"
            :errorMessage="errors.shipping_city"
            v-model="customer.shipping_city"
            :disabled="customer.use_billing_address"
          />

          <app-form-state
            :showLabel="false"
            :placeholder="$t('general.address.state')"
            :country_id="business.country?.id ?? 0"
            v-model="customer.shipping_state"
            :errorMessage="errors.shipping_state_id"
            :disabled="customer.use_billing_address"
          />

          <app-form-country
            :showLabel="false"
            :allowFilter="true"
            :showIcon="true"
            v-model="customer.shipping_country"
            :errorMessage="errors.shipping_country_id"
            :disabled="customer.use_billing_address"
          />
        </div>
        <div>
          <app-form-telephone-no
            :labelText="$t('payment.create.phone_no_label')"
            v-model="customer.phone_no"
            :errorMessage="errors.phone_no"
          />
        </div>
        <div>
          <app-form-input
            type="text"
            :labelText="$t('customer.invoice_prefix')"
            :placeholder="$t('customer.invoice_prefix_placeholder')"
            v-model="customer.invoice_prefix"
            :errorMessage="errors.invoice_prefix"
          />
        </div>
        <div>
          <app-form-input
            type="text"
            :labelText="$t('customer.invoice_sequence')"
            :placeholder="$t('customer.invoice_prefix_placeholder')"
            v-model="customer.invoice_sequence"
            :errorMessage="errors.invoice_sequence"
          />
        </div>
        <div>
          <app-alert-error
            v-if="errors.business_id"
            :message="errors.business_id[0]"
          />
        </div>
      </div>
    </template>

    <template v-slot:action>
      <app-button
        class="w-full"
        @click="onClickAddCustomer()"
        :showf70Icon="false"
        :disabled="hasActiveBusiness"
        
      >
        {{ $t("customer.add_customer") }}
      </app-button>
    </template>
  </app-modal>
</template>

<script>
import appAlertError from "../alert/app-alert-error.vue";
import AppFormCountry from "../form/reference/app-form-country.vue";
import Business_status from "@/utils/const/business_status";
export default {
  components: { appAlertError, AppFormCountry },
  emits: ["close", "refresh", "success"],
  data() {
    return {
      statusCheck:false,
    };
  },
  computed: {
    errors() {
      return this.$store.getters["customerStore/errors"];
    },
     business() {
      return this.$store.getters["businessStore/business"];
    },
    hasActiveBusiness() {
      if (this.business.business_status_id == null) {
        return false;
      }
      return this.business.business_status_id != Business_status.APPROVED;
    },
    customer() {
      return this.$store.getters["customerStore/customer"];
    },
  },
  
  methods: {
    checkStatus(){
        this.statusCheck != this.statusCheck
        return this.statusCheck
    },

    copyAddress(statusCheck) {
   
      this.customer.shipping_address_1 = statusCheck?this.customer.billing_address_1 :null; 
      this.customer.shipping_address_2 = statusCheck?this.customer.billing_address_2:null;
      this.customer.shipping_postcode  = statusCheck?this.customer.billing_postcode:null;
      this.customer.shipping_city      = statusCheck?this.customer.billing_city:null;
      this.customer.shipping_state     = statusCheck?this.customer.billing_state:null;
      this.customer.shipping_country   = statusCheck?this.customer.billing_country:null;
    },

    async onClickAddCustomer() {
      this.customer.billing_country_id  = this.customer.billing_country?.id;
      this.customer.billing_state_id    = this.customer.billing_state?.id;
      this.customer.shipping_country_id = this.customer.shipping_country?.id;
      this.customer.shipping_state_id   = this.customer.shipping_state?.id;

      const result = await this.$store.dispatch(
        "customerStore/createOrUpdateCustomer",
        this.customer
      );

      if (this.$lodash.isEmpty(result.errors)) {
        this.$store.dispatch("customerStore/resetCustomer");

        this.close();

        this.refresh();

        this.returnData(result.data);

        this.$notify(
          {
            group: "success",
            title: "Saved!",
          },
          3000
        );
      }
    },

    close() {
      this.$emit("close");
    },

    refresh() {
      this.$emit("refresh", true);
    },

    returnData(data) {
      this.$emit("success", data);
    },
  },
};
</script>
<template>
  <div class="relative">
    <input
      type="text"
      readonly
      class="
        px-3
        py-2
        rounded-lg
        focus:outline-none
        focus:shadow-outline
        text-gray-200
        border-gray-200
        w-full
        h-full
      "
      placeholder="Sep 10 - Sep 20"
    />

    <app-icon
      name="CalendarIcon"
      class="absolute w-5 h-5 top-0 bottom-0 my-auto right-3 text-gray-500"
    />
  </div>
</template>

<script>
export default {};
</script>



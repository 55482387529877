<template>
  <app-full-modal title="Details">
    <template v-slot:body>
      <div>
        <div class="mt-5">
          <div class="p-5 space-y-2">
            <div class="block sm:grid grid-cols-5 text-sm">
              <p class="col-span-2 text-gray-400" width="120px">
                {{ $t("admin.audit.id") }}
              </p>
              <p class="col-span-3">{{ selectAuditTrail._id }}</p>
            </div>
            <div class="block sm:grid grid-cols-5 text-sm" v-if="selectAuditTrail.user_type != null">
              <p class="col-span-2 text-gray-400" width="120px">
                {{ $t("admin.audit.made_by") }}
              </p>
              <p class="col-span-3">
                {{
                  selectAuditTrail.user_type === "Modules\\Auth\\Entities\\User"
                  ? selectAuditTrail.user.name +" (" + selectAuditTrail.user.email + ")" 
                  : selectAuditTrail.integration.business.name
                }}
              </p>
            </div>
            <div class="block sm:grid grid-cols-5 text-sm">
              <p class="col-span-2 text-gray-400" width="120px">
                {{ $t("admin.audit.via") }}
              </p>
              <p class="col-span-3">
                {{ selectAuditTrail.via }}
              </p>
            </div>
            <div class="block sm:grid grid-cols-5 text-sm">
              <p class="col-span-2 text-gray-400" width="120px">
                {{ $t("admin.audit.old_values") }}
              </p>
              <div
                class="
                  block
                  whitespace-pre
                  overflow-x-scroll
                  bg-gray-900
                  text-yellow-200 text-xs
                  p-2
                  mb-3
                  rounded-md
                  col-span-3
                "
              >
                <code v-text="selectAuditTrail.old_values" />
              </div>
            </div>
            <div class="block sm:grid grid-cols-5 text-sm">
              <p class="col-span-2 text-gray-400" width="120px">
                {{ $t("admin.audit.new_values") }}
              </p>
              <div
                class="
                  block
                  whitespace-pre
                  overflow-x-scroll
                  bg-gray-900
                  text-yellow-200 text-xs
                  p-2
                  mb-3
                  rounded-md
                  col-span-3
                "
              >
                <code v-text="selectAuditTrail.new_values" />
              </div>
            </div>
            <div class="block sm:grid grid-cols-5 text-sm">
              <p class="col-span-2 text-gray-400" width="120px">
                {{ $t("admin.audit.event") }}
              </p>
              <p class="col-span-3">{{ selectAuditTrail.event ?? "-" }}</p>
            </div>
            <div class="block sm:grid grid-cols-5 text-sm">
              <p class="col-span-2 text-gray-400" width="120px">
                {{ $t("admin.audit.auditable_id") }}
              </p>
              <p class="col-span-3">
                {{ selectAuditTrail.auditable_id ?? "-" }}
              </p>
            </div>
            <div class="block sm:grid grid-cols-5 text-sm">
              <p class="col-span-2 text-gray-400" width="120px">
                {{ $t("admin.audit.auditable_type") }}
              </p>
              <p class="col-span-3">
                {{ selectAuditTrail.auditable_type ?? "-" }}
              </p>
            </div>
            <div class="block sm:grid grid-cols-5 text-sm">
              <p class="col-span-2 text-gray-400" width="120px">
                {{ $t("admin.audit.user_id") }}
              </p>
              <p class="col-span-3">{{ selectAuditTrail.user_id ?? "-" }}</p>
            </div>
            <div class="block sm:grid grid-cols-5 text-sm">
              <p class="col-span-2 text-gray-400" width="120px">
                {{ $t("admin.audit.user_type") }}
              </p>
              <p class="col-span-3">{{ selectAuditTrail.user_type ?? "-" }}</p>
            </div>
            <div class="block sm:grid grid-cols-5 text-sm">
              <p class="col-span-2 text-gray-400" width="120px">
                {{ $t("admin.audit.url") }}
              </p>
              <p class="col-span-3">{{ selectAuditTrail.url ?? "-" }}</p>
            </div>
            <div class="block sm:grid grid-cols-5 text-sm">
              <p class="col-span-2 text-gray-400" width="120px">
                {{ $t("admin.audit.ip_address") }}
              </p>
              <p class="col-span-3">{{ selectAuditTrail.ip_address ?? "-" }}</p>
            </div>
            <div class="block sm:grid grid-cols-5 text-sm">
              <p class="col-span-2 text-gray-400" width="120px">
                {{ $t("admin.audit.user_agent") }}
              </p>
              <p class="col-span-3">{{ selectAuditTrail.user_agent ?? "-" }}</p>
            </div>
            <div class="block sm:grid grid-cols-5 text-sm">
              <p class="col-span-2 text-gray-400" width="120px">
                {{ $t("admin.audit.tags") }}
              </p>
              <p class="col-span-3">{{ selectAuditTrail.tags ?? "-" }}</p>
            </div>
            <div class="block sm:grid grid-cols-5 text-sm">
              <p class="col-span-2 text-gray-400" width="120px">
                {{ $t("admin.audit.created_at") }}
              </p>
              <p class="col-span-3">
                {{
                  $moment(selectAuditTrail.created_at).format(
                    "DD MMM YYYY hh:mm a"
                  )
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </template>
  </app-full-modal>
</template>

<script>
export default {
  props: {
    selectAuditTrail: {
      type: Object,
      default: null,
    },
  },
};
</script>
export default {
    zeroCurrencies() {
        return [
            "BIF", "CLP", "DJF", "GNF", "JPY", "KMF", "KRW",
            "MGA", "PYG", "RWF", "UGX", "VND", "VUV", "XAF",
            "XOF", "XPF"
        ];
    },

    currency(currency, amount, showCurrency = true) {
        if (isNaN(amount)) return;
        if (!currency) return amount;

        // check if currency is zero decimal no need divide 100
        amount = this.zeroCurrencies().includes(currency) ? amount : amount / 100;

        var formattedAmount = new Intl.NumberFormat(process.env.VUE_APP_LOCALE, {
            style: "currency",
            currency: currency,
        }).format(amount);

        return (showCurrency) ? formattedAmount : formattedAmount.replace(currency, "")
            .trim();
    },

    formattedCurrency(currency, amount, showCurrency = true) {
        if (isNaN(amount)) return;
        if (!currency) return amount;

        var formattedAmount = new Intl.NumberFormat(process.env.VUE_APP_LOCALE, {
            style: "currency",
            currency: currency,
        }).format(amount);

        return (showCurrency) ? formattedAmount : formattedAmount.replace(currency, "")
            .trim();
    },

    toCents(currency, amount) {
        if (isNaN(amount)) return;
        if (!currency) return amount;

        // check if currency is zero decimal no need divide 100
        return this.zeroCurrencies().includes(currency) ?
            parseInt(amount) : parseInt(amount * 100);
    },

    hasConverted(currency, amount) {
        return (this.zeroCurrencies().includes(currency)) ? true :
            String(amount).indexOf(".") > 0;
    },
}
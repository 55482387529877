<template>
  <!-- Google Tag Manager (noscript) -->
  <no-script>
    <iframe
      :src="
        'https://www.googletagmanager.com/ns.html?id=' + $googleTagManagerId
      "
      height="0"
      width="0"
      style="display: none; visibility: hidden"
    ></iframe>
  </no-script>
  <!-- End Google Tag Manager (noscript) -->
  <router-view />
  <app-alert />
</template>
<script>
import ROLE from "@/utils/const/role";

export default {
  beforeCreate() {
    this.$store.dispatch("authStore/checkAuth");
  },
  mounted() {
    this.initGoogleTagHead();
  },
  computed: {
    email_not_verify_error() {
      return this.$store.getters.email_not_verify_error;
    },
    error_message() {
      return this.$store.getters.error_message;
    },
    user() {
      return this.$store.getters["authStore/user"];
    },
    selectedRole() {
      return this.$store.getters["authStore/selectedRole"];
    },
  },
  methods: {
    initGoogleTagHead() {
      let wind = window;
      let docs = document;
      let script = "script";
      let layer = "dataLayer";

      wind[layer] = wind[layer] || [];
      wind[layer].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });

      let f = docs.getElementsByTagName(script)[0];
      let j = docs.createElement(script);
      let dl = layer != "dataLayer" ? "&l=" + layer : "";

      j.async = true;
      j.src =
        "https://www.googletagmanager.com/gtm.js?id=" +
        this.$googleTagManagerId +
        dl;

      f.parentNode.insertBefore(j, f);
    },
  },
  watch: {
    email_not_verify_error: {
      handler(error) {
        if (!this.$lodash.isEmpty(error)) {
          this.$notify(
            {
              group: "error",
              title: error,
            },
            2000
          );
        }
      },
      deep: true,
    },
    error_message: {
      handler(errorMessage) {
        if (!this.$lodash.isEmpty(errorMessage)) {
          this.$notify(
            {
              group: "error",
              title: errorMessage,
            },
            5000
          );
        }
      },
      deep: true,
    },
    $route(to, from) {},
  },
};
</script>

<template>
  <div
    ref="item"
    v-on:mouseenter="showPopper(true)"
    v-on:mouseleave="showPopper(false)"
  >
    <slot />
    <div
      v-if="text && !show"
      v-show="tooltipShow"
      ref="tooltip"
      id="tooltip"
      role="tooltip"
      class="shadow-md rounded-lg px-4 py-2"
      :class="[customClass, tooltipColor]"
    >
      <p class="text-black" v-html="text"></p>
      <div id="arrow" data-popper-arrow></div>
    </div>
  </div>
</template>

<script>
import { createPopper } from "@popperjs/core";

export default {
  data() {
    return {
      tooltipShow: false,
    };
  },
  props: {
    text: {
      type: String,
      required: true,
    },
    position: {
      type: String,
      default: "top",
    },
    customClass: {
      type: String,
    },
    tooltipColor: {
      type: String,
      default: "bg-white",
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    async showPopper(show) {
      this.tooltipShow = show;

      if (show) {
        createPopper(this.$refs.item, this.$refs.tooltip, {
          placement: this.position,
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 8],
              },
            },
          ],
        });
      }
    },
  },
};
</script>

<style scoped>
#tooltip {
  display: inline-block;
}

#arrow,
#arrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
  background: inherit;
}

#arrow {
  visibility: hidden;
}

#arrow::before {
  visibility: visible;
  content: "";
  transform: rotate(45deg);
}

#tooltip[data-popper-placement^="top"] > #arrow {
  bottom: -4px;
}

#tooltip[data-popper-placement^="bottom"] > #arrow {
  top: -4px;
}

#tooltip[data-popper-placement^="left"] > #arrow {
  right: -4px;
}

#tooltip[data-popper-placement^="right"] > #arrow {
  left: -4px;
}
</style>
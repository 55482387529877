<template>
  <!-- Select Business-->
  <app-modal
    :show="showModalBusiness"
    @close="(showModalBusiness = false), (business = null)"
    :showCancelButton="false"
  >
    <template v-slot:title>
      {{ $t("business.copy.select_business_title") }}
    </template>

    <template v-slot:body>
      <app-form-select
        :datas="all_business"
        :allowFilter="false"
        v-model="business"
        class="py-3"
      />
    </template>

    <template v-slot:action>
      <app-button
        :disabled="business == null"
        :showf70Icon="false"
        :primary="true"
        type="button"
        class="w-full outline-none"
        @click="(showModalBusiness = false), (showModalInfo = true)"
      >
        {{ $t("general.proceed") }}
      </app-button>
    </template>
  </app-modal>

  <!-- info Business-->

  <app-modal
    :show="showModalInfo"
    @close="(showModalInfo = false), (business = null)"
    :showCancelButton="false"
  >
    <template v-slot:title>
      <p class="text-xs font-normal">
        {{ $t("business.copy.info_business_title") }}
      </p>
      <p class="text-sm font-bold">{{ business?.name ?? "-" }}</p>
    </template>
    <template v-slot:body>
      <div class="divide-y divide-gray-100 text-left">
        <!-- business structure -->

        <app-expand
          v-if="show_info == 'all' || show_info == 'structure'"
          :expanded="show_info == 'structure'"
        >
          <template v-slot:head="slot">
            <div class="flex justify-between py-2 cursor-pointer text-sm my-1">
              <p>
                {{ $t("business.main.structure") }}
              </p>
              <app-icon-chevron
                :rotate="slot.expanded || show_info == 'structure'"
              />
            </div>
          </template>
          <template v-slot:body>
            <div class="border border-gray rounded-md p-4 text-sm mb-3">
              <div v-if="business?.id != null" class="space-y-2">
                <div>
                  <p class="font-medium">
                    {{ $t("business.structure.country_label") }}
                  </p>
                  <p class="text-gray-600">{{ business.country.name }}</p>
                </div>
                <div>
                  <p class="font-medium">
                    {{ $t("business.structure.info_label") }}
                  </p>
                  <p class="text-gray-600">{{ business.name }}</p>
                </div>
                <div>
                  <p class="font-medium">
                    {{ $t("business.structure.address_label") }}
                  </p>
                  <p class="text-gray-600">
                    {{ business.address_1 }}
                    <br />
                    {{ business.address_2 }}
                    <br />
                    {{ business.postcode }}, {{ business.city }},
                    {{ business.state?.name ?? "" }}
                  </p>
                </div>
                <div>
                  <p class="font-medium">
                    {{ $t("business.structure.type_of_business_label") }}
                  </p>
                  <p class="text-gray-600">
                    {{ business.type_of_business?.description }}
                  </p>
                </div>
              </div>
            </div>
          </template>
        </app-expand>

        <!-- business details -->

        <app-expand
          v-if="show_info == 'all' || show_info == 'detail'"
          :expanded="show_info == 'detail'"
        >
          <template v-slot:head="slot">
            <div class="flex justify-between py-2 cursor-pointer text-sm my-1">
              <p>
                {{ $t("business.main.details") }}
              </p>
              <app-icon-chevron :rotate="slot.expanded" />
            </div>
          </template>
          <template v-slot:body>
            <div class="border border-gray rounded-md p-4 text-sm mb-3">
              <div v-if="business?.detail?.id != null" class="space-y-2">
                <div>
                  <p class="font-medium">
                    {{ $t("business.detail.company_label") }}
                  </p>
                  <p class="text-gray-600">
                    {{ business.detail.company_name }}
                  </p>
                </div>
                <div>
                  <p class="font-medium">
                    {{ $t("business.summary.detail_company_no") }}
                  </p>
                  <p class="text-gray-600">
                    {{ business.detail.company_reg_no }}
                  </p>
                </div>
                <div>
                  <p class="font-medium">
                    {{ $t("business.summary.detail_company_annual_turnover") }}
                  </p>
                  <p class="text-gray-600">
                    {{ business.detail.company_annual_turnover?.description }}
                  </p>
                </div>
                <div>
                  <p class="font-medium">
                    {{ $t("business.summary.detail_activity") }}
                  </p>
                  <p class="text-gray-600">
                    {{ business.detail.business_activity }}
                  </p>
                </div>
                <div>
                  <p class="font-medium">
                    {{ $t("business.structure.address_label") }}
                  </p>
                  <p class="text-gray-600">
                    {{ business.detail.address_1 }}
                    <br />
                    {{ business.detail.address_2 }}
                    <br />
                    {{ business.detail.postcode }}, {{ business.detail.city }},
                    {{ business.detail.state?.name ?? "" }}
                  </p>
                </div>
                <div>
                  <p class="font-medium">
                    {{ $t("business.detail.phone_no_label") }}
                  </p>
                  <p class="text-gray-600">{{ business.detail.phone_no }}</p>
                </div>
                <div>
                  <p class="font-medium">
                    {{ $t("business.detail.industry_label") }}
                  </p>
                  <p class="text-gray-600">
                    {{ business.detail.industry?.name }}
                  </p>
                </div>
                <div>
                  <p class="font-medium">
                    {{ $t("business.detail.website_label") }}
                  </p>
                  <p class="text-gray-600">{{ business.detail.website }}</p>
                </div>
                <div>
                  <p class="font-medium">
                    {{ $t("business.detail.product_desc_label") }}
                  </p>
                  <p class="text-gray-600">{{ business.detail.description }}</p>
                </div>
              </div>
            </div>
          </template>
        </app-expand>

        <!-- business representative -->

        <app-expand
          v-if="show_info == 'all' || show_info == 'representative'"
          :expanded="show_info == 'representative'"
        >
          <template v-slot:head="slot">
            <div class="flex justify-between py-2 cursor-pointer text-sm my-1">
              <p>
                {{ $t("business.main.representative") }}
              </p>
              <app-icon-chevron :rotate="slot.expanded" />
            </div>
          </template>
          <template v-slot:body>
            <div class="border border-gray rounded-md p-4 text-sm mb-3">
              <div
                v-if="business?.representative?.id != null"
                class="space-y-2"
              >
                <div>
                  <p class="font-medium">
                    {{ $t("business.representative.legal_name") }}
                  </p>
                  <p class="text-gray-600">
                    {{ business.representative.full_name }}
                  </p>
                </div>
                <div>
                  <p class="font-medium">
                    {{ $t("business.representative.email") }}
                  </p>
                  <p class="text-gray-600">
                    {{ business.representative.email }}
                  </p>
                </div>
                <div>
                  <p class="font-medium">
                    {{ $t("business.representative.designation") }}
                  </p>
                  <p class="text-gray-600">
                    {{ business.representative.designation }}
                  </p>
                </div>
                <div>
                  <p class="font-medium">
                    {{ $t("business.representative.date_of_birth") }}
                  </p>
                  <p class="text-gray-600">
                    {{
                      business.representative.date_of_birth
                        ? $moment(
                            String(business.representative.date_of_birth),
                            "YYYY-MM-DD"
                          ).format("DD / MM / YYYY")
                        : "-"
                    }}
                  </p>
                </div>
                <div>
                  <p class="font-medium">
                    {{ $t("business.representative.home_address") }}
                  </p>
                  <p class="text-gray-600">
                    {{ business.representative.address_1 }}
                    <br />
                    {{ business.representative.address_2 }}
                    <br />
                    {{ business.representative.postcode }},
                    {{ business.representative.city }},
                    <br />
                    {{ business.representative.state?.name ?? "" }},
                    {{ business.representative.country?.name ?? "" }}
                  </p>
                </div>
                <div>
                  <p class="font-medium">
                    {{ $t("business.representative.phone_no") }}
                  </p>
                  <p class="text-gray-600">
                    {{ business.representative.phone_no }}
                  </p>
                </div>
                <div>
                  <p class="font-medium">
                    {{ labelIdentificationType }}
                  </p>
                  <p class="text-gray-600">
                    {{ business.representative.identification }}
                  </p>
                </div>
              </div>
            </div>
          </template>
        </app-expand>

        <!-- business owners -->

        <app-expand
          v-if="show_info == 'all' || show_info == 'owners'"
          :expanded="show_info == 'owners'"
        >
          <template v-slot:head="slot">
            <div class="flex justify-between py-2 cursor-pointer text-sm my-1">
              <p>
                {{ $t("business.main.owners") }}
              </p>
              <app-icon-chevron :rotate="slot.expanded" />
            </div>
          </template>
          <template v-slot:body>
            <div class="border border-gray rounded-md p-4 text-sm mb-3">
              <div v-if="business?.owners && business.owners?.length > 0">
                <div
                  v-for="(owner, index) in business.owners"
                  :key="owner.id"
                  class="space-y-3"
                >
                  <div>
                    <p class="font-medium">
                      {{ $t("business.owner.label_name_of_person") }}
                    </p>
                    <p class="text-gray-600">
                      {{ owner.first_name }}
                      {{ owner.last_name }}
                    </p>
                  </div>

                  <div>
                    <p class="font-medium">
                      {{ owner.identification_type ?? "-" }}
                    </p>
                    <p class="text-gray-600">
                      {{ owner.identification ?? "-" }}
                    </p>
                  </div>

                  <div>
                    <p class="font-medium">
                      {{ $t("business.owner.label_phone_no") }}
                    </p>
                    <p class="text-gray-600">{{ owner.phone_no ?? "-" }}</p>
                  </div>

                  <div>
                    <p class="font-medium">
                      {{ $t("business.owner.label_email") }}
                    </p>
                    <p class="text-gray-600">
                      {{ owner.email }}
                    </p>
                  </div>
                  <hr v-if="index + 1 < business.owners.length" class="pb-3" />
                </div>
              </div>
            </div>
          </template>
        </app-expand>

        <!-- business support_details -->

        <app-expand
          v-if="show_info == 'all' || show_info == 'support_detail'"
          :expanded="show_info == 'support_detail'"
        >
          <template v-slot:head="slot">
            <div class="flex justify-between py-2 cursor-pointer text-sm my-1">
              <p>
                {{ $t("business.main.support_details") }}
              </p>
              <app-icon-chevron :rotate="slot.expanded" />
            </div>
          </template>
          <template v-slot:body>
            <div class="border border-gray rounded-md p-4 text-sm mb-3">
              <div
                v-if="business?.support_detail?.id != null"
                class="space-y-2"
              >
                <div class="mb-3">
                  <p class="font-medium">
                    {{ $t("business.support_detail.statement_descriptor") }}
                  </p>
                  <p class="text-gray-600">
                    {{ business.support_detail.statement_descriptor }}
                  </p>
                </div>
                <div class="mb-3">
                  <p class="font-medium">
                    {{ $t("business.support_detail.shortened_descriptor") }}
                  </p>
                  <p class="text-gray-600">
                    {{ business.support_detail.short_descriptor }}
                  </p>
                </div>
                <div class="mb-3">
                  <p class="font-medium">
                    {{ $t("business.support_detail.support_email") }}
                  </p>
                  <p class="text-gray-600">
                    {{ business.support_detail.support_email }}
                  </p>
                </div>
                <div class="mb-3">
                  <p class="font-medium">
                    {{ $t("business.support_detail.phone_no") }}
                  </p>
                  <p class="text-gray-600">
                    {{ business.support_detail.support_tel_no }}
                  </p>
                </div>
              </div>
            </div>
          </template>
        </app-expand>

        <!-- business bank -->

        <app-expand
          v-if="show_info == 'all' || show_info == 'bank'"
          :expanded="show_info == 'bank'"
        >
          <template v-slot:head="slot">
            <div class="flex justify-between py-2 cursor-pointer text-sm my-1">
              <p>
                {{ $t("business.main.bank") }}
              </p>
              <app-icon-chevron :rotate="slot.expanded" />
            </div>
          </template>
          <template v-slot:body>
            <div class="border border-gray rounded-md p-4 text-sm mb-3">
              <div v-if="business?.bank?.id != null" class="space-y-2">
                <div>
                  <p class="font-medium">{{ $t("general.bank") }}</p>
                  <p class="text-gray-600">
                    {{ business.bank.bank?.display_name }}
                  </p>
                </div>
                <div>
                  <p class="font-medium">
                    {{ $t("business.bank_details.account_number") }}
                  </p>
                  <p class="text-gray-600">{{ business.bank.account_no }}</p>
                </div>
                <div>
                  <p class="font-medium">
                    {{ $t("business.bank_details.account_holder") }}
                  </p>
                  <p class="text-gray-600">
                    {{ business.bank.account_holder }}
                  </p>
                </div>
              </div>
            </div>
          </template>
        </app-expand>

        <!-- business package -->

        <!-- <app-expand
          v-if="show_info == 'all' || show_info == 'package'"
          :expanded="show_info == 'package'"
        >
          <template v-slot:head="slot">
            <div class="flex justify-between py-2 cursor-pointer text-sm my-1">
              <p>
                {{ $t("business.package.package") }}
              </p>
              <app-icon-chevron :rotate="slot.expanded" />
            </div>
          </template>
          <template v-slot:body>
            <div class="border border-gray rounded-md p-4 text-sm mb-3">
              <div v-if="business?.package?.id != null" class="space-y-2">
                <div>
                  <p class="font-medium">
                    {{ $t("business.package.package") }}
                  </p>
                  <p class="text-gray-600">
                    {{
                      business.package?.name[0].toUpperCase() +
                      business.package?.name.substring(1)
                    }}
                  </p>
                </div>
              </div>
              <div v-else>
                <p>
                  {{
                    $t("business.summary.missing_required_business_information")
                  }}
                </p>
              </div>
            </div>
          </template>
        </app-expand> -->
      </div>
    </template>

    <template v-slot:action>
      <app-button-outline
        @click="(showModalInfo = false), copyBusiness(business)"
        :showf70Icon="false"
        :primary="true"
        type="button"
        class="w-full bg-primary hover:bg-primary-200 text-red-50"
        :disabled="business == null"
      >
        {{ $t("general.proceed") }}
      </app-button-outline>
      <div class="w-5 h-2"></div>
      <app-button-outline
        @click="(showModalBusiness = true), (showModalInfo = false)"
        :showf70Icon="false"
        :primary="true"
        type="button"
        class="w-full"
      >
        {{ $t("general.back") }}
      </app-button-outline>
    </template>
  </app-modal>

  <!-- Loading Copy Business-->

  <app-loading
    :text="
      $t('business.copy.loading_text', {
        business_name: business?.name,
      })
    "
    :loading="loading"
    class="w-96 h-36 m-auto rounded-md shadow-md"
  />
</template>

<script>
import BUSINESS_STATUS from "@/utils/const/business_status";

export default {
  emits: ["show", "copy-business"],
  data() {
    return {
      showModalInfo: false,
      business: null,
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    show_info: {
      type: String,
      default: "all",
    },
  },
  computed: {
    all_business() {
      return this.$store.getters["businessStore/all_business"];
    },
    labelIdentificationType() {
      return this.business.representative.identification_type == "MyKad"
        ? this.$t("business.representative.mykad")
        : this.$t("business.representative.passport");
    },
    showModalBusiness: {
      get() {
        return this.show;
      },
      set(show) {
        this.$emit("show", show);
      },
    },
  },
  mounted() {
    this.$store.dispatch("businessStore/getBusinesses", {
      business_status_id: BUSINESS_STATUS.APPROVED,
    });
  },
  methods: {
    copyBusiness(business) {
      this.showModalInfo = false;
      this.$emit("copy-business", business);
    },
  },
};
</script>

<template>
  <svg
    class="h-5 w-5 text-gray-700"
    viewBox="0 0 20 20"
    fill="none"
    stroke="currentColor"
  >
    <path
      d="M7 7l3-3 3 3m0 6l-3 3-3-3"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
export default class BaseDao {
    constructor() {

    }

    getData(key) {
        try {
            const store = localStorage.getItem(key);

            if (!store) {
                return null;
            }

            const object = JSON.parse(store);
            const now = new Date();

            if (now.getTime() > object.expiry) {
                localStorage.removeItem(key);
                return null;
            }

            return object.value;

        } catch (error) {
            return null;
        }
    }

    saveData(key, data, time = 86400000) {
        try {
            const now = new Date();

            const object = {
                value: data,
                expiry: now.getTime() + time
            };

            localStorage.setItem(key, JSON.stringify(object));

        } catch (error) {
            window.localStorage.setItem(key, null)
        }
    }
}

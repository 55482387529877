import client from "@/data/base-client";
import WithAuthRepository from "./with-auth-repository";
import BUSINESS_STATUS from "@/utils/const/business_status";

export default class BusinessRepository extends WithAuthRepository {
  constructor() {
    super();
  }

  async createOrUpdate(business) {
    business.country_id = business.country != null ? business.country.id : 0;
    business.state_id = business.state != null ? business.state.id : 0;
    business.type_of_business_id =
      business.type_of_business != null ? business.type_of_business.id : 0;

    const response =
      business.id == null
        ? await client.post("/businesses", business, this.getToken())
        : await client.put(
          "/businesses/" + business.id,
          business,
          this.getToken()
        );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(this.mapData(response.data));
  }

  async getBusinessById(businessId) {
    const response = await client.get(
      "/businesses/" + businessId,
      this.getToken()
    );

    if (this.$lodash.isEmpty(response.data)) {
      return response;
    }

    return this.successResponse(this.mapData(response.data));
  }

  async getAllMerchantType() {
    const response = await client.get("/all-merchant-type",
      this.getToken()
    );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async statusBusiness(businessId, businessStatusId, comment, pending_payment_comment) {
    const response = await client.patch(
      "/businesses/" + businessId + "/status",
      {
        business_status_id: businessStatusId,
        comment: comment,
        pending_payment_comment: pending_payment_comment,
      },
      this.getToken()
    );

    if (this.$lodash.isEmpty(response.data)) {
      return response;
    }

    return this.successResponse(this.mapData(response.data));
  }

  async upgradeBusiness(businessId, masterBusinessType) {
    const response = await client.post(
      "/businesses/" + businessId + "/upgrade",
      {
        master_business_type: masterBusinessType,
      },
      this.getToken()
    );

    if (this.$lodash.isEmpty(response.data)) {
      return response;
    }

    return this.successResponse(response.data);
  }

  async submitBusiness(businessId) {
    const response = await client.post(
      "/businesses/" + businessId + "/submit",
      {},
      this.getToken()
    );

    if (this.$lodash.isEmpty(response.data)) {
      return response;
    }

    return this.successResponse(this.mapData(response.data));
  }

  async getListBusiness(queryParams) {
    const response = await client.get(
      "/all-businesses" + queryParams,
      this.getToken()
    );

    if (this.$lodash.isEmpty(response.data)) {
      return response;
    }

    return this.successResponse(response.data);
  }

  async getListBusinessForSales(queryParams) {
    const response = await client.get(
      "/all-businesses-for-sales" + queryParams,
      this.getToken()
    );

    if (this.$lodash.isEmpty(response.data)) {
      return response;
    }

    return this.successResponse(response.data);
  }

  async getBusinesses(params = {}) {
    const response = await client.get("/businesses", this.getToken(), params);

    if (this.$lodash.isEmpty(response.data)) {
      return response;
    }

    return this.successResponse(response.data);
  }

  async copyBusiness(businessId) {
    const response = await client.get(`/businesses/${businessId}/copy`, this.getToken());

    if (this.$lodash.isEmpty(response.data)) {
      return response;
    }

    return this.successResponse(this.mapData(response.data));
  }

  async statusPayout(businessId, payoutStatus) {
    const response = await client.patch(
      "/businesses/" + businessId + "/update-payout-type",
      {
        payout_type: payoutStatus,
      },
      this.getToken()
    );

    if (this.$lodash.isEmpty(response.data)) {
      return response;
    }

    return this.successResponse(response.data);
  }

  async getAllBusiness(params = {}) {
    const response = await client.get(
      "/admin/all-business",
      this.getToken(),
      params
    );

    if (this.$lodash.isEmpty(response.data)) {
      return response;
    }

    return this.successResponse(response.data);
  }

  mapData(business) {
    var populateBusiness = business;

    populateBusiness.country.icon = business.country?.flag;
    populateBusiness.type_of_business.name =
      business.type_of_business.description;

    if (populateBusiness.detail?.company_annual_turnover) {
      populateBusiness.detail.company_annual_turnover.name =
        business.detail.company_annual_turnover?.description;
    }

    if (populateBusiness.bank?.bank) {
      populateBusiness.bank.bank.name = business.bank.bank.full_name;
      populateBusiness.bank.bank.icon = business.bank.bank.logo_url;
    }

    return populateBusiness;
  }

  async createOrUpdateBusinessOwner(businessId, businessOwner) {
    const response =
      businessOwner.id == null
        ? await client.post(
          "/businesses/" + businessId + "/owners",
          businessOwner,
          this.getToken()
        )
        : await client.put(
          "/businesses/" + businessId + "/owners/" + businessOwner.id,
          businessOwner,
          this.getToken()
        );

    if (this.$lodash.isEmpty(response.data)) {
      return response;
    }

    return response.data;
  }

  async getBusinessOwnersByBusinessId(businessId) {
    const response = await client.get(
      "/businesses/" + businessId + "/owners",
      this.getToken()
    );

    if (this.$lodash.isEmpty(response.data)) {
      return response;
    }

    return response.data;
  }

  async deleteBusinessOwner(businessId, businessOwnerId) {
    const response = await client.delete(
      "/businesses/" + businessId + "/owners/" + businessOwnerId,
      this.getToken()
    );

    if (this.$lodash.isEmpty(response.data)) {
      return response;
    }

    return response.data;
  }

  async ownerDocuments(business_id) {
    const response = await client.get(
      `/businesses/${business_id}/owners/ic-documents`,
      this.getToken()
    );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async ownerDocumentsType(business_id) {
    const response = await client.get(
      `/businesses/${business_id}/owners/ic-documents-types`,
      this.getToken()
    );

    if (response.data == null) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async getSalesListBusinessExports(queryParams) {
    const response = await client.get(
      "sales/businesses/export" + queryParams,
      this.getToken()
    );

    if (this.$lodash.isEmpty(response.data)) {
      return response;
    }

    return this.successResponse(response.data);
  }

  async createOrUpdatePackage(business_id, businessPackage) {

    const response = await client.patch(
      "/businesses/" + business_id + "/packages",
      businessPackage,
      this.getToken()
    );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(this.mapData(response.data));
  }
}

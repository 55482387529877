<template>
  <div class="flex" v-for="(item, idx) in items" :key="idx">
    <div class="flex flex-col items-center mr-2">
      <div>
        <slot name="item-icon" v-bind="item" />
      </div>
      <div v-if="idx < items.length - 1" class="w-px h-full my-1 border border-dotted" />
    </div>
    <div class="pb-5 space-y-2">
      <p class="text-slightly-sm">
        <slot name="item-title" v-bind="item" />
      </p>
      <p class="text-xs text-gray-800">
        <slot name="item-content" v-bind="item" />
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
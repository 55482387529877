<template>
  <div>
    <div @click="isExpanded = !isExpanded">
      <slot name="head" :expanded="isExpanded" />
    </div>
    <div v-if="isExpanded">
      <slot name="body" :expanded="isExpanded" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isExpanded: false,
    };
  },
  props: {
    expanded: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.isExpanded = this.expanded;
  },
};
</script>
const colors = require('tailwindcss/colors')
const path = require('path');

module.exports = {
  purge: [
    './src/**/*.vue',
    path.resolve(__dirname, './node_modules/litepie-datepicker/**/*.js'),
  ],
  darkMode: false,
  theme: {
    fontSize: {
      'xxs': '.50rem',
      'xs': '.75rem',
      'slightly-xs': '.812rem',
      'sm': '.875rem',
      'slightly-sm': '.94rem',
      'base': '1rem',
      'lg': '1.125rem',
      'xl': '1.25rem',
      '2xl': '1.5rem',
      '3xl': '1.875rem',
      '4xl': '2.25rem',
      '5xl': '3rem',
      '6xl': '4rem',
      '7xl': '5rem',
    },
    extend: {
      spacing: {
        '22': '5.5rem'
      },
      height: {
        '34': '8.438rem',
        '26': '6.25rem',
        '90': '22rem',
      },
      width: {
        '26': '6.25rem',
        '90': '22rem',
      },
      fontFamily: {
        'qanelas': ['Qanelas Soft', 'Arial'],
      },
      colors: {
        'primary': '#623F99',
        'primary-100': '#715698',
        'primary-200': '#6d5098',
        'primary-300': '#684a96',
        'primary-400': '#654598',
        'primary-500': '#623F99',
        'primary-600': '#5f399a',
        'primary-700': '#5a319a',
        'primary-800': '#542998',
        'primary-900': '#4d1f94',

        'background': '#FAFAFA',
        'test-mode': '#9A6AE7',

        'error': '#C91C31',
        'success': '#55BE64',
        'bordercolor': '#D4D4D8',
        'hover-button': '#7340AA',
        'click-button': '#512E88',

        'success-badge': "#F2FFF4",
        'error-badge': "#FFEEF0",
        'warning-badge': "#FFF2DD",
        'light-badge': "#F5F5F5",

        'white': colors.white,
        'blue': colors.blue,
        'gray': colors.gray,
        'yellow': colors.yellow,
        'red': colors.red,
        'bluelink': '#5c8de1',
        'divider-gray': '#E5E7EB',

        'approve': '#16A34A',
        'decline': '#B91C1C',

        'litepie-primary': {
          100: '#715698',
          200: '#6d5098',
          300: '#684a96',
          400: '#654598',
          500: '#623F99',
          600: '#5f399a',
          700: '#5a319a',
          800: '#542998',
          900: '#4d1f94',
        }, // color system for light mode
        'litepie-secondary': colors.gray // color system for dark mode
      },
      backgroundImage: theme => ({
        'app-logo': "url('../image/bg_app_logo.svg')",
      })
    },
  },
  variants: {
    extend: {
      opacity: ['disabled'],
      backgroundColor: ['active'],
      padding: ['hover'],
      cursor: ['disabled'],
      textOpacity: ['disabled'],
      textColor: ['disabled']
    },
  },
  plugins: [require('@tailwindcss/forms')],
}

<template>
  <div class="flex flex-row items-center">
    <p for="toggle" class="text-sm toggle-label">
      <slot />
    </p>

    <div v-if="loading" class="px-2">
      <svg
        viewBox="0 0 24 24"
        class="animate-spin mt-0.5 -mr-1 ml-3 h-5 w-5 text-primary"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
      >
        <circle
          class="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          stroke-width="4"
        ></circle>

        <path
          class="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    </div>

    <div
      class="
        relative
        inline-block
        w-9
        ml-2
        align-middle
        select-none
        transition
        duration-200
        ease-in
      "
      v-else
    >
      <input
        type="checkbox"
        :id="id"
        :name="name"
        v-model="checkedValue"
        @click="onClick"
        @change="onChange"
        class="
          toggle-checkbox
          absolute
          block
          w-5
          h-5
          rounded-full
          border
          cursor-pointer
          focus:ring-inset focus:ring-white
          border-gray-300
          bg-white
        "
      />
      <label
        for="toggle"
        class="toggle-label block overflow-hidden h-5 rounded-full bg-gray-300"
      >
      </label>
    </div>
    <span class="ml-2">{{ label }}</span>

    <app-modal
      v-if="confirmModal != null"
      :show="showModal"
      @close="showModal = false"
    >
      <template v-slot:title>{{
        checkedValue
          ? confirmModal.negative_title
          : confirmModal.positive_title
      }}</template>
      <template v-slot:body>
        <div class="flex flex-col items-start">
          <p class="text-sm text-gray-500">
            {{
              checkedValue
                ? confirmModal.negative_desc
                : confirmModal.positive_desc
            }}
          </p>
          <div v-if="showSkipModal" class="w-full flex flex-row items-center content-center mt-4">
            <app-form-checkbox v-model="skipModal" @change="onSkipPromptChange" />
            <span class="mt-1.5 text-gray-500">{{ confirmModal.skip_prompt }}</span>
          </div>
        </div>
      </template>
      <template v-slot:action>
        <app-button
          @onClick="proceedAction()"
          :showf70Icon="false"
          :primary="false"
          type="button"
          class="sm:ml-3 sm:w-auto text-white outline-none"
          :class="[
            checkedValue
              ? 'bg-decline hover:bg-error'
              : 'bg-approve hover:bg-success',
          ]"
        >
          {{
            checkedValue
              ? confirmModal.negative_action
              : confirmModal.positive_action
          }}
        </app-button>
      </template>
    </app-modal>
  </div>
</template>


<script>
export default {
  props: {
    id: {
      type: String,
    },
    name: {
      type: String,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    confirmModal: {
      type: Object,
      default: null,
    },
    showSkipModal: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showModal: false
    };
  },
  computed: {
    checkedValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    skipModal() {
      if (this.confirmModal?.skip_modal_store != null) {
        return this.$store.getters[this.confirmModal?.skip_modal_store];
      }

      return false;
    }
  },
  methods: {
    onClick(event) {
      // if got modal, show modal before proceed action
      if (this.confirmModal != null && !this.skipModal) {
        this.showModal = !this.showModal;
        event.preventDefault();
        return;
      }

      this.proceedAction();
    },
    proceedAction() {
      this.checkedValue = !this.checkedValue;
      this.showModal = false;
      this.$emit("onChange", {
        value: !this.checkedValue,
        id: this.id,
      });
    },
    onSkipPromptChange(event) {
      if (this.confirmModal?.skip_modal_update_store != null) {
        return this.$store.commit(this.confirmModal?.skip_modal_update_store, event.target.checked);
      }
    }
  },
};
</script>

<style>
.toggle-checkbox:checked {
  @apply border-gray-300 bg-white hover:border-gray-300 hover:bg-white focus:bg-white focus:border-gray-300;
  right: 0;
}
.toggle-checkbox:checked + .toggle-label {
  @apply text-test-mode bg-primary;
}
</style>
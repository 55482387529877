import BaseDao from "./base-dao";

export default class AuthDao extends BaseDao {
    constructor() {
        super();
        this.key = "auth";
    }

    saveAuth(authData) {
        const expiryDate = authData.remember_me ? 604800000 : 86400000;

        this.saveData(this.key + ".token", authData.token, expiryDate);
        this.saveData(this.key + ".user", authData.user, expiryDate);
    }

    removeAuth() {
        this.saveData(this.key + ".token", null);
        this.saveData(this.key + ".user", null);
        this.saveData(this.key + ".selectedRole", null);
    }

    retrieveUser() {
        return this.getData(this.key + ".user");
    }

    retrieveToken() {
        return this.getData(this.key + ".token");
    }

    saveSelectedRole(selectedRole) {
        this.saveData(this.key + ".selectedRole", selectedRole);
    }

    retrieveSelectedRole() {
        return this.getData(this.key + ".selectedRole");
    }
}
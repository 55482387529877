<template>
  <div
    class="
      p-3
      flex
      space-x-1
      rounded-md
      font-medium
      items-center
      bg-red-100
      justify-start
      text-sm text-error
    "
    v-if="message"
  >
    <app-icon
      name="ExclamationIcon"
      class="flex-none h-4 w-4 text-error mr-2"
    />

    <span>
      {{
        message === "$general.message"
          ? $t("general.unable_to_reach_server")
          : message
      }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
    },
  },
};
</script>
import baseStore from "../base-store";
import ReferenceRepository from "../../data/repository/reference-repository";

export default {
  namespaced: true,
  state: {
    ...baseStore.state,
    countries: [],
    states: [],
    packages: [],
    typeOfBusinesses: [],
    industries: [],
    companyAnnualTurnovers: [],
    selectedCountryId: null,
    banks: [],
    roles: [],
    paymentMethods: [],
    paymentGateways: [],
    paymentGatewayMethods: [],
    paymentGatewayMethodChannels: [],
    paymentGatewayApplicationStatuses: [],
  },

  getters: {
    ...baseStore.getters,

    countries(state) {
      return state.countries;
    },

    states(state) {
      return state.states;
    },

    packages(state) {
      return state.packages;
    },

    typeOfBusinesses(state) {
      return state.typeOfBusinesses;
    },

    industries(state) {
      return state.industries;
    },

    companyAnnualTurnovers(state) {
      return state.companyAnnualTurnovers;
    },

    selectedCountryId(state) {
      return state.selectedCountryId;
    },

    banks(state) {
      return state.banks;
    },

    paymentMethods(state) {
      return state.paymentMethods;
    },

    paymentGateways(state) {
      return state.paymentGateways;
    },

    paymentGatewayMethods(state) {
      return state.paymentGatewayMethods;
    },

    paymentGatewayMethodChannels(state) {
      return state.paymentGatewayMethodChannels;
    },

    paymentGatewayApplicationStatuses(state) {
      return state.paymentGatewayApplicationStatuses;
    },

    roles(state) {
      return state.roles;
    },
  },

  mutations: {
    ...baseStore.mutations,

    setCountries(state, countries) {
      state.countries = countries;
    },

    setStates(state, states) {
      state.states = states;
    },

    setPackages(state, packages) {
      state.packages = packages;
    },

    setTypeOfBusinesses(state, typeOfBusinesses) {
      state.typeOfBusinesses = typeOfBusinesses;
    },

    setIndustries(state, industries) {
      state.industries = industries;
    },

    setCompanyAnnualTurnover(state, companyAnnualTurnovers) {
      state.companyAnnualTurnovers = companyAnnualTurnovers;
    },

    setSelectedCountryId(state, selectedCountryId) {
      state.selectedCountryId = selectedCountryId;
    },

    setBanks(state, banks) {
      state.banks = banks;
    },

    setPaymentGateways(state, paymentGateways) {
      state.paymentGateways = paymentGateways;
    },

    setPaymentMethods(state, paymentMethods) {
      state.paymentMethods = paymentMethods;
    },

    setPaymentGatewayMethods(state, paymentGatewayMethods) {
      state.paymentGatewayMethods = paymentGatewayMethods;
    },

    setPaymentGatewayMethodChannels(state, paymentGatewayMethodChannels) {
      state.paymentGatewayMethodChannels = paymentGatewayMethodChannels;
    },

    setPaymentGatewayApplicationStatuses(
      state,
      paymentGatewayApplicationStatuses
    ) {
      state.paymentGatewayApplicationStatuses =
        paymentGatewayApplicationStatuses;
    },

    setRoles(state, roles) {
      state.roles = roles;
    },
  },

  actions: {
    async getCountries(state) {
      const countryResult = await new ReferenceRepository().getCountries();

      if (countryResult.message != null) {
        state.commit("setErrorMessage", countryResult);
        return null;
      }

      const countries = [];
      countryResult.data?.forEach((country) => {
        countries.push({
          id: country.id,
          name: country.name,
          icon: country.flag,
        });
      });

      state.commit("setLoading", false);
      state.commit("setCountries", countries);
      return countries;
    },

    async getStates(state, countryId) {
      if (countryId == null) {
        state.commit("setLoading", false);
        return null;
      }

      const params = new URLSearchParams();
      params.append("filter[country_id]", countryId);

      const stateResult = await new ReferenceRepository().getStates(params);

      if (stateResult.message != null) {
        state.commit("setErrorMessage", stateResult);
        return null;
      }

      state.commit("setLoading", false);
      state.commit("setStates", stateResult.data);
      return stateResult.data;
    },

    async getPackages(state) {
      const packageResult = await new ReferenceRepository().getPackages();

      if (packageResult.message != null) {
        state.commit("setErrorMessage", packageResult);
        return null;
      }

      state.commit("setLoading", false);
      state.commit("setPackages", packageResult.data);
      return packageResult.data;
    },

    async getTypeOfBusinesses(state, countryId) {
      if (countryId == null) {
        state.commit("setLoading", false);
        return null;
      }

      let params = {};

      if (countryId != 0) {
        params = new URLSearchParams();
        params.append("filter[country_id]", countryId);
      }

      const typeOfBusinessResult =
        await new ReferenceRepository().getTypeOfBusinesses(params);

      if (typeOfBusinessResult.message != null) {
        state.commit("setErrorMessage", typeOfBusinessResult);
        return null;
      }

      const typeOfBusinesses = [];
      typeOfBusinessResult.data?.forEach((typeOfBusiness) => {
        typeOfBusinesses.push({
          id: typeOfBusiness.id,
          name: typeOfBusiness.description,
        });
      });

      state.commit("setLoading", false);
      state.commit("setTypeOfBusinesses", typeOfBusinesses);
      return typeOfBusinessResult.data;
    },

    async getCompanyAnnualTurnovers(state, countryId) {
      if (countryId == null) {
        state.commit("setLoading", false);
        return null;
      }

      let params = {};

      if (countryId != 0) {
        params = new URLSearchParams();
        params.append("filter[country_id]", countryId);
      }

      const companyAnnualTurnoverResult =
        await new ReferenceRepository().getCompanyAnnualTurnover(params);

      if (companyAnnualTurnoverResult.message != null) {
        state.commit("setErrorMessage", companyAnnualTurnoverResult);
        return null;
      }

      const companyAnnualTurnovers = [];
      companyAnnualTurnoverResult.data?.forEach((companyAnnualTurnover) => {
        companyAnnualTurnovers.push({
          id: companyAnnualTurnover.id,
          name: companyAnnualTurnover.description,
        });
      });

      state.commit("setLoading", false);
      state.commit("setCompanyAnnualTurnover", companyAnnualTurnovers);
      return companyAnnualTurnoverResult.data;
    },

    async getBanks(state, countryId) {
      if (countryId == null) {
        state.commit("setLoading", false);
        return null;
      }

      let params = {};

      if (countryId != 0) {
        params = new URLSearchParams();
        params.append("filter[country_id]", countryId);
      }

      const businessBankResult = await new ReferenceRepository().getBanks(
        params
      );

      if (businessBankResult.message != null) {
        state.commit("setErrorMessage", businessBankResult);
        return null;
      }
      const businessBank = [];
      businessBankResult.data?.forEach((bank) => {
        businessBank.push({
          id: bank.id,
          name: bank.full_name,
          icon: bank.logo_url,
        });
      });

      state.commit("setLoading", false);
      state.commit("setBanks", businessBank);
      return businessBankResult.data;
    },

    async getPaymentMethods(state) {
      const paymentMethodsResult = await new ReferenceRepository().getPaymentMethods();

      if (paymentMethodsResult.message != null) {
        state.commit("setErrorMessage", paymentMethodsResult);
      } else {
        state.commit("setPaymentMethods", paymentMethodsResult.data);
      }

      state.commit("setLoading", false);
      return paymentMethodsResult.data;
    },

    async getPaymentGateways(state) {
      const paymentGatewayResult =
        await new ReferenceRepository().getPaymentGateways();

      if (paymentGatewayResult.message != null) {
        state.commit("setErrorMessage", paymentGatewayResult);
        return null;
      }

      const paymentGateways = [];

      paymentGatewayResult.data?.forEach((paymentGateway) => {
        paymentGateways.push({
          id: paymentGateway.id,
          name: paymentGateway.name,
        });
      });

      state.commit("setLoading", false);
      state.commit("setPaymentGateways", paymentGateways);
      return paymentGatewayResult.data;
    },

    async getPaymentGatewayMethods(state, payment_gateway_id) {
      let params = new URLSearchParams();
      params.append("include", "paymentMethod");
      params.append("filter[payment_gateway_id]", payment_gateway_id ?? 0);

      const paymentGatewayMethodResult =
        await new ReferenceRepository().getPaymentGatewayMethods(params);

      if (paymentGatewayMethodResult.message != null) {
        state.commit("setErrorMessage", paymentGatewayMethodResult);
        return null;
      }

      state.commit("setLoading", false);
      state.commit("setPaymentGatewayMethods", paymentGatewayMethodResult.data);
      return paymentGatewayMethodResult.data;
    },

    async getPaymentGatewayMethodChannels(state, payment_method_id) {
      let params = new URLSearchParams();
      params.append("include", "paymentMethod");
      params.append("filter[payment_method_id]", payment_method_id ?? 0);

      const paymentGatewayMethodResult =
        await new ReferenceRepository().getPaymentGatewayMethods(params);

      if (paymentGatewayMethodResult.message != null) {
        state.commit("setErrorMessage", paymentGatewayMethodResult);
        return null;
      }

      state.commit("setLoading", false);
      state.commit(
        "setPaymentGatewayMethodChannels",
        paymentGatewayMethodResult.data
      );
      return paymentGatewayMethodResult.data;
    },

    async getPaymentGatewayApplicationStatuses(state) {
      const paymentGatewayApplicationStatusesResult =
        await new ReferenceRepository().getPaymentGatewayApplicationStatuses();

      if (paymentGatewayApplicationStatusesResult.message != null) {
        state.commit(
          "setErrorMessage",
          paymentGatewayApplicationStatusesResult
        );
        return null;
      }

      const paymentGatewayApplicationStatuses = [];

      paymentGatewayApplicationStatusesResult.data?.forEach(
        (paymentGatewayApplicationStatus) => {
          paymentGatewayApplicationStatuses.push({
            id: paymentGatewayApplicationStatus.id,
            name: paymentGatewayApplicationStatus.name,
          });
        }
      );

      state.commit("setLoading", false);
      state.commit(
        "setPaymentGatewayApplicationStatuses",
        paymentGatewayApplicationStatuses
      );
      return paymentGatewayApplicationStatusesResult.data;
    },

    async getIndustries(state, countryId) {
      if (countryId == null) {
        state.commit("setLoading", false);
        return null;
      }

      let params = {};

      if (countryId != 0) {
        params = new URLSearchParams();
        params.append("filter[country_id]", countryId);
      }

      const industriesResult = await new ReferenceRepository().getIndustries(
        params
      );

      if (industriesResult.message != null) {
        state.commit("setErrorMessage", industriesResult);
        return null;
      }

      state.commit("setLoading", false);
      state.commit("setIndustries", industriesResult.data);
      return industriesResult.data;
    },

    async getRoles(state) {
      const rolesResult = await new ReferenceRepository().getRoles();

      if (rolesResult.message != null) {
        state.commit("setErrorMessage", rolesResult);
        return null;
      }

      state.commit("setLoading", false);
      state.commit("setRoles", rolesResult.data);
      return rolesResult.data;
    },
  },
};

<template>
  <app-modal
    class="rounded-md"
    colorClose="text-white hover:text-gray-200"
    :showCancelButton="false"
    :overflow_hide="true"
    paddingBody="p-0"
    padding="p-0"
  >
    <template v-slot:foreground>
      <svg
        viewBox="0 0 486 157"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="absolute w-full"
      >
        <ellipse
          cx="242.858"
          cy="-63"
          rx="317.858"
          ry="220"
          fill="url(#paint0_linear_5609_82745)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_5609_82745"
            x1="242.858"
            y1="-219.949"
            x2="242.859"
            y2="157"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#623F99" />
            <stop offset="1" stop-color="#6A62A9" />
          </linearGradient>
        </defs>
      </svg>
    </template>
    <template v-slot:body>
      <div ref="capture" class="bg-gray-100">
        <div class="z-10">
          <svg
            viewBox="0 0 486 157"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="absolute w-full"
          >
            <ellipse
              cx="242.858"
              cy="-63"
              rx="317.858"
              ry="220"
              fill="url(#paint0_linear_5609_82745)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_5609_82745"
                x1="242.858"
                y1="-219.949"
                x2="242.859"
                y2="157"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#623F99" />
                <stop offset="1" stop-color="#6A62A9" />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <div class="relative flex flex-col mx-auto space-y-5 z-30 pt-22 p-10">
          <div class="flex justify-center" v-if="businessLogo">
            <div id="crop-logo">
              <img :src="businessLogo" />
            </div>
          </div>

          <div class="flex justify-center">
            <div
              class="
                flex flex-col
                items-center
                w-min
                border
                rounded-md
                py-4
                px-10
                space-y-4
                text-lg
                bg-white
              "
            >
              <p class="text-md text-center font-medium text-gray-700">
                {{ business.name ?? "-" }}
              </p>
              <div>
                <qrcode-vue
                  :value="model.payment_url"
                  :size="size"
                  level="L"
                  foreground="#623F99"
                />
              </div>
              <div class="text-lg font-semibold text-gray-700">
                {{ $t("payment.open-payment-link.qrcode-page.scan_to_pay") }}
              </div>
            </div>
          </div>

          <!-- Logo powered by swipe -->
          <div class="flex justify-center mx-auto w-32">
            <app-logo-powered-by-swipe-dark />
          </div>
        </div>
      </div>
    </template>

    <template v-slot:action>
      <div class="flex flex-row justify-end space-x-2">
        <div>
          <app-button-outline
            class="bg-white"
            padding="px-4 py-2"
            @click="copyUrl(model)"
          >
            <p class="text-xs antialiased">
              {{ $t("payment.detail.copy_link") }}
            </p>
          </app-button-outline>
        </div>
        <div>
          <app-button-outline
            class="bg-approve hover:bg-success"
            padding="px-4 py-2"
            :loading="loading"
            @click="onCapture"
            loadingColor="text-white"
          >
            <p class="text-white text-xs antialiased">
              {{ $t("payment.open-payment-link.qrcode-page.download_image") }}
            </p>
          </app-button-outline>
        </div>
      </div>
    </template>
  </app-modal>
</template>

<script>
import QrcodeVue from "qrcode.vue";
import MEDIA_CATEGORY from "@/utils/const/media_category";
import html2canvas from "html2canvas";

export default {
  data() {
    return {
      size: 200,
      image: null,
      loading: false,
    };
  },

  props: {
    model: {
      type: Object,
      default: null,
    },
  },

  components: {
    QrcodeVue,
  },
  computed: {
    business() {
      return this.$store.getters["businessStore/business"];
    },
    businessLogo() {
      return this.business?.media?.find(
        (media) => media.collection_name == MEDIA_CATEGORY.LOGO
      )?.original_url;
    },
  },

  methods: {
    async onCapture() {
      this.loading = true;
      const self = this;

      setTimeout(() => {
        self.downloadImage();
      }, 500);
    },
    async downloadImage() {
      await html2canvas(this.$refs.capture, {
        proxy: this.businessLogo
      })
        .then((canvas) => {
          const image = canvas
            .toDataURL("image/jpeg")
            .replace("image/jpeg", "image/octet-stream");

          const a = document.createElement("a");
          a.setAttribute("download", "download-qr.png");
          a.setAttribute("href", image);
          a.click();

          canvas.remove();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    copyUrl(model) {
      const clipboardData =
        event.clipboardData ||
        window.clipboardData ||
        event.originalEvent?.clipboardData ||
        navigator.clipboard;

      clipboardData.writeText(model.payment_url);

      this.$notify(
        {
          group: "success",
          title: this.$t("payment.link.copy_link"),
        },
        5000
      );
    },
  },
};
</script>

<style scoped>
#crop-logo {
  width: 123px;
  height: 123px;
  overflow: hidden;
  position: relative;
  border: 4px solid #fff;
  border-radius: 100%;
  background: #f5f5f5;
}

#crop-logo img {
  position: absolute;
  min-width: 203px;
  height: 114px;
  left: -44px;
  top: 1px;
}
</style>
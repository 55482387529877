<template>
  <svg
    class="w-4 h-4 cursor-pointer"
    :class="$attrs.class"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.03863 12.1021C4.72208 8.82689 7.57391 6.5 10.7796 6.5C13.9852 6.5 16.837 8.82688 17.5205 12.1021C17.5769 12.3725 17.8418 12.5459 18.1121 12.4895C18.3824 12.433 18.5558 12.1682 18.4994 11.8979C17.7221 8.17312 14.473 5.5 10.7796 5.5C7.08614 5.5 3.83696 8.17311 3.05972 11.8979C3.00331 12.1682 3.17672 12.433 3.44704 12.4895C3.71736 12.5459 3.98222 12.3725 4.03863 12.1021ZM10.7691 8.5C12.7021 8.5 14.2691 10.067 14.2691 12C14.2691 13.933 12.7021 15.5 10.7691 15.5C8.83607 15.5 7.26907 13.933 7.26907 12C7.26907 10.067 8.83607 8.5 10.7691 8.5Z"
      fill="#313335"
    />
  </svg>
</template>
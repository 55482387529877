<template>
  <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
    <app-form-selection-box
      v-for="paymentMethod in paymentMethods"
      :key="paymentMethod.id"
      :active="activeSelected(paymentMethod)"
      @click="selectPaymentMethod(paymentMethod)"
      :class="{ 'border-primary': activeSelected(paymentMethod) }"
    >
      <img
        class="object-contain w-full"
        :class="[$attrs.class ? $attrs.class : 'h-12']"
        alt="logo"
        :src="paymentMethod?.image_url"
      />
      <p>{{ paymentMethod?.name ?? "" }}</p>
    </app-form-selection-box>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Object,
      default: () => {},
    },
    filterPaymentMethods: {
      type: Array,
      default: () => [],
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    paymentMethods(paymentMethods) {
      if (this.$lodash.isEmpty(paymentMethods)) {
        this.onValueSelected(null);
      }
    },
  },
  computed: {
    checkedValue: {
      get() {
        if (this.$lodash.isEmpty(this.paymentMethods)) {
          return {};
        }
        return this.modelValue ?? [];
      },
      set(value) {
        this.onValueSelected(value);
      },
    },

    paymentMethods() {
      let paymentMethods = this.$store.getters["referenceStore/paymentMethods"];

      if (this.filterPaymentMethods.length > 0) {
        return paymentMethods.filter((paymentMethod) => {
          if (this.filterPaymentMethods.includes(paymentMethod.id)) {
            return paymentMethod;
          }
        });
      }

      return paymentMethods;
    },

    loading() {
      return this.$store.getters["referenceStore/loading"];
    },

    serverMessage() {
      return this.$store.getters["referenceStore/message"] ?? "";
    },
  },

  async mounted() {
    this.getPaymentMethods();
  },

  data() {
    return {
      multipleSelect: []
    }
  },

  methods: {
    async getPaymentMethods() {
      this.$store.commit("referenceStore/resetState");
      this.$store.dispatch("referenceStore/getPaymentMethods");
    },

    onValueSelected(value) {
      this.$emit("update:modelValue", value);
    },

    selectPaymentMethod(value) {
      if(this.multiple) {
        if(this.multipleSelect.includes(value)) {
          this.multipleSelect = this.multipleSelect.filter(item => item !== value);
        } else {
          this.multipleSelect.push(value);
        }
        this.onValueSelected(this.multipleSelect);
      } else {
        this.onValueSelected(value);
      }
    },
    activeSelected(paymentMethod){
      if(this.multiple) {
        return this.multipleSelect.includes(paymentMethod);
      }
      return paymentMethod.id === this.checkedValue?.id
    }
  },
};
</script>



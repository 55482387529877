export default Object.freeze({
  DOCUMENT_IC_COPY: "document_ic_copy",
  DOCUMENT_IC_COPY_BACK: "document_ic_copy_back",
  DOCUMENT_COMPANY_BANK_STATEMENT: "document_company_bank_statement",
  DOCUMENT_SSM_LLP_CERT: "document_ssm_llp_cert",
  DOCUMENT_SSM_FORM_A: "document_ssm_form_a",
  DOCUMENT_SSM_FORM_B: "document_ssm_form_b",
  DOCUMENT_SSM_FORM_D: "document_ssm_form_d",
  DOCUMENT_SSM_FORM_24: "document_ssm_form_24",
  DOCUMENT_SSM_FORM_49: "document_ssm_form_49",
  DOCUMENT_SSM_FORM_44: "document_ssm_form_44",
  DOCUMENT_SSM_FORM_M_A: "document_ssm_form_m_a",
  DOCUMENT_BUSINESS_REGISTRATION: "document_business_registration",
  DOCUMENT_LOCAL_COUNCIL: "document_local_council",
  UTILITY_BILLS: "utility_bills",
  PHOTO_SIGNBOARD: "photo_signboard",
  PHOTO_PREMISES: "photo_premises",
  PHOTO_DISPLAY_OF_PRODUCT: "photo_display_of_product",
  PHOTO_EQUIPMENT: "photo_equipment",
  DIRECTOR_SIGNATURE: "director_signature",
  COMPANY_STAMP: "company_stamp",
  COMPLETE_SSM: "complete_ssm",
  DOCUMENT_REPRESENTATIVE_IC_COPY: "document_representative_ic_copy",
  DOCUMENT_REPRESENTATIVE_IC_COPY_BACK: "document_representative_ic_copy_back"
});

<template>
  <div class="flex flex-col" :class="$attrs.class">
    <label
      v-if="showlabel"
      :for="name"
      class="block text-sm font-light text-gray-700"
    >
      {{ labelText }}
    </label>
    <div :class="[ showlabel ? 'mt-2' : '']">
      <input
        :class="[
          $attrs.inputclass,
          rounded ? 'rounded-md' : '',
          'w-full h-10 shadow-sm text-sm border-1 focus:ring-primary border-none ring-1 focus:ring-2 focus:outline-none',
          errorMessage.length > 0 ? 'ring-error' : 'ring-bordercolor',
        ]"
        :id="id"
        :name="name"
        :type="type"
        :autocomplete="autocomplete"
        :disabled="loading"
        :required="required"
        :placeholder="placeholder"
        :value="modelValue"
        v-mask="mask"
        @input="$emit('update:modelValue', $event.target.value)"
      />
      <span v-if="errorMessage.length > 0" class="text-xs text-error">{{
        errorMessage[0]
      }}</span>
    </div>
  </div>
</template>

<script>
import {mask} from 'vue-the-mask';

export default {
  directives: {mask},

  props: {
    id: {
      type: String,
    },
    name: {
      type: String,
    },
    type: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    autocomplete: {
      type: String,
    },
    labelText: {
      type: String,
      default: "",
    },
    showlabel: {
      type: Boolean,
      default: true,
    },
    modelValue: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: true,
    },
    mask: {
      type: String,
      default: '?'
    },
    errorMessage: {
      type: Array,
      default: () => [],
    },
  },
};
</script>



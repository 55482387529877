import client from "@/data/base-client";
import WithAuthRepository from "./with-auth-repository";

export default class BusinessSupportDocumentsRepository extends WithAuthRepository {
  constructor() {
    super();
  }

  async createOrUpdate(business_id, documents) {
    const response = await client.post(
      `/businesses/${business_id}/support-documents`,
      documents,
      this.getToken()
    );
    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async index(business_id) {
    const response = await client.get(
      `/businesses/${business_id}/support-documents`,
      this.getToken()
    );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }
  async delete(business_id, support_document_id) {
    const response = await client.delete(
      `/businesses/${business_id}/support-documents/${support_document_id}`,
      this.getToken()
    );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async validate(business_id) {
    const response = await client.get(
      `/businesses/${business_id}/validate-support-documents`,
      this.getToken()
    );

    if (response.data == null) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async type(business_id) {
    const response = await client.get(
      `/businesses/${business_id}/type-support-documents`,
      this.getToken()
    );

    if (response.data == null) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  // master business

  async createOrUpdateMasterBusiness(masterBusinessId, business_id, documents) {
    const response = await client.post(
      `/master-businesses/${masterBusinessId}/businesses/${business_id}/support-documents`,
      documents,
      this.getToken()
    );
    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async indexMasterBusiness(masterBusinessId, business_id) {
    const response = await client.get(
      `/master-businesses/${masterBusinessId}/businesses/${business_id}/support-documents`,
      this.getToken()
    );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }
  async deleteMasterBusiness(
    masterBusinessId,
    business_id,
    support_document_id
  ) {
    const response = await client.delete(
      `/master-businesses/${masterBusinessId}/businesses/${business_id}/support-documents/${support_document_id}`,
      this.getToken()
    );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async validateMasterBusiness(masterBusinessId, business_id) {
    const response = await client.get(
      `/master-businesses/${masterBusinessId}/businesses/${business_id}/validate-support-documents`,
      this.getToken()
    );

    if (response.data == null) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async typeMasterBusiness(masterBusinessId, business_id) {
    const response = await client.get(
      `/master-businesses/${masterBusinessId}/businesses/${business_id}/type-support-documents`,
      this.getToken()
    );

    if (response.data == null) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }
}

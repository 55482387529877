import AuthRepository from "../../data/repository/auth-repository";
import { logEvent } from "firebase/analytics";
import baseStore from "../base-store";
import ProductRepository from "../../data/repository/product-repository";

const initialProduct = {
  name: null,
  currency: 'MYR',
  price: null,
  formatted_price: null,
  unit_label: null,
};

const initialUnitLabel = [];

export default {
  namespaced: true,
  state: {
    ...baseStore.state,
    product: initialProduct,
    loadingUnitLabel: false,
    unitLabel: initialUnitLabel, 
  },

  getters: {
    ...baseStore.getters,

    product(state) {
      return state.product;
    },

    unitLabel(state) {
      return state.unitLabel;
    },

    loadingUnitLabel(state){
      return state.loadingUnitLabel;
    }
  },

  mutations: {
    ...baseStore.mutations,

    setProduct(state, product) {
      return state.product = product;
    },

    setUnitLabel(state, unitLabel) {
      return state.unitLabel = unitLabel;
    },

    setLoadingUnitLabel(state, loadingUnitLabel) {
      state.loadingUnitLabel = loadingUnitLabel;
    },

    clearProduct(state) {
      state.product = this.$lodash.clone(initialProduct);
    }
  },

  actions: {
    async retrieveProduct(state, queryParams) {
      const productRepository = new ProductRepository();
      const authRepository = new AuthRepository();

      const business_id = authRepository.getSelectedRole()?.business_id;

      if (business_id == null) {
        return;
      }

      state.commit("setApiPaginationLoading", true);
      const productIndexResult = await productRepository.indexProduct(
        business_id,
        queryParams
      );

      if (productIndexResult.message != undefined) {
        logEvent(this.$analytics, "product-index", { result: "failed" });
        state.commit("setErrorMessage", productIndexResult);
        return;
      } else {
        logEvent(this.$analytics, "product-index", { result: "success" });
      }

      state.commit("setApiPaginationLoading", false);
      state.commit("setApiPaginationResponse", productIndexResult);
      return productIndexResult;
    },
  
    async createOrUpdateProduct(state, product) {
      const productRepository = new ProductRepository();
      const authRepository = new AuthRepository();

      const business_id = authRepository.getSelectedRole()?.business_id;

      if (business_id == null) {
        return;
      }

      state.commit("setLoading", true);

      Object.keys(product).forEach((key) => {
        if (product[key] === "") {
          delete product[key];
        }
      });

      const productResult = await productRepository.createOrUpdateProduct(
        business_id,
        product
      );

      const isCreate = product._id == null;

      if (productResult.message != undefined) {
        logEvent(
          this.$analytics,
          isCreate ? "create-product" : "update-product",
          { result: "failed" }
        );
        state.commit("setErrorMessage", productResult);
        return;
      } else {
        logEvent(
          this.$analytics,
          isCreate ? "create-product" : "update-product",
          { result: "success" }
        );
      }

      state.commit("setErrorMessage");
      state.commit("setLoading", false);

      return productResult;
    },

    async getProductById(state, productId) {
      const productRepository = new ProductRepository();
      const authRepository = new AuthRepository();

      const business_id = authRepository.getSelectedRole()?.business_id;

      if (business_id == null) {
        return;
      }

      state.commit("setLoading", true);

      const productResult = await productRepository.getProductById(
        business_id,
        productId
      );

      if (productResult.message != undefined) {
        logEvent(this.$analytics, "get-product", { result: "failed" });
        state.commit("setErrorMessage", productResult);
        return;
      } else {
        logEvent(this.$analytics, "get-product", { result: "success" });
      }

      var unitLabel = productResult.data.unit_label;

      productResult.data.unit_label = {
        id: unitLabel,
        name: this.$t("product." + unitLabel)
      }

      state.commit("setProduct", productResult.data);
      state.commit("setLoading", false);
      return productResult;
    },

    async deleteProduct(state, product) {
      const productRepository = new ProductRepository();
      const authRepository = new AuthRepository();
      
      const business_id = authRepository.getSelectedRole()?.business_id;

      if (business_id == null) {
        return;
      }

      state.commit("setLoading", true);

      const productResult = await productRepository.deleteProduct(business_id, product);

      if (productResult.message != undefined) {
        logEvent(this.$analytics, "delete-product", { result: "failed" });
        state.commit("setErrorMessage", productResult);
      } else {
        logEvent(this.$analytics, "delete-product", { result: "success" });
      }

      state.commit("setLoading", false);

      return productResult;
    },

    async retrieveAllUnitLabel(state) {
      const productRepository = new ProductRepository();

      state.commit("setLoadingUnitLabel", true);
      const unitLabelResult = await productRepository.getAllUnitLabel();

      if (unitLabelResult.message != undefined) {
        logEvent(this.$analytics, "get-unit-label", { result: "failed" });
        state.commit("setErrorMessage", unitLabelResult);
        return;
      } else {
        logEvent(this.$analytics, "get-unit-label", { result: "success" });
      }

      const unitLabels = [];
      unitLabelResult.data?.forEach((unitLabel, index) => {
        unitLabels.push({
          id: unitLabel,
          name: this.$t("product." + unitLabel),
        });
      });

      state.commit("setLoadingUnitLabel", false);
      state.commit("setUnitLabel", unitLabels);
      return unitLabelResult;
    },



  },
};

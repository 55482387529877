import BaseDao from "./base-dao";

export default class TestModeDao extends BaseDao {
    constructor() {
        super();
        this.key = "test-mode";
    }

    saveTestMode(isTestMode) {
        this.saveData(this.key + ".isTestMode", isTestMode, 86400000); //data will be save for 1 day
    }

    removeTestMode() {
        this.saveData(this.key + ".isTestMode", false);
    }

    isTestMode() {
        return this.getData(this.key + ".isTestMode") ?? false;
    }
}
<template>
  <button
    :id="id"
    v-show="show"
    class="flex w-full text-sm"
    :class="[
      $attrs.class,
      disabled
        ? 'cursor-default text-gray-400'
        : 'text-gray-600 hover:bg-gray-100',
      {
        'bg-gray-100 text-primary font-bold border-primary': 
        active,
      },
      rounded ? 'rounded-md' : 'rounded-none',
      fullWidth ? 'w-full' : 'w-auto',
    ]"
    @click="onClick"
  >
    <slot />
  </button>
</template>

<script>
import { logEvent } from "firebase/analytics";

export default {
  emits: ["onMenuClick"],
  props: {
    id: {
      type: String,
      default: 'button-id',
    },
    eventName: {
      type: String,
      default: null,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    show: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    onClick(event) {
      if (this.disabled) return;

      if (this.eventName != null) {
        logEvent(this.$analytics, "click", { event: this.eventName });
      }

      this.$emit("onMenuClick", event);
    },
  },
};
</script>

import PaymentRepository from "../../data/repository/payment-repository";
import baseStore from "../base-store";
import { logEvent } from "firebase/analytics";
import currency from "../../utils/currency";

const initialPaymentGatewayMethod = [];

export default {
    namespaced: true,
    state: {
        ...baseStore.state,

        payment_gateway_methods: initialPaymentGatewayMethod,
        payment_gateway_method: null
    },

    getters: {
        ...baseStore.getters,

        payment_gateway_methods(state) {
            return state.payment_gateway_methods;
        },

        payment_gateway_method(state) {
            return state.payment_gateway_method;
        },
    },

    mutations: {
        ...baseStore.mutations,

        setPaymentGatewayMethods(state, payment_gateway_methods) {
            state.payment_gateway_methods = payment_gateway_methods;
        },

        setPaymentGatewayMethod(state, payment_gateway_method) {
            state.payment_gateway_method = payment_gateway_method;
        },
    },

    actions: {

        async indexPaymentGatewayMethods(state, queryParams) {

            const paymentRepository = new PaymentRepository();

            state.commit("setApiPaginationLoading", true);

            const paymentGatewayMethodResult = await paymentRepository.indexPaymentGatewayMethods(
                queryParams
            );

            if (paymentGatewayMethodResult.message != undefined) {
                logEvent(
                    this.$analytics,
                    "get-payment-gateway-method",
                    { result: "failed" }
                );
                state.commit("setErrorMessage", paymentGatewayMethodResult);
                return;
            } else {
                logEvent(
                    this.$analytics,
                    "get-payment-gateway-method",
                    { result: "success" }
                );
            }

            state.commit("setApiPaginationLoading", false);
            state.commit("setPaymentGatewayMethods", paymentGatewayMethodResult.data);
            return paymentGatewayMethodResult;

        },

        async updatePaymentGatewayMethods(state, updatedPaymentGatewayMethod) {

            const paymentRepository = new PaymentRepository();

            state.commit("setLoading", true);

            const updatePaymentGatewayMethodResult = await paymentRepository.updatePaymentGatewayMethods(
                updatedPaymentGatewayMethod.id,
                {
                    gateway_fixed_charges: currency.toCents('MYR', updatedPaymentGatewayMethod.formatted_gateway_fixed_charges),
                    gateway_percentage_charges: updatedPaymentGatewayMethod.gateway_percentage_charges,
                    gateway_charges_type: updatedPaymentGatewayMethod.gateway_charges_type.type,
                    gateway_percentage_min_charges: currency.toCents('MYR', updatedPaymentGatewayMethod.formatted_gateway_percentage_min_charges),
                    template_fixed_charges: currency.toCents('MYR', updatedPaymentGatewayMethod.formatted_template_fixed_charges),
                    template_percentage_charges: updatedPaymentGatewayMethod.template_percentage_charges,
                    template_charges_type: updatedPaymentGatewayMethod.template_charges_type.type,
                    template_percentage_min_charges: currency.toCents('MYR', updatedPaymentGatewayMethod.formatted_template_percentage_min_charges),
                }
            );

            if (updatePaymentGatewayMethodResult.message != undefined) {
                logEvent(
                    this.$analytics,
                    "update-payment-gateway-method",
                    { result: "failed" }
                );
                state.commit("setErrorMessage", updatePaymentGatewayMethodResult);
                return;
            } else {
                logEvent(
                    this.$analytics,
                    "update-payment-gateway-method",
                    { result: "success" }
                );
            }

            state.commit("setLoading", false);
            state.commit("setPaymentGatewayMethod", updatePaymentGatewayMethodResult.data);
            return updatePaymentGatewayMethodResult;

        },
    },
};
<template>
  <button
    ref="btnRef"
    @click="onClick"
    type="button"
    v-on:mouseenter="disabled ? toggleTooltip() : null"
    v-on:mouseleave="disabled ? toggleTooltip() : null"
    class="text-sm flex space-x-3 border-b-2 p-4 text-gray-500 outline-none"
    :class="[
      $attrs.class,
      { 'text-primary font-bold border-primary': active },
      disabled
        ? 'cursor-not-allowed text-black '
        : 'cursor-pointer hover:bg-gray-100',
      fullWidth ? 'w-full' : 'w-auto',
    ]"
  >
    <slot />
  </button>
  <div
    ref="tooltipRef"
    v-bind:class="{ hidden: !tooltipShow, block: tooltipShow }"
    class="
      bg-blue-200
      mb-2
      block
      font-normal
      leading-normal
      text-xs text-center
      no-underline
      break-words
      rounded-lg
    "
  >
    <div class="text-blue-800 p-3">
      {{ $t("dashboard.feature_access") }}
    </div>
  </div>
</template>


<script>
import { logEvent } from "firebase/analytics";
import { createPopper } from "@popperjs/core";

export default {
  emits: ["onClick"],
  data() {
    return {
      tooltipShow: false,
    };
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "button",
    },
    active: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    eventName: {
      type: String,
      default: null,
    },
    showToolTip: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    onClick(event) {
      if (this.disabled) return;

      if (this.eventName != null) {
        logEvent(this.$analytics, "click", { event: this.eventName });
      }

      this.$emit("onClick", event);
    },
    toggleTooltip: function () {
      if (!this.showToolTip) return;

      if (this.tooltipShow) {
        this.tooltipShow = false;
      } else {
        this.tooltipShow = true;
        createPopper(this.$refs.btnRef, this.$refs.tooltipRef, {
          placement: "bottom",
        });
      }
    },
  },
};
</script>
<template>
  <div>
    
    <div v-if="loading" class="flex justify-center">
      <app-icon-loading class="text-center" iconColor="primary" />
    </div>

    <div
      v-else-if="!$lodash.isEmpty(temporaryDatas) || filterText"
      class="mt-1 w-full bg-white"
    >
      <label
        v-if="showLabel && labelText"
        id="listbox-label"
        class="block text-sm font-light text-gray-700 mb-1"
      >
        {{ labelText }}
      </label>

      <app-form-input
        v-if="allowFilter"
        id="filterText"
        name="filterText"
        autocomplete=""
        class="sticky"
        inputclass="text-sm p-2 mb-2"
        :placeholder="$t('general.placeholder_search')"
        v-model="filterText"
      />
      <ul
        tabindex="-1"
        role="listbox"
        aria-labelledby="listbox-label"
        aria-activedescendant="listbox-item-3"
        class="
          max-h-56
          rounded-md
          text-base
          leading-6
          shadow-xs
          overflow-auto
          focus:outline-none
          text-xs
          sm:text-sm sm:leading-5
        "
        :class="{ border: !$lodash.isEmpty(temporaryDatas) }"
      >
        <li
          tabindex="0"
          id="listbox-item-0"
          @click="select(data)"
          role="option"
          v-for="data in temporaryDatas"
          :key="data.id"
          class="
            text-gray-900
            select-none
            relative
            py-2
            pl-3
            pr-9
            cursor-pointer
            m-2
            bg-white
            rounded-md
            border-2
          "
          :class="{ 'border-primary': isSelected(data) }"
        >
          <div class="flex items-center space-x-3">
            <img
              v-if="showIcon"
              :src="data.icon ?? data.flag"
              alt=""
              class="flex-shrink-0 h-8 w-8 object-contain"
            />

            <span
              class="block truncate"
              v-bind:class="{
                'font-normal': !isSelected(data),
                'font-semibold': isSelected(data),
              }"
            >
              {{ data.name }}
            </span>
          </div>

          <span
            v-show="isSelected(data)"
            class="absolute inset-y-0 right-0 flex items-center pr-4"
          >
            <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path
                fill-rule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clip-rule="evenodd"
              />
            </svg>
          </span>
        </li>
        <li v-if="$lodash.isEmpty(temporaryDatas)" class="text-center pt-3">
          {{ $t("general.no_results") }}
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    datas: {
      type: Array,
    },
    modelValue: {
      type: Object,
      default: () => {},
    },
    id: {
      type: String,
    },
    name: {
      type: String,
    },
    type: {
      type: String,
    },
    autocomplete: {
      type: String,
    },
    labelText: {
      type: String,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    showIcon: {
      type: Boolean,
      default: false,
    },
    allowFilter: {
      type: Boolean,
      default: true,
    },
    errorMessage: {
      type: Array,
      default: () => [],
    },
    serverMessage: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      isOpen: false,
      temporaryDatas: [],
      filterText: "",
      selectValue: null,
    };
  },

  computed: {
    placeHolderText() {
      return this.placeholder == ""
        ? this.$t("general.please_select")
        : this.placeholder;
    },
    modelValueSelect: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.selectValue = value;
      },
    },
    selected() {
      return this.modelValueSelect ?? this.selectValue;
    },
  },

  methods: {
    isSelected(value) {
      return this.selected != null && this.selected.id === value.id;
    },
    select(value) {
      this.modelValueSelect = value;
      this.$emit("update:modelValue", value);
      this.$emit("valueSelected", value);
    },
    retry() {
      this.$emit("retry");
    },
  },

  watch: {
    datas: function (newVal) {
      this.temporaryDatas = newVal;
    },
    filterText(newFilterText) {
      if (this.temporaryDatas == null) {
        return;
      }

      if (newFilterText.trim() == "") {
        this.temporaryDatas = this.datas;
        return;
      }

      this.temporaryDatas = this.datas.filter(function (data) {
        return data.name.toLowerCase().includes(newFilterText.toLowerCase());
      });
    },
  },
};
</script>

<template>
  <app-full-modal :title="$t('payment.detail.title_modal')">
    <template v-slot:body>
      <div v-if="loading">
        <app-icon-loading iconColor="primary" class="mx-auto mt-10" />
      </div>

      <div v-else class="w-full">
        <table class="min-w-full border-b divide-y divide-divider-gray border-divider-gray">
          <thead>
            <th>{{ $t("invoice.create.product_description") }}</th>
            <th>{{ $t("invoice.create.product_qty") }}</th>
            <th>{{ $t("invoice.create.product_price") }}</th>
            <th>{{ $t("invoice.create.product_tax") }}</th>
            <th class="text-right">
              {{ $t("invoice.create.product_amount") }}
            </th>
          </thead>
          <tr v-for="product in invoice.items" :key="product._id">
            <td>
              <p>
                <span class="font-bold">{{ product.name }}</span>
              </p>
            </td>
            <td>{{ product.quantity }}</td>
            <td>
              <p>
                {{
                  `${$formats.currency(product.currency, product.price)}/${
                    product.unit_label
                  }`
                }}
              </p>
            </td>
            <td><span v-if="product.tax_rate">{{ product.tax_rate }}% {{ product.tax_type === "inclusive" ? $t(`tax.types.${product.tax_type}_short`) : "" }}</span></td>
            <td>
              <p class="text-right">
                {{ `${$formats.currency(product.currency, product.price * product.quantity)}` }}
              </p>
            </td>
          </tr>
        </table>
        <div class="w-full mb-2 border-t"></div>
        <table class="w-full table-fixed">
          <tr>
            <td></td>
            <td></td>
            <td>
              <p class="font-bold text-right">
                {{ $t("invoice.invoice_pdf.subtotal") }}:
              </p>
            </td>
            <td>
              <p class="text-right">
                {{
                  $formats.currency(invoice.currency, invoice.subtotal) ?? "0"
                }}
              </p>
            </td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td>
              <p class="text-right text-gray-400">
               {{ $t("invoice.create.tax") }} {{ `${invoice.tax_rate}%` }} {{
                    invoice.tax_type === "inclusive"
                      ? `${$t(`tax.types.${invoice.tax_type}_short`)}`
                      : ""  }}
              </p>
            </td>
            <td>
              <p class="text-right text-gray-400">
                {{
                  $formats.currency(invoice.currency, invoice.tax_amount) ?? "0"
                }}
              </p>
            </td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td>
              <p class="text-lg font-semibold text-right">
                {{ $t("invoice.invoice_pdf.total_due") }} :
              </p>
            </td>
            <td>
              <p class="text-lg font-semibold text-right">
                {{
                  $formats.currency(invoice.currency, invoice.total_amount) ??
                  "0"
                }}
              </p>
            </td>
          </tr>
          <!-- <tr>
            <td colspan="4">
              <p class="text-right text-gray-400">
                {{ $t(`invoice.create.types.${invoice.type}`) }}
              </p>
            </td>
          </tr> -->
        </table>
      </div>

      <!-- invoice items summary -->
      <div
        class="justify-center inline-block min-w-full py-4 bg-white sm:px-12 lg:px-12"
      >
        <div class="flex flex-col gap-4 md:flex-row">
          <div class="w-1/4"></div>
          <div class="w-1/4"></div>
          <div class="w-1/2 text-right sm:items-end">
            <div class="grid grid-cols-2 sm:text-right">
              <div class="text-sm">
                <p class="pb-2 text-sm font-bold">
                  {{ $t("invoice.invoice_pdf.subtotal") }}:
                </p>
                <p class="pb-1 text-sm text-gray-600">
                  {{
                    `${$t("invoice.create.tax")}${
                      invoice.tax_type === 'inclusive'
                        ? ` (${$t(`tax.types.${invoice.tax_type}_short`)})`
                        : ""
                    }:`
                  }}
                </p>
                <p class="pb-1 text-sm text-gray-600">
                  {{ $t("invoice.create.tax_amount") }}:
                </p>
                <p class="text-lg py-1.5 font-semibold text-primary">
                  {{ $t("invoice.invoice_pdf.total_due") }}:
                </p>
              </div>
              <div class="items-end text-sm sm:text-right">
                <p class="pb-2 text-sm font-bold">
                  {{ $formats.currency(invoice.currency, invoice.subtotal) }}
                </p>
                <p class="pb-1 text-sm text-gray-600">
                  {{ invoice.tax_name ? invoice.tax_name : "-" }} /
                  {{ invoice.tax_rate ? `${invoice.tax_rate}%` : "-" }}
                </p>
                <p class="pb-1 text-sm text-gray-600">
                  {{
                    $formats.currency(invoice.currency, invoice.tax_amount) ??
                    "0"
                  }}
                </p>
                <p class="text-lg py-1.5 font-semibold text-primary">
                  {{
                    $formats.currency(invoice.currency, invoice.total_amount)
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </app-full-modal>
</template>

<script>
export default {
  data() {
    return {
      showModalDetail: false,
      selectWebhookEventLog: null,
    };
  },
  computed: {
    loading() {
      return this.$store.getters["paymentStore/loading"];
    },
    message() {
      return this.$store.getters["paymentStore/message"];
    },
    invoice() {
      return this.$store.getters["invoiceStore/invoice"];
    },
    customer() {
      return this.$store.getters["customerStore/customer"];
    },
    business() {
      return this.masterBusinessMode
        ? this.payment_link?.business
        : this.$store.getters["businessStore/business"];
    },
  },
};
</script>

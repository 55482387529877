<template>
  <div class="flex flex-col min-h-screen" :class="[background_color]">
    <app-header v-if="show_header" class="flex-none" />
    <app-navbar v-if="show_navbar" class="flex-none" />
    <div class="flex-1">
      <slot />
    </div>
    <app-footer v-if="show_footer" class="flex-none border-t" />
  </div>
</template>

<script>
export default {
  props: {
    show_header: {
      type: Boolean,
      default: true,
    },
    show_navbar: {
      type: Boolean,
      default: true,
    },
    show_footer: {
      type: Boolean,
      default: true,
    },
    background_color: {
      type: String,
      default: "bg-backgroud",
    },
  },
};
</script>

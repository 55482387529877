<template>
  <svg
    width="92"
    height="92"
    viewBox="0 0 92 92"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M60.0188 11.7332L22.9616 30.7058L8.23193 23.4584L44.6359 5.48272C45.4525 5.06761 46.4324 5.06761 47.2899 5.48272L60.0188 11.7332Z"
      fill="#52525B"
    />
    <path
      d="M83.7271 23.4571L46.02 42.1371L31.8994 35.1789L29.8579 34.1411L66.9559 15.1719L68.9974 16.2063L83.7271 23.4571Z"
      fill="#52525B"
    />
    <path
      d="M42.999 47.5208L42.9582 86.8305L6.84349 67.9838C5.82272 67.4462 5.16943 66.3676 5.16943 65.2073V28.8408L20.4741 36.3809V49.6372C20.4741 51.3351 21.8624 52.7437 23.5364 52.7437C25.2105 52.7437 26.5987 51.3351 26.5987 49.6372V39.4466L28.6402 40.4401L42.999 47.5208Z"
      fill="#52525B"
    />
    <path
      d="M86.7896 28.8818L49.1234 47.4802L49.0825 86.7899L86.8304 67.0789L86.7896 28.8818Z"
      fill="#52525B"
    />
  </svg>
</template>
<template>
  <div class="flex flex-col h-screen">
    <slot class="flex-1"/>
    <app-footer v-if="show_footer" class="flex-none" />
  </div>
</template>

<script>
export default {
  props: {
    show_header: {
      type: Boolean,
      default: true,
    },
    show_navbar: {
      type: Boolean,
      default: true,
    },
    show_footer: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<template>
  <svg
    width="92"
    height="92"
    viewBox="0 0 92 92"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M23.3161 9.70605C18.305 9.70605 14.2427 13.7684 14.2427 18.7795V82.2938L30.1212 73.2203L45.9998 82.2938L61.8784 73.2203L77.757 82.2938V18.7795C77.757 13.7684 73.6946 9.70605 68.6835 9.70605H23.3161ZM34.658 23.3163C30.8996 23.3163 27.8529 26.363 27.8529 30.1214C27.8529 33.8797 30.8996 36.9265 34.658 36.9265C38.4163 36.9265 41.4631 33.8797 41.4631 30.1214C41.4631 26.363 38.4163 23.3163 34.658 23.3163ZM62.818 24.645C61.0463 22.8733 58.1738 22.8733 56.4021 24.645L29.1817 51.8655C27.41 53.6372 27.41 56.5097 29.1817 58.2814C30.9534 60.0531 33.8259 60.0531 35.5976 58.2814L62.818 31.061C64.5897 29.2892 64.5897 26.4167 62.818 24.645ZM57.3417 45.9999C53.5833 45.9999 50.5366 49.0467 50.5366 52.805C50.5366 56.5634 53.5833 59.6101 57.3417 59.6101C61.1 59.6101 64.1468 56.5634 64.1468 52.805C64.1468 49.0467 61.1 45.9999 57.3417 45.9999Z"
      fill="#52525B"
    />
  </svg>
</template>
<template>
  <div class="flex flex-col" :class="$attrs.class">
    <label
      v-if="showlabel"
      :for="name"
      class="block text-sm font-light text-gray-700"
    >
      {{ labelText }}
    </label>
    <div :class="[showlabel ? 'mt-2' : '']">
      <input
        :class="[
          $attrs.inputclass,
          rounded ? 'rounded-md' : '',
          'w-full h-10 shadow-sm text-sm border-1 focus:ring-primary border-none ring-1 focus:ring-2 focus:outline-none',
          errorMessage.length > 0 ? 'ring-error' : 'ring-bordercolor',
          { 'bg-gray-100': disabled },
        ]"
        :id="id"
        :name="name"
        :type="type"
        :min="min"
        :max="max"
        :step="step"
        :maxlength="maxlength"
        :autocomplete="autocomplete"
        :disabled="disabled || loading"
        :required="required"
        :placeholder="placeholder"
        :value="modelValue"
        @input="onInput"
        @blur="onBlur"
        @focus="onFocus"
        @keypress="onKeyPress"
        :readonly="readonly"
      />
      <span v-if="errorMessage.length > 0" class="text-xs text-error">{{
        errorMessage[0]
      }}</span>
    </div>
  </div>
</template>

<script>
import { mask } from "vue-the-mask";

export default {
  directives: { mask },
  emits: ["onblur", "onfocus", "update:modelValue"],
  props: {
    id: {
      type: String,
    },
    name: {
      type: String,
    },
    type: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    autocomplete: {
      type: String,
    },
    labelText: {
      type: String,
      default: "",
    },
    showlabel: {
      type: Boolean,
      default: true,
    },
    modelValue: {
      type: [Number, String],
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    min: {
      type: String,
    },
    max: {
      type: String,
    },
    step: {
      type: String,
    },
    maxlength: {
      type: Number,
    },
    rounded: {
      type: Boolean,
      default: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: Array,
      default: () => [],
    },
    numberOnly: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    onBlur(event) {
      if (this.type == "url" && this.modelValue) {
        var url = this.modelValue;

        if (!~url.indexOf("://")) {
          url = "https://" + url;
        }

        this.$emit("update:modelValue", url);
      }
      
      if (
        this.type == "number" &&
        event.target.value != "" &&
        this.countDecimals(this.step) > 0
      ) {
        const input = event.target.value;

        let inputToFloat = parseFloat(input);
        let stepToFloat = parseFloat(this.step);

        const inputCountDecimals = this.countDecimals(inputToFloat);
        const stepCountDecimals = this.countDecimals(stepToFloat);

        if (inputCountDecimals > 0 && inputCountDecimals != stepCountDecimals) {
          inputToFloat = inputToFloat.toFixed(stepCountDecimals);
        }

        this.$emit("update:modelValue", inputToFloat);
      }

      this.$emit("onblur", event);
    },
    onFocus(event) {
      this.$emit("onfocus", event);
    },
    onKeyPress(event) {
      if (this.type == "number") {
        let keyCode = event.keyCode ? event.keyCode : event.which;
        if (keyCode < 48 || keyCode > 57 || keyCode === 101) {
          if (this.numberOnly == false && keyCode === 46) {
            return;
          }
          event.preventDefault();
        }
      }
    },
    onInput(event) {
      let input = event.target;

      if (
        input.value &&
        this.maxlength &&
        input.value.length > input.maxLength
      ) {
        input.value = input.value.slice(0, input.maxLength);
      }

      this.$emit("update:modelValue", input.value);
    },
    countDecimals(input) {
      let value = parseFloat(input);
      if (Math.floor(value) === value) return 0;
      return value.toString().split(".")[1].length || 0;
    },
  },
};
</script>

<style scoped>
/* hide indicator for input number */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>



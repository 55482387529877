import lodash from "lodash";

export default class BaseRepository {
    constructor() {
        this.$lodash = lodash;
    }

    successResponse(data) {
        return {
            data: data,
            message: null,
            errors: null
        }
    }

    errorResponse(response) {
        return {
            data: null,
            message: response.message,
            errors: response.errors
        }
    }
}
<template>
  <app-full-modal
    :title="$t('payment.open-payment-link.modal.details.modal_title')"
    width="md:w-10/12 lg:w-9/12 xl:w-7/12"
  >
    <template v-slot:body>
      <div v-if="loading">
        <app-icon-loading iconColor="primary" class="mx-auto mt-10" />
      </div>

      <div v-else-if="open_payment_link">
        <!-- copy link -->
        <div class="flex items-center space-x-3 p-5">
          <app-form-input
            type="text"
            v-model="data.payment_url"
            :showlabel="false"
            :readonly="true"
            class="w-full"
          />
          <app-button-outline
            @click="copyUrl(open_payment_link)"
            class="bg-white flex-none space-x-1"
          >
            <app-icon-outline
              name="DocumentDuplicateIcon"
              class="h-5 w-5 text-gray-400"
            />
            <p class="text-xs">
              {{ $t("payment.detail.copy_link") }}
            </p>
          </app-button-outline>
        </div>

        <!-- Open Payment Link Details -->
        <div
          class="
            p-5
            md:flex-row
            items-start
            flex flex-col
            space-x-0 space-y-5
            md:space-x-5 md:space-y-0
          "
        >
          <!-- Banner -->
          <div class="flex-1" v-if="openPaymentLinkBanner">
            <div class="py-10 px-4 border rounded-md">
              <img :src="openPaymentLinkBanner" class="w-full object-contain" />
            </div>
          </div>

          <!-- Details -->
          <div class="flex-1 space-y-5 w-full">
            <div class="border-b pb-3">
              <p class="font-medium">
                {{
                  $t("payment.open-payment-link.modal.details.payment_details")
                }}
              </p>
            </div>
            <div class="space-y-5">
              <div class="grid grid-cols-7 text-sm space-x-3">
                <span class="col-span-2 text-gray-500">
                  {{ $t("payment.open-payment-link.modal.details.business_name") }}
                </span>
                <span class="col-span-5 break-all">
                  {{ open_payment_link.business.name   }}
                </span>
              </div>
              <div class="grid grid-cols-7 text-sm space-x-3">
                <span class="col-span-2 text-gray-500">
                  {{ $t("payment.open-payment-link.modal.details.title") }}
                </span>
                <span class="col-span-5 break-all">
                  {{ open_payment_link.title }}
                </span>
              </div>
              <div class="grid grid-cols-7 text-sm space-x-3">
                <span class="col-span-2 text-gray-500">
                  {{
                    $t("payment.open-payment-link.modal.details.description")
                  }}
                </span>
                <span class="col-span-5 break-all">
                  <app-text-readmore :text="open_payment_link.description ?? '-'" :showReadMore="false" />
                </span>
              </div>
              <div class="grid grid-cols-7 text-sm space-x-3">
                <span class="col-span-2 text-gray-500">
                  {{
                    $t("payment.open-payment-link.modal.details.minimum_amount")
                  }}
                </span>
                <span class="col-span-5">
                  {{
                    $formats.currency(
                      open_payment_link.currency,
                      open_payment_link.minimum_amount ?? 0.0
                    )
                  }}
                </span>
              </div>
              <div class="grid grid-cols-7 text-sm space-x-3">
                <span class="col-span-2 text-gray-500">
                  {{ $t("payment.open-payment-link.modal.details.status") }}
                </span>
                <span
                  class="col-span-5"
                  :class="{ 'text-green-500': open_payment_link.status }"
                >
                  {{
                    $t(
                      "payment.open-payment-link.status." +
                        open_payment_link.status_id
                    )
                  }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <!-- Statistic Payments -->
        <div class="px-5 pt-10">
          <div class="flex space-x-6 text-sm justify-between">
            <div class="space-y-2">
              <p class="text-gray-400">
                {{ $t("payment.open-payment-link.modal.details.link_opened") }}
              </p>
              <p>{{ open_payment_link.payment_summary?.total ?? '-' }}</p>
            </div>
            <div class="space-y-2 border-l pl-4">
              <p class="text-gray-400">
                {{
                  $t(
                    "payment.open-payment-link.modal.details.successful_payment"
                  )
                }}
              </p>
              <p>{{ open_payment_link.payment_summary?.successful ?? '-' }}</p>
            </div>
            <div class="space-y-2 border-l pl-4">
              <p class="text-gray-400">
                {{
                  $t(
                    "payment.open-payment-link.modal.details.unsuccessful_payment"
                  )
                }}
              </p>
              <p>{{ open_payment_link.payment_summary?.unsuccessful ?? '-' }}</p>
            </div>
            <div class="space-y-2 border-l pl-4">
              <p class="text-gray-400">
                {{
                  $t("payment.open-payment-link.modal.details.pending_payment")
                }}
              </p>
              <p>{{ open_payment_link.payment_summary?.pending ?? '-' }}</p>
            </div>
            <div />
          </div>
        </div>

        <!-- Payment Details -->
        <div class="pt-10">
          <p class="text-lg font-medium py-2 px-5">
            {{ $t("payment.open-payment-link.modal.details.payment_details") }}
          </p>

          <hr />

          <div class="flex justify-center p-10">
            <app-link
              :to="{
                name: 'payment-open-link.index.payment-links',
                params: {
                  open_payment_link_id: open_payment_link._id
                },
              }"
            >
            <app-button-outline>
            {{ $t("payment.open-payment-link.modal.details.view_payment_details") }}
            </app-button-outline>
            </app-link>
          </div>
        </div>
      </div>
    </template>
  </app-full-modal>
</template>

<script>
import MEDIA_CATEGORY from "@/utils/const/media_category";

export default {
  data() {
    return {
      showModalDetail: false,
      data: {},
    };
  },
  props: {
    open_payment_link: {
      type: Object,
      default: null,
    },
  },
  watch: {
    open_payment_link(newVal, oldVal) {
      if (newVal) {
        this.data = newVal;
      }
    },
  },
  computed: {
    loading() {
      return this.$store.getters["openPaymentStore/loading"];
    },
    message() {
      return this.$store.getters["openPaymentStore/message"];
    },
    openPaymentLinkBanner() {
      return this.open_payment_link?.media?.find(
        (media) => media.collection_name == MEDIA_CATEGORY.BANNER
      )?.original_url;
    },
  },
  methods: {
    copyUrl(model) {
      const clipboardData =
        event.clipboardData ||
        window.clipboardData ||
        event.originalEvent?.clipboardData ||
        navigator.clipboard;

      clipboardData.writeText(model.payment_url);

      this.$notify(
        {
          group: "success",
          title: this.$t("payment.link.copy_link"),
        },
        5000
      );
    },
  },
};
</script>
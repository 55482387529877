<template>
  <div class="flex flex-row w-full space-x-6">
    <app-form-textarea
      id="invoice.memo"
      name="invoice.memo"
      type="text"
      class="lg:w-1/2 w-full break-words"
      v-model="invoice.memo"
      :labelText="$t('invoice.create.memo')"
      :placeholder="$t('invoice.create.memo_placeholder')"
      :maxlength="100"
      :errorMessage="errors.memo"
    />
    <app-form-textarea
      id="invoice.internal_memo"
      name="invoice.internal_memo"
      type="text"
      class="lg:w-1/2 w-full"
      v-model="invoice.internal_memo"
      :labelText="$t('invoice.create.internal_memo')"
      :placeholder="$t('invoice.create.internal_memo_placeholder')"
      :maxlength="100"
      :errorMessage="errors.memo"
    />
  </div>
  <div class="w-full space-y-3">
    <p class="font-semibold text-lg">
      {{ $t("invoice.create.payment_term") }}
    </p>
    <div class="flex space-x-3">
      <div class="w-1/2">
        <app-form-select
          :datas="paymentTermOpts"
          :allowFilter="false"
          :labelText="$t('invoice.create.payment_term_date')"
          :inline="true"
          :errorMessage="errors.payment_term_date"
          v-model="term"
        />
      </div>
      <div class="w-1/2">
        <litepie-datepicker
          as-single
          :formatter="termFormatter"
          :disable-date="disabledDate"
          v-model="invoice.payment_term_date"
          v-if="term.id === 'custom'"
        />
      </div>
    </div>
  </div>
  <!-- @TODO: Type will be hidden until we are ready for recurring and partial payment -->
  <div class="space-y-3" v-show="false">
    <app-form-radio
      class="text-sm"
      :label="$t('invoice.create.type')"
      labelClass="font-semibold text-lg"
      :options="typeOpts"
      v-model="invoice.type"
      :errorMessage="errors.type"
    />
    <div
      class="text-sm bg-gray-100 p-2"
      v-if="invoice.type === 'one_time_payment'"
    >
      <label class="inline-flex items-center">
        <input
          type="checkbox"
          class="form-radio focus:ring-transparent"
          name="invoice.allow_partial"
          v-model="invoice.allow_partial"
        />
        <span class="ml-2">
          {{ $t("invoice.create.allow_partial") }}
        </span>
      </label>
    </div>
  </div>
  <div class="pb-10 border-b-2 w-full">
    <label class="block text-sm font-light text-gray-700">
      {{ $t("invoice.create.custom_attributes") }}
    </label>
    <div
      v-for="(custom_attribute, idx) in invoice.custom_attributes"
      :key="idx"
      class="flex flex-row lg:w-1/2 w-full mb-3 space-x-2"
    >
      <app-form-input
        type="text"
        v-model="invoice.custom_attributes[idx].key"
        :placeholder="$t('invoice.create.custom_attributes_key_placeholder')"
        :maxlength="50"
        :errorMessage="errors[`custom_attributes.${idx}.key`]"
      />
      <app-form-input
        type="text"
        v-model="invoice.custom_attributes[idx].value"
        :placeholder="$t('invoice.create.custom_attributes_value_placeholder')"
        :maxlength="50"
        :errorMessage="errors[`custom_attributes.${idx}.value`]"
      />
      <app-icon-outline
        v-if="idx > 0"
        name="XIcon"
        class="mt-2 w-6 cursor-pointer"
        @click="removeCustomField(idx)"
      />
      <div v-else class="w-6" />
    </div>
    <app-button
      :primary="false"
      :showf70Icon="false"
      @click="addCustomField"
      v-if="canAddNewCustomFields"
      width=""
      class="gap-2"
    >
      <app-icon name="PlusIcon" class="w-4" />
      {{ $t("invoice.create.add_new_custom_attribute") }}
    </app-button>
  </div>
  <app-form-textarea
    id="invoice.footer"
    name="invoice.footer"
    type="text"
    class="w-full"
    v-model="invoice.footer"
    :labelText="$t('invoice.create.footer')"
    :placeholder="$t('invoice.create.footer_placeholder')"
    :maxlength="100"
    :errorMessage="errors.footer"
  />
</template>

<script>
import LitepieDatepicker from "litepie-datepicker";
import { MAX_N_CUSTOM_ATTRIBUTES } from "@/utils/const/invoice_settings";
import INVOICE_TYPE from "@/utils/const/invoice_type";

const paymentTerms = [
  "0d",
  "1d",
  "7d",
  "14d",
  "30d",
  "45d",
  "60d",
  "90d",
  "custom",
];

export default {
  components: {
    LitepieDatepicker,
  },
  data() {
    return {
      invoiceId: null,
      term: {},
      termFormatter: {
        date: "YYYY-MM-DD",
        month: "MMM",
      },
      paymentTermOpts: paymentTerms.map((id) => ({
        id,
        name: this.$t(`invoice.create.terms.${id}`),
      })),
      typeOpts: [INVOICE_TYPE.STANDARD /*INVOICE_TYPE.RECURRING*/].map(
        (id) => ({
          id,
          name: this.$t(`invoice.create.types.${id}`),
        })
      ),
      disabledDate: (date) => {
        return date < new Date(Date.now());
      },
    };
  },

  computed: {
    invoice() {
      return this.$store.getters["invoiceStore/invoice"];
    },
    canAddNewCustomFields() {
      return this.invoice.custom_attributes.length < MAX_N_CUSTOM_ATTRIBUTES;
    },
    errors() {
      return this.$store.getters["invoiceStore/errors"] ?? {};
    },
  },

  methods: {
    addCustomField() {
      this.invoice.custom_attributes.push({ key: "", value: "" });
    },
    removeCustomField(idx) {
      this.invoice.custom_attributes.splice(idx, 1);
    },
  },

  watch: {
    invoice() {
      // @TODO: remove invoice.id when the BE is consistent using _id or id on listing/detail invoice/customer/product
      if (!this.invoiceId && (this.invoice._id || this.invoice.id)) {
        this.invoiceId = this.invoice._id || this.invoice.id;

        if (this.invoice.payment_term_date) {
          this.term = {
            id: "custom",
            name: this.$t("invoice.create.terms.custom"),
          };
          this.invoice.payment_term_date = this.$moment(
            this.invoice.payment_term_date
          ).format("YYYY-MM-DD");
        }
      }
    },
    term(newTerm) {
      if (newTerm.id !== "custom") {
        const termDate = new Date();
        termDate.setDate(
          termDate.getDate() + parseInt(newTerm.id.slice(0, -1))
        );
          this.invoice.payment_term_date =
            this.$moment(termDate).format("YYYY-MM-DD");
      }
    },
  },
};
</script>

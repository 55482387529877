<template>
  <svg
    width="15"
    height="17"
    viewBox="0 0 15 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.70522 11.8525L5.12657 9.71578C4.89149 9.39661 4.48632 8.97806 4.48632 8.55759C4.48632 8.13713 4.88958 7.72049 5.12657 7.40132L6.70522 5.26078C6.76755 5.17405 6.84077 5.09568 6.92309 5.02762V2.55453C6.92309 0.576449 6.09555 0.0699833 5.08452 1.44604L1.67878 6.05775C1.17231 6.74387 0.304626 7.64595 0.304626 8.5423C0.304626 9.43865 1.17422 10.3465 1.67878 11.0269L5.08452 15.6328C6.09555 17.0032 6.92309 16.5101 6.92309 14.5263V12.078C6.84033 12.0097 6.76706 11.9306 6.70522 11.8429"
      fill="#0286C8"
    />
    <path
      d="M13.3985 6.05771L9.99082 1.446C8.9798 0.0756757 8.15225 0.574497 8.15225 2.55449V5.01802C8.23437 5.08571 8.30701 5.16414 8.36822 5.25119L9.95069 7.39173C10.1858 7.7109 10.589 8.12945 10.589 8.548C10.589 8.96655 10.1858 9.38701 9.95069 9.70618L8.36822 11.8525C8.30696 11.9406 8.23434 12.0203 8.15225 12.0894V14.5511C8.15225 16.5349 8.9798 17.0356 9.99082 15.6576L13.3985 11.0517C13.9049 10.3617 14.7726 9.45773 14.7726 8.56711C14.7726 7.67649 13.9049 6.76294 13.3985 6.08256"
      fill="#242B57"
    />
  </svg>
</template>
import baseStore from "../base-store";
import AuthRepository from "../../data/repository/auth-repository";
import PaymentRepository from "../../data/repository/payment-repository";
import Role from "@/utils/const/role";
import PaymentChannel from "@/utils/const/payment_channel";
import { logEvent } from "firebase/analytics";
import currency from "../../utils/currency";

const initialPaymentLink = {
  _id: null,
  id: null,
  email: null,
  phone_no: "",
  title: null,
  currency: "MYR",
  description: null,
  ref1: null,
  ref2: null,
  redirect_url: null,
  send_email: false,
  business_id: null,
};

const initialBusinessPaymentGatewayConfig = {
  _id: null,
  api_key: null,
  secret_key: null,
  merchant_id: null,
  fpx_certificate: null,
  private_key: null,
  csr_key: null
};

const initialPaymentMethod = [];
const initialChargesType = [];

const initialCardInfo = {
  card_name: null,
  card_number: null,
  card_expiry: null,
  card_cvc: null,
};

const checkRoleMasterBusiness = [
  Role.MASTER_BUSINESS_OWNER,
  Role.MASTER_BUSINESS_ADMIN,
  Role.MASTER_BUSINESS_MANAGER,
  Role.MASTER_BUSINESS_DEVELOP,
  Role.MASTER_BUSINESS_SUPPORT,
]

export default {
  namespaced: true,
  state: {
    ...baseStore.state,

    payment_link: {},
    list_payment_link: [],
    card_info: {},
    business_payment_gateway_method: [],
    chargesType: initialChargesType,
    onlineBanks: [],
    loadingChargesType: false,
    requery: {},
    business_payment_gateway_config: initialBusinessPaymentGatewayConfig,
    totalPaid: {
      currency: 'MYR',
      total: 0.0
    }
  },

  getters: {
    ...baseStore.getters,

    card_info(state) {
      return state.card_info;
    },
    payment_link(state) {
      return state.payment_link;
    },
    business_payment_gateway_method(state) {
      return state.business_payment_gateway_method;
    },
    chargesType(state) {
      return state.chargesType;
    },
    onlineBanks(state) {
      return state.onlineBanks;
    },
    loadingChargesType(state) {
      return state.loadingChargesType;
    },
    requery(state) {
      return state.requery;
    },
    business_payment_gateway_config(state) {
      return state.business_payment_gateway_config;
    },
    totalPaid(state) {
      return state.totalPaid;
    }
  },

  mutations: {
    ...baseStore.mutations,

    initCardInfo(state, card_info = initialCardInfo) {
      state.card_info = this.$lodash.clone(card_info);
    },
    initPaymentLink(state, payment_link = initialPaymentLink) {
      state.payment_link = this.$lodash.clone(payment_link);
    },
    setPaymentLink(state, payment_link) {
      state.payment_link = payment_link;
    },
    setCardInfo(state, card_info) {
      state.payment_link = card_info;
    },
    setBusinessPaymentGatewayMethod(state, business_payment_gateway_method) {
      state.business_payment_gateway_method = business_payment_gateway_method;
    },
    setChargesType(state, chargesType) {
      state.chargesType = chargesType;
    },
    setRequery(state, requery) {
      state.requery = requery;
    },

    setOnlineBanks(state, onlineBanks) {
      state.onlineBanks = onlineBanks;
    },

    setLoadingChargesType(state, loadingChargesType) {
      state.loadingChargesType = loadingChargesType;
    },

    setBusinessPaymentGatewayConfig(state, business_payment_gateway_config) {
      state.business_payment_gateway_config = business_payment_gateway_config;
    },
    setTotalPaid(state, totalPaid) {
      state.totalPaid = totalPaid;
    },
  },

  actions: {
    async retrievePaymentLinks(state, queryParams) {
      const paymentRepository = new PaymentRepository();
      const authRepository = new AuthRepository();

      const business_id = authRepository.getSelectedRole()?.business_id;

      if (business_id == null) {
        return;
      }

      state.commit("setApiPaginationLoading", true);

      let paymentLinkIndexResult = (checkRoleMasterBusiness.includes(authRepository.getSelectedRole()?.role_id)) ?
        await paymentRepository.indexMasterBusiness(
          business_id,
          queryParams
        ) : await paymentRepository.index(
          business_id,
          queryParams
        );

      if (paymentLinkIndexResult.message != undefined) {
        logEvent(this.$analytics, "payment-link-index", { result: "failed" });
        state.commit("setErrorMessage", paymentLinkIndexResult);
        return;
      } else {
        logEvent(this.$analytics, "payment-link-index", { result: "success" });
      }

      state.commit("setApiPaginationLoading", false);
      state.commit("setApiPaginationResponse", paymentLinkIndexResult);
      return paymentLinkIndexResult;
    },

    async createOrUpdatePaymentLink(state) {
      const paymentRepository = new PaymentRepository();
      const authRepository = new AuthRepository();

      let business_id = authRepository.getSelectedRole()?.business_id;

      if (checkRoleMasterBusiness.includes(authRepository.getSelectedRole()?.role_id)) {
        business_id = state.getters.payment_link?.business?.id;
      }

      if (business_id == null) {
        state.commit("setError", {
          business_id: [
            this.$t("general.please_select", {
              name: this.$t("general.business"),
            }),
          ],
        });
        return;
      }

      state.commit("setLoading", true);

      state.getters.payment_link.amount = currency.toCents(
        state.getters.payment_link.currency, state.getters.payment_link.formatted_amount
      );

      Object.keys(state.getters.payment_link).forEach((key) => {
        if (state.getters.payment_link[key] === "") {
          delete state.getters.payment_link[key];
        }
      });

      const paymentLinkResult = await paymentRepository.createOrUpdate(
        business_id,
        state.getters.payment_link
      );

      const isCreate = state.getters.payment_link.id == null;

      if (paymentLinkResult.message != undefined) {
        logEvent(
          this.$analytics,
          isCreate ? "create-payment-link" : "update-payment-link",
          { result: "failed" }
        );
        state.commit("setErrorMessage", paymentLinkResult);
        return;
      } else {
        logEvent(
          this.$analytics,
          isCreate ? "create-payment-link" : "update-payment-link",
          { result: "success" }
        );
      }

      state.commit("setErrorMessage");
      state.commit("setLoading", false);

      return paymentLinkResult;
    },

    async getPaymentLinkPublic(state, paymentLinkId) {
      const paymentRepository = new PaymentRepository();

      state.commit("setLoading", true);

      const paymentLinkResult = await paymentRepository.getPaymentLinkPublic(
        paymentLinkId
      );

      if (paymentLinkResult.message != undefined) {
        logEvent(this.$analytics, "get-payment-link", { result: "failed" });
        state.commit("setErrorMessage", paymentLinkResult);
      } else {
        logEvent(this.$analytics, "get-payment-link", { result: "success" });
        state.commit("setPaymentLink", paymentLinkResult.data ?? {});
      }

      state.commit("setLoading", false);
      return paymentLinkResult;
    },

    async getPaymentLink(state, data) {
      const paymentRepository = new PaymentRepository();
      const authRepository = new AuthRepository();

      let business_id = data.business_id ?? authRepository.getSelectedRole()?.business_id;

      if (business_id == null) {
        return;
      }

      state.commit("setLoading", true);

      const paymentLinkResult = await paymentRepository.getPaymentLink(
        business_id,
        data.payment_link_id
      );

      if (paymentLinkResult.message != undefined) {
        logEvent(this.$analytics, "get-payment-link", { result: "failed" });
        state.commit("setErrorMessage", paymentLinkResult);
      } else {
        logEvent(this.$analytics, "get-payment-link", { result: "success" });
        state.commit("setPaymentLink", paymentLinkResult.data ?? {});
      }

      state.commit("setLoading", false);
      return paymentLinkResult;
    },

    async resendEmail(state, paymentLinkId) {
      const paymentRepository = new PaymentRepository();
      const authRepository = new AuthRepository();

      let business_id = authRepository.getSelectedRole()?.business_id;

      if (checkRoleMasterBusiness.includes(authRepository.getSelectedRole()?.role_id)) {
        const businessId = await paymentRepository.getPaymentLink(paymentLinkId);
        business_id = businessId.data.business_id;
      }

      if (business_id == null) {
        return;
      }

      const resendEmailResult = await paymentRepository.resendEmail(
        business_id,
        paymentLinkId
      );

      if (resendEmailResult.message != undefined) {
        logEvent(this.$analytics, "resend-email-payment-link", {
          result: "failed",
        });
        state.commit("setErrorMessage", resendEmailResult);
        return;
      } else {
        logEvent(this.$analytics, "resend-email-payment-link", {
          result: "success",
        });
      }

      return resendEmailResult;
    },

    async proceedPaymentAttempt(state, data) {
      const paymentRepository = new PaymentRepository();

      state.commit("setLoading", true);

      const paymentPaymentAttemptResult =
        await paymentRepository.proceedPaymentAttempt(
          state.getters.payment_link._id,
          data.businessPaymentGatewayMethodId,
          state.getters.card_info,
          data.bank_info,
          data.stripe_create_payment_result
        );

      if (paymentPaymentAttemptResult.message != undefined) {
        logEvent(this.$analytics, "proceed-payment-attempt", {
          result: "failed",
        });
        state.commit("setErrorMessage", paymentPaymentAttemptResult);
        return;
      }

      logEvent(this.$analytics, "proceed-payment-attempt", {
        result: "success",
      });

      return paymentPaymentAttemptResult.data;
    },

    async getBusinessPaymentGatewayMethod(state, business_id) {
      const paymentRepository = new PaymentRepository();
      const authRepository = new AuthRepository();

      business_id =
        business_id ?? authRepository.getSelectedRole()?.business_id;

      if (business_id == null) {
        return;
      }

      state.commit("setLoading", true);
      const businessPaymentGatewayMethodResult =
        await paymentRepository.getBusinessPaymentGatewayMethod(business_id);

      if (businessPaymentGatewayMethodResult.message != undefined) {
        logEvent(this.$analytics, "business-payment-gateway-method", {
          result: "failed",
        });
        state.commit("setErrorMessage", businessPaymentGatewayMethodResult);
        return;
      } else {
        logEvent(this.$analytics, "business-payment-gateway-method", {
          result: "success",
        });
      }

      state.commit("setLoading", false);
      state.commit(
        "setBusinessPaymentGatewayMethod",
        businessPaymentGatewayMethodResult.data
      );
      return businessPaymentGatewayMethodResult;
    },

    async requery(state, params) {
      const paymentRepository = new PaymentRepository();

      const payment_link = params.payment_link;
      const attempt_id = params.attempt_id;

      if (payment_link == null || attempt_id == null) {
        return;
      }

      const requeryResult =
        await paymentRepository.requery(payment_link, attempt_id);

      if (requeryResult.message != undefined) {
        logEvent(this.$analytics, "requery", {
          result: "failed",
        });
        state.commit("setErrorMessage", requeryResult);
        return;
      } else {
        logEvent(this.$analytics, "requery", {
          result: "success",
        });
      }

      state.commit("setRequery", requeryResult);
      return requeryResult;
    },

    async retrieveChargesType(state) {
      const paymentRepository = new PaymentRepository();

      state.commit("setLoadingChargesType", true);
      const paymentChargesTypeResult = await paymentRepository.getChargesType();

      if (paymentChargesTypeResult.message != undefined) {
        logEvent(this.$analytics, "get-charges-type", { result: "failed" });
        state.commit("setErrorMessage", paymentChargesTypeResult);
        return;
      } else {
        logEvent(this.$analytics, "get-charges-type", { result: "success" });
      }

      const chargesTypes = [];
      paymentChargesTypeResult.data?.forEach((chargesType, index) => {
        chargesTypes.push({
          id: chargesType,
          name: this.$t("payment.menu." + chargesType),
          type: chargesType,
        });
      });

      state.commit("setLoadingChargesType", false);
      state.commit("setChargesType", chargesTypes);
      return paymentChargesTypeResult;
    },

    async createOrUpdateBusinessPaymentGatewayMethod(
      state,
      business_payment_gateway_method
    ) {
      const paymentRepository = new PaymentRepository();
      const authRepository = new AuthRepository();

      let business_id =
        business_payment_gateway_method?.business_id ??
        business_payment_gateway_method?.payment_gateway_method_application
          ?.business_id;

      business_id =
        business_id ?? authRepository.getSelectedRole()?.business_id;

      if (business_id == null) {
        return;
      }

      business_payment_gateway_method.fixed_charges = currency.toCents(
        business_payment_gateway_method.currency ?? "MYR", business_payment_gateway_method.formatted_fixed_charges
      );
      business_payment_gateway_method.fixed_min_charges = currency.toCents(
        business_payment_gateway_method.currency ?? "MYR", business_payment_gateway_method.formatted_fixed_min_charges
      );
      business_payment_gateway_method.formatted_min_charges = currency.toCents(
        business_payment_gateway_method.currency ?? "MYR", business_payment_gateway_method.formatted_percentage_min_charges
      );

      state.commit("setLoading", true);

      Object.keys(business_payment_gateway_method).forEach((key) => {
        if (business_payment_gateway_method[key] === "" || business_payment_gateway_method[key] === null) {
          delete business_payment_gateway_method[key];
        }
      });

      const businessPaymentGatewayMethodResult =
        await paymentRepository.createOrUpdateStatusBusinessPaymentGatewayMethod(
          business_id,
          business_payment_gateway_method
        );

      if (businessPaymentGatewayMethodResult.message != undefined) {
        logEvent(
          this.$analytics,
          businessPaymentGatewayMethodResult.id == null
            ? "create-business-payment-gateway-method"
            : "update-business-payment-gateway-method",
          { result: "failed" }
        );
        state.commit("setErrorMessage", businessPaymentGatewayMethodResult);
        return;
      } else {
        logEvent(
          this.$analytics,
          businessPaymentGatewayMethodResult.id == null
            ? "create-business-payment-gateway-method"
            : "update-business-payment-gateway-method",
          { result: "success" }
        );
      }

      state.commit("setLoading", false);
      return businessPaymentGatewayMethodResult;
    },

    async updateChargesMasterMerchantPaymentGatewayMethod(
      state,
      business_payment_gateway_method
    ) {
      const paymentRepository = new PaymentRepository();
      const authRepository = new AuthRepository();

      let business_id = business_payment_gateway_method.business_id;

      business_id = business_id ?? authRepository.getSelectedRole()?.business_id;

      if (business_id == null) {
        return;
      }

      state.commit("setLoading", true);

      if (business_payment_gateway_method.formatted_master_merchant_fixed_charges != null) {
        business_payment_gateway_method.master_merchant_fixed_charges = currency.toCents(
          business_payment_gateway_method.currency ?? "MYR", business_payment_gateway_method.formatted_master_merchant_fixed_charges
        );
      }

      if (business_payment_gateway_method.formatted_master_merchant_percentage_min_charges != null) {
        business_payment_gateway_method.master_merchant_percentage_min_charges = currency.toCents(
          business_payment_gateway_method.currency ?? "MYR", business_payment_gateway_method.formatted_master_merchant_percentage_min_charges
        );
      }

      Object.keys(business_payment_gateway_method).forEach((key) => {
        if (business_payment_gateway_method[key] === "" || business_payment_gateway_method[key] === null) {
          delete business_payment_gateway_method[key];
        }
      });

      const businessPaymentGatewayMethodResult =
        await paymentRepository.updateChargesMasterMerchantPaymentGatewayMethod(
          business_id,
          business_payment_gateway_method
        );

      if (businessPaymentGatewayMethodResult.message != undefined) {
        logEvent(
          this.$analytics,
          "update-charges-master-merchant-payment-gateway-method",
          { result: "failed" }
        );
        state.commit("setErrorMessage", businessPaymentGatewayMethodResult);
        return;
      } else {
        logEvent(
          this.$analytics,
          "update-charges-master-merchant-payment-gateway-method",
          { result: "success" }
        );
      }

      state.commit("setLoading", false);
      return businessPaymentGatewayMethodResult;
    },

    async getListPaymentLinksForSupport(state, queryParams = "") {
      state.commit("setApiPaginationLoading", true);
      const paymentRepository = new PaymentRepository();
      const paymentLinkResult =
        await paymentRepository.getPaymentLinkForSupport(queryParams);

      if (paymentLinkResult.message != null) {
        logEvent(this.$analytics, "get-list-payment-link-support", {
          result: "failed",
        });
        state.commit("setErrorMessage", paymentLinkResult);
      } else {
        logEvent(this.$analytics, "get-list-payment-link-support", {
          result: "success",
        });
      }

      state.commit("setApiPaginationLoading", false);
      state.commit("setApiPaginationResponse", paymentLinkResult);

      return paymentLinkResult;
    },

    async getPaymentMethodOptionsByBusinessAndPaymentMethodId(state, data) {

      const paymentRepository = new PaymentRepository();

      state.commit("setLoading", true);

      const onlineBankByBusinessResult = await paymentRepository.getPaymentMethodOptionsByBusinessAndPaymentMethodId(
        data.business_id,
        data.payment_method_id
      );

      if (onlineBankByBusinessResult.message != undefined) {
        logEvent(
          this.$analytics,
          "get-online-bank-by-business",
          { result: "failed" }
        );
        state.commit("setErrorMessage", onlineBankByBusinessResult);
        return;
      } else {
        logEvent(
          this.$analytics,
          "get-online-bank-by-business",
          { result: "success" }
        );
      }

      const banks = [];
      onlineBankByBusinessResult.data?.forEach((bank) => {
        banks.push({
          id: bank.code,
          name: bank.display_name,
          icon: bank.logo_url,
        });
      });

      state.commit("setLoading", false);
      state.commit("setOnlineBanks", banks);

      return onlineBankByBusinessResult.data;
    },

    async retrieveBusinessPaymentGatewayConfig(state, data) {
      const paymentRepository = new PaymentRepository();

      if (
        data.business_id == null ||
        data.payment_gateway_id == null ||
        data.channel == null
      ) {
        return;
      }

      state.commit("setLoading", true);

      const paymentGatewayConfigIndexResult = await paymentRepository.indexPaymentGatewayConfig(
        data.business_id,
        data.payment_gateway_id,
        data.channel
      );

      if (paymentGatewayConfigIndexResult.message != undefined) {
        logEvent(this.$analytics, "payment-gateway-config-index", { result: "failed" });
        state.commit("setErrorMessage", paymentGatewayConfigIndexResult);
      } else {
        logEvent(this.$analytics, "payment-gateway-config-index", { result: "success" });
        state.commit("setBusinessPaymentGatewayConfig", paymentGatewayConfigIndexResult.data);
      }

      state.commit("setLoading", false);

      return paymentGatewayConfigIndexResult;
    },

    async createOrUpdateBusinessPaymentGatewayConfig(state, businessPaymentGatewayConfig) {
      const paymentRepository = new PaymentRepository();

      if (businessPaymentGatewayConfig.business_id == null ||
        businessPaymentGatewayConfig.payment_gateway_id == null ||
        businessPaymentGatewayConfig.channel == null
      ) {
        return;
      }

      if (businessPaymentGatewayConfig.payment_gateway_id == 1 && businessPaymentGatewayConfig.channel == PaymentChannel.FPX) {
        if (
          !businessPaymentGatewayConfig.fpx_certificate ||
          !businessPaymentGatewayConfig.private_key ||
          !businessPaymentGatewayConfig.csr_key
        ) {
          return;
        }
      }

      state.commit("setLoading", true);

      const paymentGatewayConfigResult = await paymentRepository.createOrUpdatePaymentGatewayConfig(
        businessPaymentGatewayConfig.business_id,
        businessPaymentGatewayConfig.payment_gateway_id,
        businessPaymentGatewayConfig.channel,
        businessPaymentGatewayConfig
      );

      if (paymentGatewayConfigResult.message != undefined) {
        logEvent(
          this.$analytics,
          businessPaymentGatewayConfig._id == null
            ? "payment-gateway-config-create"
            : "payment-gateway-config-update",
          { result: "failed" }
        );
        state.commit("setErrorMessage", paymentGatewayConfigResult);

      } else {
        logEvent(
          this.$analytics,
          businessPaymentGatewayConfig._id == null
            ? "payment-gateway-config-create"
            : "payment-gateway-config-update",
          { result: "success" }
        );
        state.commit("setBusinessPaymentGatewayConfig", paymentGatewayConfigResult.data);
      }

      state.commit("setLoading", false);

      return paymentGatewayConfigResult;
    },

    async uploadBusinessPaymentGatewayConfig(state, data) {
      const paymentRepository = new PaymentRepository();

      if (data.business_id == null ||
        data.payment_gateway_id == null ||
        data.channel == null ||
        data.type == null ||
        data.file == null
      ) {
        return;
      }

      state.commit("setLoading", true);

      const uploadFileResult = await paymentRepository.uploadPaymentGatewayConfig(
        data.business_id,
        data.payment_gateway_id,
        data.channel,
        data.type,
        data.file
      );

      if (uploadFileResult.message != null) {
        logEvent(this.$analytics, "upload-secure-file", { result: "failed" });
        state.commit("setErrorMessage", uploadFileResult);
      } else {
        logEvent(this.$analytics, "upload-secure-file", {
          result: "success",
        });
      }

      state.commit("setLoading", false);

      return uploadFileResult;
    },


    async applyForCC(state) {
      const paymentRepository = new PaymentRepository();
      const authRepository = new AuthRepository();

      const business_id = authRepository.getSelectedRole()?.business_id;

      if (business_id == null) {
        return;
      }

      const applyForCCResult = await paymentRepository.applyForCC(
        business_id
      );

      if (applyForCCResult.message != undefined) {
        logEvent(this.$analytics, "apply-cc", {
          result: "failed",
        });
        state.commit("setErrorMessage", applyForCCResult);
        return;
      } else {
        logEvent(this.$analytics, "apply-cc", {
          result: "success",
        });
      }

      return applyForCCResult;
    },

    async getTotalPaid(state) {
      const paymentRepository = new PaymentRepository();
      const authRepository = new AuthRepository();

      const business_id = authRepository.getSelectedRole()?.business_id;

      if (business_id == null) {
        return;
      }

      state.commit("setLoading", true);

      let totalPaidResult = await paymentRepository.totalPaid(business_id);

      if (checkRoleMasterBusiness.includes(authRepository.getSelectedRole()?.role_id)) {
        totalPaidResult = await paymentRepository.totalPaidMasterBusiness(
          business_id
        );
      }

      if (totalPaidResult.message != undefined) {
        logEvent(this.$analytics, "wallet-balance", { result: "failed" });
        state.commit("setErrorMessage", totalPaidResult);
        return;
      } else {
        logEvent(this.$analytics, "wallet-balance", { result: "success" });
      }

      state.commit("setLoading", false);
      state.commit("setTotalPaid", totalPaidResult.data);
      return totalPaidResult;
    },

    async cronPayout(state) {

      state.commit("setLoading", true);

      const paymentRepository = new PaymentRepository();
      const cronPayout = await paymentRepository.cronPayout();

      if (cronPayout.message != undefined) {
        logEvent(this.$analytics, "cron-payout", { result: "failed" });
        state.commit("setErrorMessage", cronPayout);
      } else {
        logEvent(this.$analytics, "cron-payout", { result: "success" });
      }

      state.commit("setLoading", false);

      return cronPayout;
    },

    async cronSettlement(state) {

      state.commit("setLoading", true);

      const paymentRepository = new PaymentRepository();
      const settlementPayout = await paymentRepository.cronSettlement();

      if (settlementPayout.message != undefined) {
        logEvent(this.$analytics, "cron-settlement", { result: "failed" });
        state.commit("setErrorMessage", settlementPayout);
      } else {
        logEvent(this.$analytics, "cron-settlement", { result: "success" });
      }

      state.commit("setLoading", false);

      return settlementPayout;
    },

    async cronMasterBusinessSettlement(state, data) {

      state.commit("setLoading", true);

      const paymentRepository = new PaymentRepository();
      const settlementPayout = await paymentRepository.cronMasterBusinessSettlement(
        data.start_date,
        data.end_date
      );

      if (settlementPayout.message != undefined) {
        logEvent(this.$analytics, "cron-master-business-settlement", { result: "failed" });
        state.commit("setErrorMessage", settlementPayout);
      } else {
        logEvent(this.$analytics, "cron-master-business-settlement", { result: "success" });
      }

      state.commit("setLoading", false);

      return settlementPayout;
    },
    
    async cronPaymentProfit(state, data) {

      state.commit("setLoading", true);

      const paymentRepository = new PaymentRepository();
      const cronPaymentProfit = await paymentRepository.cronPaymentProfit(
        data.start_date,
        data.end_date
      );

      if (cronPaymentProfit.message != undefined) {
        logEvent(this.$analytics, "cron-payment-profit", { result: "failed" });
        state.commit("setErrorMessage", cronPaymentProfit);
      } else {
        logEvent(this.$analytics, "cron-payment-profit", { result: "success" });
      }

      state.commit("setLoading", false);

      return cronPaymentProfit;
    },

    async getSupportPaymentLinkExports(state, queryParams = "") {
      const paymentRepository = new PaymentRepository();

      state.commit("setLoading", true);

      const paymentResult = await paymentRepository.getSupportPaymentLinkExports(queryParams);

      if (paymentResult.message != null) {
        logEvent(this.$analytics, "get-payment-link-exports", { result: "failed" });
        state.commit("setErrorMessage", paymentResult);
      } else {
        logEvent(this.$analytics, "get-payment-link-exports", { result: "success" });
        state.commit("setPaymentLink", paymentResult.data);
      }

      state.commit("setLoading", false);

      return paymentResult;
    },
  },
};

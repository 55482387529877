<template>
  <app-form-select
    id="states"
    :datas="states"
    :labelText="labelText ?? $t('general.state')"
    :serverMessage="serverMessage"
    :loading="loading"
    :errorMessage="errorMessage"
    :showLabel="showLabel"
    :allowFilter="allowFilter"
    :placeholder="placeholder"
    v-on:valueSelected="onValueSelected"
    v-on:retry="getStates"
    v-model="checkedValue"
  />
</template>

<script>
export default {
  props: {
    labelText: {
      type: String,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    showIcon: {
      type: Boolean,
      default: false,
    },
    allowFilter: {
      type: Boolean,
      default: true,
    },
    errorMessage: {
      type: Array,
      default: () => [],
    },
    modelValue: {
      type: Object,
      default: () => {},
    },
    country_id: {
      type: Number,
      default: 0,
    },
    placeholder: {
      type: String,
      default: "",
    },
  },

  computed: {
    checkedValue: {
      get() {
        if (this.$lodash.isEmpty(this.states)) {
          return {};
        }
        return this.modelValue ?? {};
      },
      set(value) {
        this.onValueSelected(value);
      },
    },

    states() {
      return this.$store.getters["referenceStore/states"];
    },

    loading() {
      return this.$store.getters["referenceStore/loading"];
    },

    serverMessage() {
      return this.$store.getters["referenceStore/message"] ?? "";
    },
  },

  watch: {
    country_id: function (newVal, oldValue) {
      this.getStates();
    },
    states(states) {
      if (this.$lodash.isEmpty(states)) {
        this.onValueSelected(null);
      }
    },
  },

  async mounted() {
    this.getStates();
  },

  methods: {
    async getStates() {
      this.$store.commit("referenceStore/resetState");
      this.$store.dispatch("referenceStore/getStates", this.country_id);
    },

    onValueSelected(value) {
      this.$emit("update:modelValue", value);
    },
  },
};
</script>



import client from "@/data/base-client";
import WithAuthRepository from "./with-auth-repository";

export default class BusinessSupportDetailsRepository extends WithAuthRepository {
  constructor() {
    super();
  }

  async createOrUpdate(support_detail) {
    const response =
      support_detail.id == null
        ? await client.post(
          "/businesses/" + support_detail.business_id + "/support-details",
          support_detail,
          this.getToken()
        )
        : await client.put(
          "/businesses/" +
          support_detail.business_id +
          "/support-details/" +
          support_detail.id,
          support_detail,
          this.getToken()
        );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async createOrUpdateMasterBusiness(masterBusinessId, support_detail) {
    const response =
      support_detail.id == null
        ? await client.post(
          "/master-businesses/" + masterBusinessId + "/businesses/" + support_detail.business_id + "/support-details",
          support_detail,
          this.getToken()
        )
        : await client.put(
          "/master-businesses/" + masterBusinessId + "/businesses/" + support_detail.business_id + "/support-details/" +
          support_detail.id,
          support_detail,
          this.getToken()
        );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }
}

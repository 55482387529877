import TestModeDao from "../dao/test-mode-dao";

export default class TestModeRepository {
    constructor() {
        this.testModeDao = new TestModeDao()
    }

    setTestMode(value) {
        this.testModeDao.saveTestMode(value);
    }

    removeTestMode() {
        this.testModeDao.removeTestMode();
    }

    getTestMode() {
        return this.testModeDao.isTestMode();
    }
}
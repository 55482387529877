<template>
  <NotificationGroup group="success">
    <div
      class="
        fixed
        inset-x-0
        bottom-0
        flex
        items-start
        justify-center
        p-6
        px-4
        py-40
        pointer-events-none
        z-50
      "
    >
      <div class="w-full max-w-sm">
        <Notification
          v-slot="{ notifications }"
          enter="transform ease-out duration-300 transition"
          enter-from="translate-y-2 opacity-0 sm:translate-y-8 sm:translate-x-0"
          enter-to="translate-y-0 opacity-100 sm:translate-x-0"
          leave="transition ease-in duration-500"
          leave-from="opacity-100"
          leave-to="opacity-0"
          move="transition duration-500"
          move-delay="delay-300"
          :max-notifications="1"
        >
          <div
            class="
              flex
              w-full
              max-w-sm
              mx-auto
              mt-4
              overflow-hidden
              bg-green-50
              rounded-lg
              shadow-md
            "
            v-for="notification in notifications"
            :key="notification.id"
          >
            <div class="flex items-center justify-center bg-green-5 ml-4">
              <app-icon class="w-6 h-6 text-green-500" name="CheckCircleIcon" />
            </div>

            <div class="px-4 py-4 -mx-3">
              <div class="mx-3">
                <span class="text-gray-900">{{ notification.title }}</span>
              </div>
            </div>
          </div>
        </Notification>
      </div>
    </div>
  </NotificationGroup>

  <NotificationGroup group="error">
    <div
      class="
        fixed
        inset-x-0
        bottom-0
        flex
        items-start
        justify-center
        p-6
        px-4
        py-40
        pointer-events-none
        z-50
      "
    >
      <div class="w-full max-w-sm">
        <Notification
          v-slot="{ notifications }"
          enter="transform ease-out duration-300 transition"
          enter-from="translate-y-2 opacity-0 sm:translate-y-8 sm:translate-x-0"
          enter-to="translate-y-0 opacity-100 sm:translate-x-0"
          leave="transition ease-in duration-500"
          leave-from="opacity-100"
          leave-to="opacity-0"
          move="transition duration-500"
          move-delay="delay-300"
          :max-notifications="1"
        >
          <div
            class="
              flex
              w-full
              max-w-sm
              mx-auto
              mt-4
              overflow-hidden
              bg-white
              rounded-lg
              shadow-md
            "
            v-for="notification in notifications"
            :key="notification.id"
          >
            <div class="flex items-center justify-center w-12 bg-red-500">
              <app-icon
                class="w-6 h-6 text-white"
                name="ExclamationCircleIcon"
              />
            </div>

            <div class="px-4 py-2 -mx-3">
              <div class="mx-3">
                <span class="font-semibold text-red-500">{{
                  notification.title
                }}</span>
                <p class="text-sm text-gray-600">{{ notification.text }}</p>
              </div>
            </div>
          </div>
        </Notification>
      </div>
    </div>
  </NotificationGroup>
</template>
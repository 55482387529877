<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.1344 0H4.55036C4.08864 0 3.71436 0.374286 3.71436 1.10057V31.4286C3.71436 31.6257 4.08864 32 4.55036 32H27.4498C27.9115 32 28.2858 31.6257 28.2858 31.4286V7.416C28.2858 7.01829 28.2326 6.89029 28.1389 6.796L21.4898 0.146857C21.3955 0.0531429 21.2675 0 21.1344 0Z"
      fill="#E9E9E0"
    />
    <path
      d="M21.4287 0.0859375V6.85679H28.1996L21.4287 0.0859375Z"
      fill="#D9D7CA"
    />
    <path
      d="M10.8179 10.8575C12.2598 10.8575 13.4287 9.68854 13.4287 8.2466C13.4287 6.80466 12.2598 5.63574 10.8179 5.63574C9.37595 5.63574 8.20703 6.80466 8.20703 8.2466C8.20703 9.68854 9.37595 10.8575 10.8179 10.8575Z"
      fill="#F3D55B"
    />
    <path
      d="M3.71436 22.2856H10.0001H28.2858V15.9999L22.5715 10.5713L16.5715 17.1427L13.4384 14.0096L3.71436 22.2856Z"
      fill="#26B99A"
    />
    <path
      d="M27.4498 32.0004H4.55036C4.08864 32.0004 3.71436 31.6261 3.71436 31.1644V22.2861H28.2858V31.1644C28.2858 31.6261 27.9115 32.0004 27.4498 32.0004Z"
      fill="#14A085"
    />
    <path
      d="M12.2434 24.3711V28.8557C12.2434 29.1265 12.1937 29.3545 12.0948 29.5391C11.996 29.7237 11.8628 29.8722 11.6965 29.9842C11.5297 30.0962 11.3383 30.1745 11.1223 30.2185C10.9057 30.2631 10.6834 30.2854 10.4543 30.2854C10.3394 30.2854 10.2051 30.2734 10.052 30.25C9.89826 30.2265 9.7394 30.1905 9.5754 30.1408C9.4114 30.0911 9.25369 30.0351 9.10283 29.9728C8.9514 29.9105 8.82397 29.8402 8.71997 29.762L9.11826 29.1294C9.17026 29.1654 9.24454 29.2037 9.34111 29.2425C9.43711 29.2814 9.54283 29.318 9.65768 29.3517C9.77197 29.386 9.89197 29.4151 10.0171 29.4414C10.1423 29.4677 10.2594 29.4802 10.3685 29.4802C10.6445 29.4802 10.8645 29.4265 11.0285 29.3202C11.1925 29.214 11.2794 29.0322 11.2903 28.7774V24.3711H12.2434Z"
      fill="white"
    />
    <path
      d="M14.5793 30.2466H13.6416V24.4883H15.2976C15.5422 24.4883 15.7845 24.5271 16.0239 24.6054C16.2633 24.6837 16.4782 24.8009 16.6685 24.9569C16.8587 25.1129 17.0125 25.302 17.1296 25.5231C17.2467 25.7443 17.3056 25.9929 17.3056 26.2694C17.3056 26.5614 17.2559 26.8254 17.157 27.0626C17.0582 27.2997 16.9199 27.4991 16.7427 27.6603C16.5656 27.8214 16.3519 27.9466 16.1022 28.0351C15.8525 28.1237 15.5759 28.1677 15.2742 28.1677H14.5787V30.2466H14.5793ZM14.5793 25.1997V27.4809H15.4387C15.553 27.4809 15.6662 27.4614 15.7787 27.422C15.8907 27.3831 15.9936 27.3191 16.0873 27.2306C16.181 27.142 16.2565 27.0186 16.3136 26.8597C16.3707 26.7009 16.3993 26.5043 16.3993 26.27C16.3993 26.1763 16.3862 26.0677 16.3605 25.946C16.3342 25.8237 16.281 25.7066 16.2005 25.5946C16.1193 25.4826 16.0062 25.3889 15.8605 25.3134C15.7142 25.2374 15.5216 25.1997 15.2822 25.1997H14.5793Z"
      fill="white"
    />
    <path
      d="M22.5715 27.2778V29.5041C22.4515 29.6555 22.3178 29.7784 22.1692 29.8749C22.0206 29.9715 21.8646 30.0509 21.7006 30.1132C21.5366 30.1755 21.3675 30.2201 21.1926 30.2458C21.0178 30.2732 20.8452 30.2858 20.6732 30.2858C20.3292 30.2858 20.0132 30.2235 19.724 30.0984C19.4349 29.9732 19.1823 29.7887 18.9663 29.5435C18.7503 29.2984 18.5806 28.9915 18.4583 28.6218C18.336 28.2521 18.2749 27.8247 18.2749 27.3407C18.2749 26.8567 18.336 26.4304 18.4583 26.0635C18.5806 25.6961 18.7498 25.3904 18.9663 25.1458C19.1823 24.9007 19.436 24.7149 19.728 24.5869C20.0195 24.4589 20.3349 24.3955 20.6732 24.3955C20.9858 24.3955 21.2772 24.4475 21.548 24.5515C21.8189 24.6561 22.0606 24.8121 22.2743 25.0201L21.6258 25.5984C21.5006 25.4469 21.3572 25.3378 21.196 25.2704C21.0343 25.2029 20.868 25.1686 20.696 25.1686C20.5035 25.1686 20.3195 25.2046 20.1452 25.2778C19.9703 25.3509 19.8143 25.4744 19.6766 25.6486C19.5383 25.8235 19.4303 26.0481 19.3526 26.3247C19.2749 26.6012 19.2326 26.9389 19.2275 27.3401C19.2326 27.7309 19.2732 28.0692 19.3486 28.3555C19.424 28.6418 19.528 28.8761 19.6612 29.0584C19.7943 29.2407 19.9452 29.3761 20.1143 29.4647C20.2835 29.5532 20.4618 29.5972 20.6498 29.5972C20.7069 29.5972 20.7835 29.5932 20.88 29.5852C20.976 29.5772 21.0726 29.5647 21.1692 29.5464C21.2652 29.5281 21.3578 29.5035 21.4463 29.4721C21.5349 29.4407 21.6 29.3967 21.6418 29.3395V27.9178H20.6652V27.2772H22.5715V27.2778Z"
      fill="white"
    />
  </svg>
</template>
<template>
  <app-full-modal title="Withdraw History">
    <template v-slot:body>
      {{ withdraw }}
    </template>
  </app-full-modal>
</template>

<script>
export default {
  data() {
    return {};
  },

  props: {
    withdraw: {
      type: Object,
      default: null,
    },
  },
  computed: {
    loading() {
      return this.$store.getters["walletStore/loading"];
    },
  },
  methods: {},
};
</script>
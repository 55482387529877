<template>
  <svg
    class="w-8 h-4"
    :class="$attrs.class"
    viewBox="0 0 26 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0)">
      <path
        d="M12.6413 15.3051H23.0799C23.829 15.3467 24.5655 15.0988 25.137 14.6126C25.7085 14.1264 26.0712 13.4391 26.15 12.693L27.807 3.29726C28.0994 1.63059 27.3294 0.685181 25.6627 0.685181H15.2144C14.4652 0.643508 13.7287 0.891423 13.1573 1.37763C12.5858 1.86383 12.2231 2.5511 12.1442 3.29726L10.4873 12.693C10.1949 14.3694 10.9649 15.3051 12.6413 15.3051Z"
        fill="#D3CBE4"
      />
      <path
        d="M2.40736 15.3051H12.8362C13.5854 15.3467 14.3219 15.0988 14.8933 14.6126C15.4648 14.1264 15.8275 13.4391 15.9064 12.693L17.5633 3.29726C17.8654 1.63059 17.0858 0.685181 15.4191 0.685181H4.98047C4.23131 0.643508 3.49483 0.891423 2.92336 1.37763C2.35189 1.86383 1.98915 2.5511 1.91027 3.29726L0.253399 12.693C-0.0389987 14.3694 0.730944 15.3051 2.40736 15.3051Z"
        fill="#623F98"
      />
      <path d="M27.076 7.62476H7.33917V8.38499H27.076V7.62476Z" fill="white" />
      <path
        d="M25.6627 0.685181H4.98047C4.23131 0.643508 3.49483 0.891423 2.92336 1.37763C2.35189 1.86383 1.98915 2.5511 1.91027 3.29726L0.253399 12.693C-0.0389987 14.3694 0.730944 15.3051 2.40736 15.3051H23.0799C23.829 15.3467 24.5655 15.0988 25.137 14.6126C25.7085 14.1264 26.0712 13.4391 26.15 12.693L27.807 3.29726C28.0994 1.63059 27.3294 0.685181 25.6627 0.685181Z"
        stroke="white"
        stroke-width="0.39"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="28.0604"
          height="15"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
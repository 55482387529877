<template>
  <div v-if="loading" class="fixed z-30 inset-0 overflow-y-auto">
    <div class="flex items-center justify-center h-full opacity-80 bg-white">
      <app-icon-loading customClass="h-5 w-5 mr-2 text-primary" />
      <p>{{ text ?? $t("general.loading") }}</p>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: null,
    },
  },
};
</script>
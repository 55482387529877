<template>
  <app-full-modal :title="$t('invoice.action.view_invoice') + ' ' + invoice.invoice_no" width="md:w-10/12 lg:w-9/12 xl:w-7/12">
    <template v-slot:body>
      
      <div v-if="loading">
        <app-icon-loading iconColor="primary" class="mx-auto mt-10" />
      </div>

      <div v-else-if="invoice">
        <Invoice-pdf :previewMode="true"> </Invoice-pdf>
      </div>
    </template>
  </app-full-modal>
</template>

<script>
  import InvoicePdf from "@/views/pages/main/invoice/invoice-pdf.vue";

export default {
  components: {
    InvoicePdf,
  },
  props: {
    invoice: {
      type: Object,
      default: null,
    },
  },
  computed: {
    loading() {
      return this.$store.getters["invoiceStore/loading"];
    },
  },
};
</script>

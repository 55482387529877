import AuthRepository from "../../data/repository/auth-repository";
import DashboardRepository from "../../data/repository/dashboard-repository";
import baseStore from "../base-store";
import Role from "@/utils/const/role";
import { logEvent } from "firebase/analytics";

const checkRoleMasterBusiness = [
  Role.MASTER_BUSINESS_OWNER,
  Role.MASTER_BUSINESS_ADMIN,
  Role.MASTER_BUSINESS_MANAGER,
  Role.MASTER_BUSINESS_DEVELOP,
  Role.MASTER_BUSINESS_SUPPORT,
]

export default {
  namespaced: true,
  state: {
    ...baseStore.state,
    currency: 'MYR',
    dateFrom: null,
    dateTo: null,
    overviewLoading: false,
    overviewMessage: null,
    accountSummaryLoading: false,
    accountSummaryMessage: null,
    accountTransactionSummaryLoading: false,
    accountTransactionSummaryMessage: null,
    operationSummaryLoading: false,
    operationSummaryMessage: null,
    balance: 0.0,
    summary: {
      currency: 'MYR',
      sales: 0.0,
      net_sales: 0.0,
      balance: 0.0,
      payout: 0.0
    },
    overview: {
      currency: 'MYR',
      sales: 0.0,
      payments: 0.0,
      total_customers: 0,
      successful_payments: 0,
      average_spending: 0.0,
      net_sales: 0.0,
    },
    accountTransactionSummary: {
      online_banking_b2c: 0,
      online_banking_b2b: 0,
      credit_card: 0
    },
    accountSummary: {
      currency: 'MYR',
      total_payout_requested: {
        count: 0,
        amount: 0.0
      },
      total_payout_approved: {
        count: 0,
        amount: 0.0
      },
      total_transaction: {
        count: 0,
        amount: 0.0
      },
      total_payout: {
        count: 0,
        amount: 0.0
      },
      gross_sales: {
        online_banking_b2c: 0,
        online_banking_b2b: 0,
        credit_debit_card: 0
      },
      net_sales: {
        online_banking_b2c: 0,
        online_banking_b2b: 0,
        credit_debit_card: 0
      },
      top_sale_collection: []
    },
    operationSummary: {
      total_business_register: 0,
      total_business_created: 0,
      total_business_apporoved: 0,
      total_business_pending: 0,
      total_business_amended: 0,
      total_business_rejected: 0,
      total_payment_gateway: []
    },
    masterBusinessSummaryTotalBusiness: {
      total_business_register: 0,
      total_business_created: 0,
      total_business_apporoved: 0,
      total_business_pending: 0,
      total_business_amended: 0,
      total_business_rejected: 0,
    }
  },

  getters: {
    ...baseStore.getters,
    dateFrom(state) {
      return state.dateFrom;
    },
    dateTo(state) {
      return state.dateTo;
    },
    currency(state) {
      return state.currency;
    },
    balance(state) {
      return state.balance;
    },
    summary(state) {
      return state.summary;
    },
    overview(state) {
      return state.overview;
    },
    overviewLoading(state) {
      return state.overviewLoading;
    },
    overviewMessage(state) {
      return state.overviewMessage;
    },
    accountTransactionSummary(state) {
      return state.accountTransactionSummary;
    },
    accountTransactionSummaryLoading(state) {
      return state.accountTransactionSummaryLoading;
    },
    accountTransactionSummaryMessage(state) {
      return state.accountTransactionSummaryMessage;
    },
    accountSummary(state) {
      return state.accountSummary;
    },
    accountSummaryLoading(state) {
      return state.accountSummaryLoading;
    },
    accountSummaryMessage(state) {
      return state.accountSummaryMessage;
    },
    operationSummary(state) {
      return state.operationSummary;
    },
    operationSummaryLoading(state) {
      return state.operationSummaryLoading;
    },
    operationSummaryMessage(state) {
      return state.operationSummaryMessage;
    },
    masterBusinessSummaryTotalBusiness(state) {
      return state.masterBusinessSummaryTotalBusiness;
    },
    masterBusinessSummaryTotalBusinessLoading(state) {
      return state.masterBusinessSummaryTotalBusinessLoading;
    },
    masterBusinessSummaryTotalBusinessMessage(state) {
      return state.masterBusinessSummaryTotalBusinessMessage;
    }
  },

  mutations: {
    ...baseStore.mutations,
    setBalance(state, balance) {
      state.balance = balance;
    },
    setSummary(state, summary) {
      state.summary = summary;
    },
    setOverview(state, overview) {
      state.overview = overview;
    },
    setOverviewLoading(state, overviewLoading) {
      state.overviewLoading = overviewLoading;
    },
    setOverviewErrorMessage(state, errorResponse = { message: null, errors: [] }) {
      state.overviewMessage = errorResponse.message;
      state.errors = errorResponse.errors;
      state.loading = false;
    },
    setAccountTransactionSummary(state, accountTransactionSummary) {
      state.accountTransactionSummary = accountTransactionSummary;
    },
    setAccountTransactionSummaryLoading(state, accountTransactionSummaryLoading) {
      state.accountTransactionSummaryLoading = accountTransactionSummaryLoading;
    },
    setAccountTransactionSummaryMessage(state, errorResponse = { message: null, errors: [] }) {
      state.accountTransactionSummaryMessage = errorResponse.message;
      state.errors = errorResponse.errors;
      state.loading = false;
    },
    setAccountSummary(state, accountSummary) {
      state.accountSummary = accountSummary;
    },
    setAccountSummaryLoading(state, accountSummaryLoading) {
      state.accountSummaryLoading = accountSummaryLoading;
    },
    setAccountSummaryMessage(state, errorResponse = { message: null, errors: [] }) {
      state.accountSummaryMessage = errorResponse.message;
      state.errors = errorResponse.errors;
      state.loading = false;
    },
    setOperationSummary(state, operationSummary) {
      state.operationSummary = operationSummary;
    },
    setOperationSummaryLoading(state, operationSummaryLoading) {
      state.operationSummaryLoading = operationSummaryLoading;
    },
    setOperationSummaryMessage(state, errorResponse = { message: null, errors: [] }) {
      state.operationSummaryMessage = errorResponse.message;
      state.errors = errorResponse.errors;
      state.loading = false;
    },
    setMasterBusinessSummaryTotalBusiness(state, masterBusinessSummaryTotalBusiness) {
      state.masterBusinessSummaryTotalBusiness = masterBusinessSummaryTotalBusiness;
    },
    setMasterBusinessSummaryTotalBusinessLoading(state, masterBusinessSummaryTotalBusinessLoading) {
      state.masterBusinessSummaryTotalBusinessLoading = masterBusinessSummaryTotalBusinessLoading;
    },
    setMasterBusinessSummaryTotalBusinessMessage(state, errorResponse = { message: null, errors: [] }) {
      state.masterBusinessSummaryTotalBusinessMessage = errorResponse.message;
      state.errors = errorResponse.errors;
      state.loading = false;
    },
    setDateFrom(state, dateFrom) {
      state.dateFrom = dateFrom;
    },
    setDateTo(state, dateTo) {
      state.dateTo = dateTo;
    },
  },

  actions: {
    async retrieveBalanceDashboard(state, data) {
      const dashboardRepository = new DashboardRepository();
      const authRepository = new AuthRepository();

      let business_id = data?.business_id ?? authRepository.getSelectedRole()?.business_id;

      if (business_id == null) {
        return;
      }

      state.commit("setLoading", true);

      let balanceResult = null;

      if (checkRoleMasterBusiness.includes(authRepository.getSelectedRole()?.role_id)
        && authRepository.getSelectedRole()?.business_id == business_id) {
        balanceResult = await dashboardRepository.masterBusinessSummary(
          business_id,
          state.getters.currency,
          data.date_between
        );
      } else {
        balanceResult = await dashboardRepository.summary(
          business_id,
          state.getters.currency,
          data.date_between
        );
      }

      if (balanceResult.message != undefined) {
        logEvent(this.$analytics, "dashboard-balance", { result: "failed" });
        state.commit("setErrorMessage", balanceResult);
        return;
      } else {
        logEvent(this.$analytics, "dashboard-balance", { result: "success" });
      }

      state.commit("setLoading", false);
      state.commit("setBalance", balanceResult.data.balance);
      return balanceResult;
    },

    async retrieveSummaryDashboard(state, data) {
      const dashboardRepository = new DashboardRepository();
      const authRepository = new AuthRepository();

      let business_id = data?.business_id ?? authRepository.getSelectedRole()?.business_id;

      if (business_id == null) {
        return;
      }

      state.commit("setLoading", true);

      let summaryResult = null;

      if (checkRoleMasterBusiness.includes(authRepository.getSelectedRole()?.role_id) && authRepository.getSelectedRole()?.business_id == business_id) {
        summaryResult = await dashboardRepository.masterBusinessSummary(
          business_id,
          state.getters.currency,
          data.date_between
        );
      } else {
        summaryResult = await dashboardRepository.summary(
          business_id,
          state.getters.currency,
          data.date_between
        );
      }

      if (summaryResult.message != undefined) {
        logEvent(this.$analytics, "dashboard-summary", { result: "failed" });
        state.commit("setErrorMessage", summaryResult);
        return;
      } else {
        logEvent(this.$analytics, "dashboard-summary", { result: "success" });
      }

      state.commit("setLoading", false);
      state.commit("setSummary", summaryResult.data);
      return summaryResult;
    },

    async retrieveOverviewDashboard(state, data) {
      const dashboardRepository = new DashboardRepository();
      const authRepository = new AuthRepository();

      let business_id = authRepository.getSelectedRole()?.business_id;

      if (checkRoleMasterBusiness.includes(authRepository.getSelectedRole()?.role_id)) {
        business_id = data?.business_id;
      }

      if (business_id == null) {
        return;
      }

      state.commit("setOverviewLoading", true);

      const overviewResult = await dashboardRepository.overview(
        business_id,
        state.getters.currency,
        data.date_between
      );

      if (overviewResult.message != undefined) {
        logEvent(this.$analytics, "dashboard-overview", { result: "failed" });
        state.commit("setOverviewErrorMessage", overviewResult);
        return;
      } else {
        logEvent(this.$analytics, "dashboard-overview", { result: "success" });
      }

      state.commit("setOverviewLoading", false);
      state.commit("setOverview", overviewResult.data);
      return overviewResult;
    },

    async retrieveAccountDashboardSummary(state, date_between) {
      const dashboardRepository = new DashboardRepository();

      state.commit("setAccountSummaryLoading", true);

      const summaryResult = await dashboardRepository.accountSummary(
        state.getters.currency,
        date_between
      );

      if (summaryResult.message != undefined) {
        logEvent(this.$analytics, "dashboard-account-summary", { result: "failed" });
        state.commit("setAccountSummaryMessage", summaryResult);
        return;
      } else {
        logEvent(this.$analytics, "dashboard-account-summary", { result: "success" });
      }

      state.commit("setAccountSummaryLoading", false);
      state.commit("setAccountSummary", summaryResult.data);
      return summaryResult;
    },

    async retrieveAccountDashboardTransactionSummary(state, date_between) {
      const dashboardRepository = new DashboardRepository();

      state.commit("setAccountTransactionSummaryLoading", true);

      const transactionSummaryResult = await dashboardRepository.accountTransactionSummary(
        state.getters.currency,
        date_between
      );

      if (transactionSummaryResult.message != undefined) {
        logEvent(this.$analytics, "account-dashboard-transaction-summary", { result: "failed" });
        state.commit("setAccountTransactionSummaryMessage", transactionSummaryResult);
        return;
      } else {
        logEvent(this.$analytics, "account-dashboard-transaction-summary", { result: "success" });
      }

      state.commit("setAccountTransactionSummaryLoading", false);
      state.commit("setAccountTransactionSummary", transactionSummaryResult.data);
      return transactionSummaryResult;
    },

    async retrieveOperationDashboardSummary(state, date_between) {
      const dashboardRepository = new DashboardRepository();

      state.commit("setOperationSummaryLoading", true);

      const summaryResult = await dashboardRepository.operationSummary(
        state.getters.currency,
        date_between
      );

      if (summaryResult.message != undefined) {
        logEvent(this.$analytics, "dashboard-operation-summary", { result: "failed" });
        state.commit("setOperationSummaryMessage", summaryResult);
        return;
      } else {
        logEvent(this.$analytics, "dashboard-operation-summary", { result: "success" });
      }

      state.commit("setOperationSummaryLoading", false);
      state.commit("setOperationSummary", summaryResult.data);
      return summaryResult;
    },

    async retrieveMasterBusinessDashboardSummaryTotalBusiness(state, date_between) {
      const dashboardRepository = new DashboardRepository();
      const authRepository = new AuthRepository();

      const business_id = authRepository.getSelectedRole()?.business_id;

      if (business_id == null) {
        return;
      }

      state.commit("setMasterBusinessSummaryTotalBusinessLoading", true);

      const summaryResult = await dashboardRepository.masterBusinessSummaryTotalBusiness(
        business_id,
        state.getters.currency,
        date_between
      );

      if (summaryResult.message != undefined) {
        logEvent(this.$analytics, "dashboard-master-business-summary-total-business", { result: "failed" });
        state.commit("setErrorMessage", summaryResult);
        return;
      } else {
        logEvent(this.$analytics, "dashboard-master-business-summary-total-business", { result: "success" });
      }

      state.commit("setMasterBusinessSummaryTotalBusinessLoading", false);
      state.commit("setMasterBusinessSummaryTotalBusiness", summaryResult.data);
      return summaryResult;
    },
  },
}

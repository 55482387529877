<template>
  <svg
    width="64"
    height="65"
    viewBox="0 0 64 65"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M63.89 12.2168C63.89 5.95291 58.8121 0.875 52.5482 0.875H11.7176C5.45365 0.875 0.375732 5.95291 0.375732 12.2168V53.0475C0.375732 59.3114 5.45365 64.3893 11.7176 64.3893H52.5482C58.8121 64.3893 63.89 59.3114 63.89 53.0475V12.2168ZM13.9859 17.8878C13.9859 16.0086 15.4197 14.4852 17.1883 14.4852H47.0774C48.8461 14.4852 50.2798 16.0086 50.2798 17.8878C50.2798 19.7669 48.8461 21.2903 47.0774 21.2903H17.1883C15.4197 21.2903 13.9859 19.7669 13.9859 17.8878ZM13.9859 31.498C13.9859 29.6188 15.4197 28.0954 17.1883 28.0954H33.4672C35.2358 28.0954 36.6696 29.6188 36.6696 31.498C36.6696 33.3771 35.2358 34.9005 33.4672 34.9005H17.1883C15.4197 34.9005 13.9859 33.3771 13.9859 31.498ZM17.1883 41.7056H47.0774C48.8461 41.7056 50.2798 43.229 50.2798 45.1082C50.2798 46.9873 48.8461 48.5107 47.0774 48.5107H17.1883C15.4197 48.5107 13.9859 46.9873 13.9859 45.1082C13.9859 43.229 15.4197 41.7056 17.1883 41.7056Z"
      fill="#52525B"
    />
  </svg>
</template>
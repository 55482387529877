<template>
  <app-button-outline class="bg-white" padding="px-2 py-1" @click.stop="copyText">
    <app-icon-outline name="DocumentDuplicateIcon" class="h-5 w-5 text-gray-400" />
    <p class="ml-1 text-xs">
      {{ title ?? $t("payment.detail.copy_link") }}
    </p>
  </app-button-outline>
</template>

<script>
export default {
  props: {
    textToCopy: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
  },
  methods: {
    copyText() {
      const clipboardData =
        event.clipboardData ||
        window.clipboardData ||
        event.originalEvent?.clipboardData ||
        navigator.clipboard;

      clipboardData.writeText(this.textToCopy);

      this.$notify(
        {
          group: "success",
          title: this.$t("general.copied"),
        },
        2000
      );
    },
  },
};
</script>
<template>
  <div class="flex flex-col p-3 z-0">

    <!-- Table -->
    <div class="flex justify-center transition-all delay-300">

      <!-- Loading -->
      <div
        class="w-full h-full bg-white flex flex-col items-center justify-center"
        :class="[loading ? 'cursor-wait' : 'cursor-default']"
        v-if="loading"
      >
        <app-icon-loading iconColor="black" customClass="h-16 w-16" />
        <span class="mt-8 text-lg">{{ $t("datatable.loading_data") }}</span>
      </div>

      <!-- Data Empty -->
      <div
        v-else-if="isDataEmpty"
        class="flex h-64 m-20 justify-center items-center"
      >
        <div class="flex flex-col items-center">
          <app-icon-empty-placeholder />
          <span class="text-center font-bold text-lg mt-4">
            {{ emptyTitleText }}
          </span>
          <span class="text-center mt-1 mb-6">
            {{ emptyDescriptionText }}
          </span>
          <app-button
            width="w-80"
            @click="emptyButtonClicked"
            :showf70Icon="false"
            :loading="emptyButtonLoading"
            v-if="showEmptyButton"
          >
            <template v-slot:icon>
              <app-icon name="PlusCircleIcon" class="h-5 w-5 mr-2" />
            </template>
            <template v-slot:default>{{ emptyButtonText }}</template>
          </app-button>
        </div>
      </div>

      <!-- Data Table -->
      <div v-else class="py-2 align-middle inline-block min-w-full">
        <div class="flex flex-col">
          <div class="overflow-x-auto mt-2">
            <table
              class="
                min-w-full
                divide-y divide-divider-gray
                border-b border-divider-gray
              "
            >
              <thead class="bg-white">
                <tr>
                  <th v-if="selectable">
                    <app-form-checkbox
                      v-model="allSelected"
                      @onClick="selectAll"
                    />
                  </th>
                  <slot name="header" />
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-divider-gray">
                <tr
                  v-for="model in datas"
                  :key="model"
                  class="hover:bg-gray-50 cursor-pointer"
                  @click="$emit('on-item-click', model)"
                >
                  <td v-if="selectable">
                    <app-form-checkbox :value="model" v-model="selected" />
                  </td>
                  <slot name="body" :model="model" />
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {

  data() {
    return {

      allSelected: false,
    };
  },

  props: {

    emptyTitle: {
      type: String,
      default: "",
    },
    emptyDescription: {
      type: String,
      default: "",
    },
    emptyButton: {
      type: String,
      default: "",
    },
    showEmptyButton: {
      type: Boolean,
      default: true,
    },

    loading: {
      type: Boolean,
      default: false,
    },
    datas: {
      type: Object,
    },

    emptyButtonLoading: {
      type: Boolean,
      default: false,
    },


    selectable: {
      type: Boolean,
      default: false,
    },
  },

  computed: {


    emptyTitleText: function () {
      return this.emptyTitle === ""
        ? this.$t("datatable.empty_item_title")
        : this.emptyTitle;
    },
    emptyDescriptionText: function () {
      return this.emptyTitle === ""
        ? this.$t("datatable.empty_item_description")
        : this.emptyDescription;
    },
    emptyButtonText: function () {
      return this.emptyButton === ""
        ? this.$t("datatable.empty_button_text")
        : this.emptyButton;

    },

    isDataEmpty: function () {
      return this.$lodash.isEmpty(this.datas);
    },
  },
  methods: {

    emptyButtonClicked() {
      this.$emit("empty-button-clicked");
    },

    
    refresh() {
      this.$emit("refresh", true);
    },

    selectAll() {
      this.selected = [];

      if (!this.allSelected) {
        this.datas.forEach((model) => {
          this.selected.push(model);
        });
      }
    },
  },
};
</script>
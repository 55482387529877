<template>
  <div :class="{ flex: inline }">
    <label
      v-if="showLabel && labelText"
      id="listbox-label"
      class="block text-sm font-light text-gray-700"
      :class="inline ? ['mr-3', 'pt-2'] : 'mb-1'"
    >
      {{ labelText }}
    </label>
    <div
      class="relative"
      :class="{ 'w-1/2': inline }"
      v-click-outside="closeDropdown"
    >
      <span class="inline-block w-full" v-show="!alwaysOpen">
        <button
          type="button"
          @click="openDropdown"
          aria-haspopup="listbox"
          aria-expanded="true"
          aria-labelledby="listbox-label"
          class="
            w-full
            relative
            h-10
            pr-10
            md:pr-2
            pl-2
            text-sm
            bg-white
            rounded-md
            shadow-sm
            focus:ring-primary
            border-none
            ring-1
            focus:ring-2 focus:outline-none
          "
          :class="[
            $attrs.inputclass,
            errorMessage.length > 0 ? 'ring-error' : 'ring-bordercolor',
            { 'bg-gray-100': disabled, 'mt-2': !inline },
          ]"
          :disabled="disabled"
        >
          <div class="flex items-center space-x-3 h-6">
            <img
              v-if="showIcon && Object.keys(this.selected ?? {}).length > 0"
              :src="selected.icon ?? selected.flag"
              alt=""
              class="flex-shrink-0 h-7 w-7 object-contain"
            />
            <span
              class="block truncate text-sm ml-1 mr-1"
              :class="[
                Object.keys(this.selected ?? {}).length == 0 ||
                this.selected.id == 0
                  ? 'text-gray-500'
                  : 'text-black',
              ]"
            >
              {{
                Object.keys(this.selected ?? {}).length > 0
                  ? selected.name
                  : placeHolderText
              }}
            </span>
          </div>
          <svg
            v-if="loading"
            class="absolute animate-spin right-2 top-2.5 h-5 w-5 text-gray"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              class="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              stroke-width="4"
            ></circle>
            <path
              class="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>

          <span
            v-if="!loading"
            class="
              absolute
              inset-y-0
              right-0
              flex
              items-center
              pr-2
              pointer-events-none
            "
          >
            <app-icon-select />
          </span>
        </button>
      </span>

      <span v-if="errorMessage.length > 0" class="text-xs text-error mt-1">{{
        errorMessage[0]
      }}</span>

      <span v-if="serverMessage.length > 0" class="text-xs text-gray-700 mt-1">
        {{
          serverMessage == "$general.message"
            ? $t("general.unable_to_reach_server")
            : serverMessage
        }}
        <a @click="retry" class="text-bluelink">{{
          $t("general.try_again")
        }}</a>
      </span>

      <!-- Select popover, show/hide based on select state. -->
      <div
        v-show="isOpen"
        class="absolute mt-1 w-full rounded-md bg-white shadow-lg z-10 p-3"
      >
        <app-form-input
          v-if="allowFilter"
          id="filterText"
          name="filterText"
          ref="filterText"
          autocomplete=""
          class="sticky"
          inputclass="text-sm p-2 h-9 mb-2 rounded-none border-1 border-bordercolor ring-1 ring-bordercolor focus:ring-2 focus:outline-none"
          :placeholder="filterPlaceHolderText"
          v-model="filterText"
        />
        <button
          v-if="allowButton"
          class="
            inline-flex
            transition-all
            text-sm
            w-full
            border
            gap-2
            text-primary
            font-semibold
            items-center
            px-3
            py-2
          "
          @click="onButtonClick"
        >
          <app-icon name="PlusIcon" class="w-4" />
          {{ buttonText }}
        </button>
        <ul
          tabindex="-1"
          role="listbox"
          aria-labelledby="listbox-label"
          aria-activedescendant="listbox-item-3"
          class="
            max-h-56
            text-base
            leading-6
            shadow-xs
            overflow-auto
            focus:outline-none
            sm:text-sm sm:leading-5
          "
        >
          <li v-if="showListTitle" class="bg-gray-200 py-2 px-3">
            <div>{{ listTitleText }}</div>
          </li>
          <li v-else></li>
          <li
            tabindex="0"
            id="listbox-item-0"
            @click="select(data)"
            role="option"
            v-for="data in temporaryDatas"
            :key="data.id"
            class="
              text-gray-900
              select-none
              relative
              cursor-pointer
              hover:text-white hover:bg-primary
              focus:outline-none focus:text-white focus:bg-primary-100
            "
          >
            <slot name="item" :item="data" :isSelected="isSelected(data)" />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { logEvent } from "firebase/analytics";
export default {
  props: {
    datas: {
      type: Array,
    },
    modelValue: {
      type: Object,
      default: () => {},
    },
    id: {
      type: String,
    },
    name: {
      type: String,
    },
    eventName: {
      type: String,
      default: null,
    },
    type: {
      type: String,
    },
    autocomplete: {
      type: String,
    },
    labelText: {
      type: String,
    },
    buttonText: {
      type: String,
    },
    listTitleText: {
      type: String,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    showIcon: {
      type: Boolean,
      default: false,
    },
    showListTitle: {
      type: Boolean,
      default: false,
    },
    allowButton: {
      type: Boolean,
      default: false,
    },
    allowFilter: {
      type: Boolean,
      default: true,
    },
    errorMessage: {
      type: Array,
      default: () => [],
    },
    serverMessage: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    inline: {
      type: Boolean,
      default: false,
    },
    alwaysOpen: {
      type: Boolean,
      default: false,
    },
  },

  emits: [
    "close-dropdown",
    "update:modelValue",
    "button-click",
    "filter",
    "valueSelected",
  ],

  data() {
    return {
      isOpen: false,
      temporaryDatas: [],
      filterText: "",
      filterTextTimeout: null,
      selectValue: null,
    };
  },

  mounted() {
    if (this.alwaysOpen && !this.isOpen) {
      this.openDropdown();
    }
  },

  computed: {
    placeHolderText() {
      return this.placeholder == ""
        ? this.$t("general.please_select")
        : this.placeholder;
    },
    filterPlaceHolderText() {
      if (this.alwaysOpen && this.placeholder) {
        return this.placeholder;
      }
      return this.$t("general.placeholder_search");
    },
    modelValueSelect: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.selectValue = value;
      },
    },
    selected() {
      return this.modelValueSelect ?? this.selectValue;
    },
  },

  methods: {
    isSelected(value) {
      if (this.selected) {
        const idField = value.id ? "id" : "_id";
        return this.selected[idField] === value[idField];
      }
      return false;
    },
    closeDropdown() {
      this.isOpen = this.alwaysOpen;
      this.$emit("close-dropdown");
    },
    openDropdown() {
      this.temporaryDatas = this.datas;
      this.isOpen = !this.isOpen;
      if (this.allowFilter) {
        this.$nextTick(() =>
          this.$refs.filterText.$el.getElementsByTagName("input")[0].focus()
        );
      }
    },
    select(value) {
      this.isOpen = false;
      this.modelValueSelect = value;
      this.$emit("update:modelValue", value);
      this.$emit("valueSelected", value);
    },
    retry() {
      this.$emit("retry");
    },
    onButtonClick(event) {
      if (this.eventName != null) {
        logEvent(this.$analytics, "click", { event: this.eventName });
      }

      this.$emit("button-click", event);
    },
  },

  watch: {
    datas(newDatas) {
      if (this.isOpen) {
        this.temporaryDatas = newDatas;
      }
    },
    filterText(newFilterText) {
      clearTimeout(this.filterTextTimeout);
      this.filterTextTimeout = setTimeout(() => {
        this.$emit("filter", newFilterText);
      }, 500);

      if (this.temporaryDatas == null) {
        return;
      }

      if (newFilterText.trim() == "") {
        this.temporaryDatas = this.datas;
        return;
      }

      this.temporaryDatas = this.datas.filter(function (data) {
        return data.name.toLowerCase().includes(newFilterText.toLowerCase());
      });
    },
  },
};
</script>

import client from "@/data/base-client";
import WithAuthRepository from "./with-auth-repository";

// @TODO: invoice.id should be remove, but BE is still not consistent returning this field
export default class InvoiceRepository extends WithAuthRepository {
  constructor() {
    super();
  }

  async indexInvoice(business_id, queryParams) {
    const url = `/invoices/businesses/${business_id}/invoices${queryParams || ''}`;
    const response = await client.get(url, this.getToken());

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async retrieveAgingInvoicesSummary(business_id) {
    const url = `/invoices/businesses/${business_id}/aging-invoices`;
    const response = await client.get(url, this.getToken());

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    const summary = { ...response.data, total: { total: 0, count: 0 } };
    for (const key of Object.keys(response.data)) {
      summary.total.total += summary[key].total;
      summary.total.count += summary[key].count;
    }

    return this.successResponse(summary);
  }

  async retrieveAgingTempNotes() {
    const notes = window.localStorage.getItem("aging-temp-notes");
    return this.successResponse(
      notes ? JSON.parse(notes) : { content: "", created_at: null }
    );
  }

  async saveAgingTempNotes(content) {
    const notes = { content, created_at: new Date() };
    window.localStorage.setItem("aging-temp-notes", JSON.stringify(notes));
    return this.successResponse(notes);
  }

  async deleteAgingTempNotes() {
    window.localStorage.removeItem("aging-temp-notes");
    return this.successResponse({});
  }

  async createOrUpdateInvoice(business_id, invoice) {
    var to_submit_invoices = this.$lodash.omitBy(invoice, this.$lodash.isNil);
    to_submit_invoices.custom_attributes =
      to_submit_invoices.custom_attributes.filter((item) => item.key);

    const url = `/invoices/businesses/${business_id}/invoices`;
    const response =
      invoice._id || invoice.id
        ? await client.put(
            `${url}/${invoice._id}`,
            to_submit_invoices,
            this.getToken()
          )
        : await client.post(url, to_submit_invoices, this.getToken());

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async deleteInvoice(business_id, invoice) {
    const url = `/invoices/businesses/${business_id}/invoices/${
      invoice._id || invoice.id
    }`;
    const response = await client.delete(url, this.getToken());

    if (this.$lodash.isEmpty(response.data)) {
      return response;
    }

    return response.data;
  }

  async getInvoiceById(business_id, invoice_id) {
    const url = `/invoices/businesses/${business_id}/invoices/${invoice_id}`;
    const response = await client.get(url, this.getToken());

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async downloadInvoicePdf(business_id, invoice_id) {
    const url = `/invoices/businesses/${business_id}/invoices/${invoice_id}/download-pdf`;
    const response = await client.get(url, this.getToken());

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async getSingleInvoice(invoice_id) {
    const url = `/invoices/${invoice_id}`;
    const response = await client.get(url, this.getToken());

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async getInvoiceHistory(business_id, invoice_id) {
    const url = `/invoices/businesses/${business_id}/invoices/${invoice_id}/history`;
    const response = await client.get(url, this.getToken());

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async sendEmail(business_id, invoice_id) {
    const url = `/invoices/businesses/${business_id}/invoices/${invoice_id}/mail`;
    const response = await client.post(url, [], this.getToken());

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async getInvoiceRunningNo(business_id) {
    const url = `/invoices/businesses/${business_id}/running-no`;
    const response = await client.get(url, this.getToken());

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async proceedInvoicePaymentAttempt(
    invoice_id,
    business_payment_gateway_method_id,
    card_info,
    bank_info,
    stripe = null
  ) {
    const response = await client.post("/payments/invoice/" + invoice_id + "/attempts", {
      business_payment_gateway_method_id,
      card_name: card_info?.card_name,
      card_number: card_info?.card_number
        ? card_info.card_number.trim()
        : card_info?.card_number,
      expiration_year: card_info?.card_expiry
        ? "20" + card_info.card_expiry.split("/")[1]
        : card_info?.card_expiry,
      expiration_month: card_info?.card_expiry
        ? card_info.card_expiry.split("/")[0]
        : card_info?.card_expiry,
      cvc: card_info?.card_cvc,
      selected_code: bank_info?.code,
      stripe: stripe
    });

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }
  
  async downloadAgingReport(business_id, payload) {
    const url = `/invoices/businesses/${business_id}/download-aging-report`;

    const response = await client.post(url, {
      start_date: payload.start_date,
      end_date: payload.end_date,
      format: payload.format,
      temp_note: payload.temp_note
    }, this.getToken());

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }
}

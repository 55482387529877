<template>
  <div>
    <app-modal-add-customer
      :show="isEditingDetail"
      @close="isEditingDetail = false"
      @success="handleCustomerSubmitted"
    />
  </div>
  <div
    v-if="customer._id"
    class="
      customer-info
      py-4
      px-3
      -mx-3
      border-2
      rounded-md
      border-white
      hover:border-black
      space-y-3
    "
  >
    <div class="flex items-start justify-between">
      <div>
        <p class="font-semibold">
          {{ customer.name }}
        </p>
        <p class="text-gray-400">{{ customer.email }}</p>
        <p class="text-gray-400">{{ customer.phone_no }}</p>
      </div>
      <app-icon-outline
        name="XCircleIcon"
        class="w-6 cursor-pointer hidden"
        @click="clearCustomer"
      />
    </div>
    <div class="flex flex-col md:flex-row">
      <div class="w-1/2 space-y-2">
        <p class="font-extrabold">{{ $t("customer.billing_address") }}</p>
        <p class="whitespace-pre-line">{{ customerBillingAddress }}</p>
      </div>
      <div class="w-1/2 space-y-2">
        <p class="font-extrabold">{{ $t("customer.shipping_address") }}</p>
        <p class="whitespace-pre-line">{{ customerShippingAddress }}</p>
      </div>
    </div>
  </div>
  <div v-else-if="isSelecting">
    <app-form-select-custom-item
      :datas="customers"
      :errorMessage="errorMessage"
      :loading="apiPaginationLoading"
      :placeholder="$t('customer.search_customer')"
      :allowFilter="true"
      :allowButton="true"
      :buttonText="$t('customer.add_new_customer')"
      :showListTitle="true"
      :listTitleText="$t('customer.customer_list')"
      :alwaysOpen="true"
      v-model="selectedCustomer"
      @retry="getCustomers"
      @button-click="displayCustomerForm"
      @valueSelected="handleCustomerSelected"
      @close-dropdown="clearCustomer"
      @filter="handleFilterTextChanged"
    >
      <template #item="{ item }">
        <div class="flex flex-col space-y-2 border py-3 px-3">
          <span class="block truncate">
            {{ item.name }}
          </span>
          <span class="block truncate text-gray-400">
            {{ item.email }}
          </span>
        </div>
      </template>
    </app-form-select-custom-item>
  </div>
  <div v-else-if="isEditing" class="w-1/2 space-y-3">
    <app-form-input
      id="customer.name"
      name="customer.name"
      type="text"
      v-model="customer.name"
      :labelText="$t('customer.customer_name')"
      :errorMessage="errors.name"
    />
    <app-form-input
      id="customer.email"
      name="customer.email"
      type="text"
      v-model="customer.email"
      :labelText="$t('customer.customer_email')"
      :errorMessage="errors.email"
    />
    <button
      class="
        py-2
        gap-2
        transition-all
        text-sm text-primary
        font-extrabold
        inline-flex
        justify-center
        items-center
      "
      @click="displayCustomerDetailForm"
    >
      <app-icon name="PlusIcon" class="w-4" />
      {{ $t("customer.add_additional_details") }}
    </button>
    <div class="flex justify-end">
      <app-button
        :showf70Icon="false"
        :primary="false"
        width=""
        class="border-none text-primary mr-2"
        @click="clearCustomer"
      >
        {{ $t("general.cancel") }}
      </app-button>
      <app-button
        width=""
        :showf70Icon="false"
        :loading="apiPaginationLoading"
        @click="submitCustomer"
      >
        {{ $t("customer.add_customer") }}
      </app-button>
    </div>
  </div>
  <div
    v-else
    class="
      flex flex-row
      justify-center
      items-center
      rounded-md
      border-2
      w-1/3
      py-10
      cursor-pointer
    "
    @click="displayCustomerDropdown"
  >
    <app-icon-outline name="UserAddIcon" class="w-6 mr-2" />
    <p class="font-extrabold">{{ $t("customer.add_a_customer") }}</p>
  </div>
</template>

<style scoped>
.customer-info:hover svg {
  display: block;
}
</style>

<script>
import { MAX_DROPDOWN_ITEMS } from "@/utils/const/invoice_settings";

export default {
  props: {
    errorMessage: {
      type: Array,
      default: () => [],
    },
    modelValue: {
      type: Object,
      default: () => {},
    },
  },

  emits: ["change", "update:modelValue"],

  data() {
    return {
      isSelecting: false,
      isEditing: false,
      isEditingDetail: false,
      filterText: "",
    };
  },

  computed: {
    selectedCustomer: {
      get() {
        return this.modelValue;
      },
      set(modelValue) {}, // already handled v-on:valueSelected
    },
    errors() {
      return this.$store.getters["customerStore/errors"] ?? {};
    },
    customer() {
      return this.$store.getters["customerStore/customer"];
    },
    customerBillingAddress() {
      return this.$store.getters["customerStore/customerAddress"]("billing");
    },
    customerShippingAddress() {
      return this.$store.getters["customerStore/customerAddress"]("shipping");
    },
    customers() {
      return this.$store.getters["customerStore/apiPaginationResponse"]?.data
        ?.data;
    },
    apiPaginationLoading() {
      return this.$store.getters["customerStore/apiPaginationLoading"];
    },
  },

  async mounted() {
    await this.getCustomer();
    await this.getCustomers();
  },

  methods: {
    async getCustomer() {
      if (this.modelValue?._id !== this.customer._id) {
        await this.$store.dispatch(
          "customerStore/getCustomerById",
          this.modelValue._id
        );
      }
    },
    async getCustomers() {
      let filters = `?page=1&per_page=${MAX_DROPDOWN_ITEMS}`;
      if (this.filterText) {
        filters += `&filter[name]=${this.filterText}`;
      }
      await this.$store.dispatch("customerStore/retrieveCustomer", "?" + new URLSearchParams(filters).toString());
    },
    handleFilterTextChanged(filterText) {
      this.filterText = filterText;
      this.getCustomers();
    },
    handleCustomerSelected(value) {
      this.$emit("update:modelValue", value);
      this.$emit("change", value);
      this.isSelecting = false;
      this.isEditing = false;
      this.$store.dispatch("customerStore/updateLocalCustomer", value);
    },
    handleCustomerSubmitted(customer) {
      this.handleCustomerSelected(customer);
      if (this.customers.length < MAX_DROPDOWN_ITEMS) {
        this.getCustomers();
      }
    },
    clearCustomer() {
      if (this.filterText) {
        this.filterText = "";
        this.getCustomers();
      }
      this.$emit("update:modelValue", {});
      this.$emit("change", {});
      this.isSelecting = false;
      this.isEditing = false;
      this.isEditingDetail = false;
      this.$store.dispatch("customerStore/resetCustomerWithInitialCustomer");
    },
    async submitCustomer() {
      const result = await this.$store.dispatch(
        "customerStore/createOrUpdateCustomer",
        this.customer
      );
      if (this.$lodash.isEmpty(result.errors)) {
        this.$store.dispatch("customerStore/resetCustomer");
        this.handleCustomerSubmitted(result.data);
        this.$notify({ group: "success", title: "Saved!" }, 3000);
      }
    },
    displayCustomerDropdown() {
      this.isSelecting = true;
      this.isEditing = false;
    },
    displayCustomerForm() {
      this.isSelecting = false;
      this.isEditing = true;
    },
    displayCustomerDetailForm() {
      this.isEditingDetail = true;
    },
  },
};
</script>